.badge {
	// padding: 2rem;
	position: relative;
	width: 23rem;
	margin: 0 auto 3rem auto;
	text-align: center;

	&:last-child {
		margin-bottom: 0;
	}

	@include media-breakpoint-up(xl) {
		width: 100%;
	}

	.badge__bg {
		background-image: url('../images/global/badge_2.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		width: 14rem;
   		height: 18rem;
		margin: 0 auto;

		@include media-breakpoint-up(xl) {
			width: 16rem;
			height: 26rem;
		}
	}

	.badge__content {
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 11rem;

		@include media-breakpoint-up(xl) {
			min-width: 15rem;
		}

		h1 {
			font-size: 6rem;
			line-height: 4rem;
			margin-bottom: 0.5rem;

			@include media-breakpoint-up(xl) {
				font-size: 8rem;
    			line-height: 7rem;
			}
		}

		p {
			margin-bottom: 0;
			font-family: $headings-font-family;
			text-transform: uppercase;
			font-size: 2.8rem;
			line-height: 3rem;
			letter-spacing: 1px;
			color: $gray-300;
			padding: 0.5rem;
			margin-top: 1rem;
			// background-color: $white;

			@include media-breakpoint-up(xl) {
			    font-size: 4rem;
    			line-height: 4rem;
    			margin-top: 0;
			}
		}
	}

	&.badge--overflow {
		.badge__bg {
			background-image: url('../images/global/badge_gap.png');
		}
		.badge__content {
			width: 100%;

			@include media-breakpoint-up(xl) {
				width: 105%;
			}
		}
	}

	&.badge--left {
		@include media-breakpoint-up(md) {
			margin: 0 0 5rem 0;
		}
	}
}

.sticky-block {
	.badge {
		@include media-breakpoint-up(xl) {
			margin-bottom: 0;
		}
	}
}

.owl-item .badge {
	margin-bottom: 0;
	width: 18rem;
}