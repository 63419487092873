.text-block {
	h1 {
		color: $black;
		margin-bottom: 4rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 6rem;
		}
	}

	&.text-block--overlay {
		position: relative;
		z-index: 2;
		
		@include media-breakpoint-up(md) {

			p {
				margin-bottom: 0;
				padding: 3rem 3rem 10rem 0;
				background-color: $white;
				position: relative;
				bottom: -1px;
				z-index: 2;
				width: 150%;
			}
		}
	}

	&.text-block--padded {
		background-color: $white;
		padding: 3rem;

		@include media-breakpoint-up(lg) {
			padding: 5rem;
		}
	}
}