#chapter-0 {

	.chapter-content {
		margin-top: 10rem;

		@include media-breakpoint-up(md) {
			margin-top: -10%;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -30%;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: -25%;
		}
	}

	#section-1 {
		.container {
			padding-left: 0;
			padding-right: 0;
		}

		.section__bg {
			margin-top: -12.5rem;
			position: relative;
			z-index: 2;

			@include media-breakpoint-up(md) {
				margin-top: -30%;
			}
		}

		#chapter-0__el-1 {
			margin-top: 4rem;

			@include media-breakpoint-up(md) {
				margin-top: 0;
				position: relative;
				left: 8rem;
				top: -5rem;
			}

			@include media-breakpoint-up(lg) {
				left: 20%;
				width: 110%;
			}

			@media(min-width: 1024px) {
				left: 35%;
				top: -7rem;
			}

			@include media-breakpoint-up(xxl) {
				left: 55%;
			}

			.text--callout {
				text-align: center;

				@include media-breakpoint-up(md) {
					text-align: left;
				}

				@include media-breakpoint-up(lg) {
					font-size: 7rem;
    				line-height: 6rem;
					display: inline-block;
					vertical-align: bottom;
					margin-bottom: 0;
				}

				@include media-breakpoint-up(xl) {
				    font-size: 10rem;
    				line-height: 8rem;
				}

				&.color--black {
					@include media-breakpoint-up(lg) {
						text-align: right;
						margin-right: 2rem;
					}
				}

				&.color--primary {
					@media(min-width: 1024px) {
						margin-bottom: -6.1rem;
					}

					@media(min-width: 1383px) {
						margin-bottom: -8.2rem;
					}
				}
			}
		}

		#chapter-0__el-2 {
			margin-top: -10rem;
			padding-left: 1rem;
		}
	}

	#section-2 {
		@include media-breakpoint-up(lg) {
			margin-top: 4rem;
		}

		.container {
			padding-left: 0;
			padding-right: 0;
		}

		#chapter-0__el-3 {
			position: relative;
			margin-top: 8rem;

			@media(min-width: 375px) {
				margin-top: 9rem;
			}

			@media(min-width: 414px) {
				margin-top: 10rem;
			}

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}

			img {
				width: 100%;
				display: inline-block;
			}

			.l-2 {
				position: absolute;
				bottom: -1rem;
				right: 0;
				width: 100%;
				text-align: center;

				> img {
					width: 90%;
				}

				@include media-breakpoint-up(xl) {
					bottom: -3rem;
					right: -25%;
				}
			}
		}

		#chapter-0__el-4 {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
			margin-top: 4.5rem;

			@include media-breakpoint-up(md) {
				margin-top: 0;
				padding-left: 0;
				padding-right: 3rem;
			}

			@include media-breakpoint-up(xl) {
				padding-right: 0;
				max-width: 35rem;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}
	}

	#section-3 {
		@include media-breakpoint-up(md) {
			margin-top: 8.5rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 15rem;
		}

		.section__content {
			position: relative;
			z-index: 2;
		}

		.section__bg--bottom {
			position: relative;
			z-index: 2;
			margin-top: 2rem;
			margin-bottom: 2rem;

			@include media-breakpoint-up(md) {
				margin-top: 4rem;
				margin-bottom: 0;
			}
		}

		.section__bg--right {
			position: absolute;
			top: 10%;
			right: 0;
			width: 60%;
			z-index: 1;
		}

		#chapter-0__el-5,
		#chapter-0__el-6,
		#chapter-0__el-7 {
			@include media-breakpoint-up(md) {
				margin-top: 0;
			}
		}

		#chapter-0__el-6 {
			@include media-breakpoint-up(xl) {
				max-width: 47rem;
			}
		}

		#chapter-0__el-7-wrapper {
			@include media-breakpoint-up(md) {
				margin-top: -5%;
			}

			@include media-breakpoint-up(xl) {
				margin-top: -10%;
			}

			// @include media-breakpoint-up(lg) {
			// 	margin-top: -15%;
			// }
		}

		#chapter-0__el-7 {
			@include media-breakpoint-up(xl) {
				position: relative;
				left: -7rem;
			}
		}

		#chapter-0__el-8,
		#chapter-0__el-9 {
			max-width: 47rem;
		}

		#chapter-0__el-8-wrapper {
			@include media-breakpoint-up(md) {
				margin-top: 4rem;
			}

			@include media-breakpoint-up(lg) {
				margin-top: 8.5rem;
			}
		}

		#chapter-0__el-9 {
			@include media-breakpoint-up(md) {
				margin-top: 0;
			}
		}
	}

	#section-5 {
		margin-top: 6rem;

		@include media-breakpoint-up(md) {
			margin-top: 10rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 20rem;
		}

		.section__bg {
			margin-top: -10rem;

			@include media-breakpoint-up(md) {
				margin-top: -50%;
			}
		}

		#chapter-0__el-12 {
			position: relative;
			z-index: 2;
		}
	}

	#section-6 {
		z-index: 2;
		margin-top: -5rem;

		@include media-breakpoint-up(md) {
			margin-top: -10rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -10%;
		}

		.quote-minimal .quote__inner {
			@include media-breakpoint-up(xl) {
				position: relative;
				left: 20%;
			}
		}
	}

	#section-7 {
		@include media-breakpoint-up(md) {
			margin-top: 5rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 50rem;
		}

		.owl-carousel--split-primary-container {

			// .col-md-6:last-child {
			// 	padding-left: 0;
			// 	padding-right: 0;

			// 	@include media-breakpoint-up(md) {
			// 		padding-left: $grid-gutter-width / 2;
			// 		padding-right: $grid-gutter-width / 2;
			// 	}
			// }
		}

		#chapter-0__el-14 {
			margin-top: 4rem;
			margin-bottom: 4rem;

			@include media-breakpoint-up(xl) {
				margin-top: 0;
				max-width: 35rem;
				position: relative;
				top: -10rem;
			}
		}
	}

	#section-9 {
		#chapter-0__el-15 {
			margin-top: 4rem;
			margin-bottom: -6rem;

			@include media-breakpoint-up(md) {
				margin: 8rem auto -5rem auto;
				max-width: 31rem;
			}

			@include media-breakpoint-up(lg) {
				max-width: 52rem;
			}

			@include media-breakpoint-up(xl) {
				margin: 12rem auto -5rem auto;
			}

			.text--callout {
				text-align: center;

				@include media-breakpoint-up(md) {
					text-align: left;
					display: inline-block;
					vertical-align: bottom;
					margin-bottom: 0;
				}

				&.color--primary {
					@include media-breakpoint-up(md) {
						text-align: right;
					}
				}

				&.color--black {
					@include media-breakpoint-up(md) {
						position: relative;
						bottom: -9.25rem;
					}

					@include media-breakpoint-up(md) {
					    bottom: -16.25rem;
					}
				}
			}
		}
	}
}