.owl-carousel--uneven {

	.owl-stage {
		padding-left: 0 !important;
	}

	.owl-item:nth-child(even) .item {
		padding-top: 4rem;

		@media(min-width: 1500px) {
			padding-top: 9rem;
		}
	}
}

.owl-carousel--split-primary-container {

	@include media-breakpoint-up(xl) {
		position: relative;
		top: -25rem;
	}
}

.owl-carousel--badges {
	display: block !important;
}

.owl-carousel--split.owl-rtl {
	.item__desc {
		direction: ltr !important;
	}
}

.owl-carousel--split-off-screen {
	max-width: 54rem;
	position: absolute;
	bottom: 0;

	&.owl-carousel--split-left {
		left: -30rem;
	}

	&.owl-carousel--split-right {
		right: -30rem;
	}
}