.container--fw {
	max-width: 100% !important;
}

// .container--wide {
// 	@include media-breakpoint-up(xxl) {
// 		max-width: (map-get($container-max-widths, xxl)) + 100;
// 	}
// }

.container--narrow {
	@include media-breakpoint-up(xl) {
		max-width: map-get($container-max-widths, lg);
	}
}

.container--flush-sm-down {
	@include media-breakpoint-down(xs) {
		max-width: 100%;
		padding-left: 0 !important;
		padding-right: 0 !important;

		.wpb_column {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.pt {
	padding-top: 4rem;

	@include media-breakpoint-up(lg) {
		padding-top: 8.5rem;
	}
}

.pb {
	padding-bottom: 4rem;

	@include media-breakpoint-up(lg) {
		padding-bottom: 8.5rem;
	}
}

.pt-less {
	padding-top: 2rem;

	@include media-breakpoint-up(lg) {
		padding-top: 2.5rem;
	}
}

.pb-less {
	padding-bottom: 2rem;

	@include media-breakpoint-up(lg) {
		padding-bottom: 2.5rem;
	}
}

.mt {
	margin-top: 4rem;

	@include media-breakpoint-up(lg) {
		margin-top: 8.5rem;
	}
}

.mb {
	margin-bottom: 4rem;

	@include media-breakpoint-up(lg) {
		margin-bottom: 8.5rem;
	}
}

.mt-less {
	margin-top: 2rem;

	@include media-breakpoint-up(lg) {
		margin-top: 2.5rem;
	}
}

.mb-less {
	margin-bottom: 2rem;

	@include media-breakpoint-up(lg) {
		margin-bottom: 2.5rem;
	}
}

@each $color, $value in $theme-colors {
  .bg--#{$color} {
    background-color: $value;
  }
}

@each $color, $value in $grays {
  .bg--gray-#{$color} {
    background-color: $value;
  }
}

.rel {
	position: relative;
}

.o-flow--hidden {
	overflow: hidden;
}