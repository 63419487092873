.ml-spotlight {
	position: relative;
	margin-bottom: 18rem;

	@media(min-width: 375px) {
		margin-bottom: 24rem;
	}

	@include media-breakpoint-up(md) {
		margin-bottom: 20rem;
	}

	@include media-breakpoint-up(lg) {
		max-width: 65rem;
		margin-bottom: 30rem;
	}

	@include media-breakpoint-up(xl) {
		max-width: 80rem;
		margin-bottom: 24rem;
	}

	@include media-breakpoint-up(xxl) {
		max-width: 100rem;
	}

	img {
		width: 100%;
	}

	.ml-spotlight__img-container {
		position: relative;

		&::after {
			@include media-breakpoint-up(lg) {
				content: '';
				display: block;
				z-index: 2;
				width: 20%;
				height: 57%;
				background-color: $white;
				position: absolute;
				bottom: 0;
				right: 0;
			}

			@include media-breakpoint-up(xxl) {
				height: 68%;
				width: 18%;
			}
		}

		.ml-spotlight__bg {
			z-index: 1;
		}

		.ml-spotlight__foreground {
			z-index: 3;
		    position: absolute;
		    top: 6rem;
		    right: 0;
		    max-width: 16rem;

		    @media(min-width: 375px) {
		    	max-width: 20rem;
		    }

		    @include media-breakpoint-up(lg) {
		    	max-width: 23rem;
		    	right: 2rem;
		    }

		    @include media-breakpoint-up(xl) {
				top: 3rem;
		    }

		    @include media-breakpoint-up(xxl) {
		    	max-width: 28rem;
		    }
		}
	}

	.ml-spotlight__caption {
		padding-left: $grid-gutter-width / 2;
	}

	.ml-spotlight__text {
		z-index: 4;
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
		margin-bottom: 2rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			padding-left: 0;
			padding-right: 0;
			position: absolute;
			bottom: -19rem;
		    max-width: 35rem;
		    right: -27rem;
		}

		@include media-breakpoint-up(xl) {
			bottom: -14rem;
		}

		@include media-breakpoint-up(xxl) {
			bottom: -4rem;
			right: -25.5rem;
			max-width: 37rem;
		}
	}
}

.ml-spotlight-alt-wrapper {

}

.ml-spotlight-alt {
	position: relative;
	margin: 0 auto;
	max-width: 24rem;

	@include media-breakpoint-up(md) {
		margin: 0;
		max-width: 26rem;
	}

	@include media-breakpoint-up(lg) {
		max-width: 36rem;
	}

	@include media-breakpoint-up(xl) {
	    max-width: 40rem;
	}

	img {
		width: 100%;
	}

	.ml-spotlight__text {
		position: absolute;
		bottom: 0;
	    left: 3rem;
		z-index: 3;
		text-align: right;
	}
}