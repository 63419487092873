#chapter-6 {

	.chapter-content {
		margin-top: 15rem;

		@media(min-width: 375px) {
			margin-top: 24rem;
		}

		@include media-breakpoint-up(md) {
			margin-top: 28rem;
		}

		@include media-breakpoint-up(lg) {
			margin-top: 35rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: 28rem;
		}
	}

	#section-1 {
		z-index: 2;

		#chapter-6__el-2 {
			margin-top: -5rem;
			position: relative;
			z-index: 2;

			.quote__inner {
				@include media-breakpoint-up(lg) {
					position: relative;
					left: -32rem;
					text-align: right;
					padding-left: 0;
				}
			}

			p.quote__statement {
				@include media-breakpoint-up(lg) {
					position: relative;
					top: -4rem;
				}
			}
		}

		#chapter-6__el-3 {
			.text--caption {
				padding-left: $grid-gutter-width / 2;
				padding-right: $grid-gutter-width / 2;

				@include media-breakpoint-up(md) {
					padding-right: 0;
				}
			}

			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				left: 0;
				max-width: 50%;
				padding-left: 0;
				padding-right: 0;
				margin: 0;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 45%;
			}
		}
	}

	#section-2 {

		@include media-breakpoint-up(md) {
			margin-bottom: 4rem;
			margin-top: -4rem;
		}

		@include media-breakpoint-up(lg) {
			margin-top: 4rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 0;
			margin-bottom: 8.5rem;
		}

		.container {
			@include media-breakpoint-up(xl) {
				max-width: 100%;
				padding-left: 0;
				padding-right: 0;
			}
		}

		#chapter-6__el-4 {
			@include media-breakpoint-up(lg) {
				position: relative;
    			top: -13rem;
			}
		}

		#chapter-6__el-5 {
			margin-bottom: 4rem;

			@include media-breakpoint-up(md) {
				margin-top: 0;
				margin-bottom: 0;
			}
			.text--caption {
				padding-left: $grid-gutter-width / 2;
				padding-right: $grid-gutter-width / 2;

				@include media-breakpoint-up(md) {
					padding-right: 0;
					padding-left: 0;
				}
			}

			// @include media-breakpoint-up(md) {
			// 	position: absolute;
			// 	top: 0;
			// 	right: 0;
			// 	max-width: 50%;
			// 	padding-left: 0;
			// 	padding-right: 0;
			// 	margin: 0;
			// }

			// @include media-breakpoint-up(xxl) {
			// 	max-width: 38%;
			// }
		}
	}

	#section-3 {
		z-index: 1;

		@include media-breakpoint-up(md) {
			margin-top: -15%;
		}

		@include media-breakpoint-up(hg) {
			margin-top: -18%;
		}

		.row {
			position: relative;
			z-index: 1;
		}
	}

	#section-4 {
		position: relative;
		z-index: 2;

		#chapter-6__el-11 {
			margin-top: -5rem;

			@include media-breakpoint-up(xl) {
				margin-top: -7rem;
			}
		}

		#chapter-6__el-12 {
			padding-right: 8%;
		}

		#chapter-6__el-12-a {
			position: relative;

			@include media-breakpoint-up(md) {
				top: 10rem;
			}

			@include media-breakpoint-up(xl) {
				top: 20rem;
			}

			@include media-breakpoint-up(xxl) {
				top: 30rem;
			}
		}
	}

	#section-5 {
		@include media-breakpoint-up(md) {
			margin-top: 2rem;
		}

		#chapter-6__el-13 {
			@include media-breakpoint-up(md) {
				// position: relative;
				// bottom: -2rem;
			}
		}

		#chapter-6__el-14 {
			@include media-breakpoint-up(md) {
				margin-bottom: 8rem;
			}
		}
	}

	#section-6 {
		#chapter-6__el-16 {
			@include media-breakpoint-up(lg) {
				margin-top: 0;
			}
		}
	}

	#section-7 {
		@include media-breakpoint-up(xl) {
			margin-top: -20rem;
		}

		.sticky-block {
			@include media-breakpoint-up(md) {
				margin-top: 0;
			}
		}
	}

	#section-8 {
		.section__bg__tear {
			bottom: 40px;

			&.section__bg__tear--before {
				top: 10px;
			}
		}
	}

	#section-9 {
		#chapter-6__el-23-a {
			@include media-breakpoint-up(md) {
				position: absolute;
			    top: 0;
			    right: 0;
			    max-width: 50%;
			    padding-left: 0;
			    padding-right: 0;
			    margin: 0;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 42%;
			}

			@include media-breakpoint-up(xhg) {
				max-width: 38%;
			}

			.text--caption {
				padding-left: $grid-gutter-width / 2;
				padding-right: $grid-gutter-width / 2;

				@include media-breakpoint-up(md) {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}

	#section-11 {
		@include media-breakpoint-up(xxl) {
			margin-bottom: 12rem;
		}
		.el {
			@include media-breakpoint-up(md) {
				margin-top: 0;
			}
		}
	}
}