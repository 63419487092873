.site {
	overflow: hidden;
	opacity: 1;
	transition: opacity $transition-speed ease;
	background-color: $body-bg;

	&.site--loading {
		opacity: 0;
	}
}

::-webkit-scrollbar {
	display: none;
}