.quote {
	overflow: hidden;

	img {
		width: 100%;
	}

	.quote__inner {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
	}

	.quote__icon {
		display: block;
		color: rgba($white, 0.15);
		font-size: 135rem;
		line-height: 75rem;
		font-family: $headings-font-family;
		position: absolute;
		top: -3rem;
		left: -8rem;

		@media(min-width: 375px) {
			font-size: 155rem;
			line-height: 85rem;
			position: absolute;
			left: -11rem;
		}

		@media(min-width: 414px) {
			font-size: 180rem;
			line-height: 95rem;
		}

		@include media-breakpoint-up(md) {
			left: -3rem;
			line-height: 100rem;
		}

		@include media-breakpoint-up(lg) {
			left: -11rem;
			line-height: 102rem;
		}

		@include media-breakpoint-up(xl) {
			font-size: 230rem;
			left: -18rem;
			line-height: 122rem;
		}

		@include media-breakpoint-up(xxl) {
			font-size: 340rem;
		    left: -33rem;
		    line-height: 176rem;
		}
	}

	.quote__by {
		padding-bottom: 7rem;
		max-width: 16rem;
		margin: 0 auto;
		text-align: right;
		position: relative;

		span.quote__name,
		span.quote__date {
			color: $white;
			display: block;
		}

		span.quote__name {
			font-weight: bold;
			font-size: 1.5rem;
			line-height: 1rem;
			margin-bottom: 0.5rem;

			@include media-breakpoint-up(xl) {
				font-size: 2rem;
				line-height: 1.5rem;
				margin-bottom: 1.5rem;
			}
		}

		span.quote__date {
			text-transform: uppercase;
			font-size: 1rem;
			line-height: 2rem;
			opacity: 0.5;

			@include media-breakpoint-up(xl) {
				font-size: 1.5rem;
			}
		}
	}

	p.quote__statement {
		margin-bottom: 0;
		color: $white;
		font-size: 2rem;
		line-height: 4rem;
		font-weight: 300;

		@media(min-width: 375px) {
			font-size: 2.5rem;
		}

		@include media-breakpoint-up(md) {
			font-size: 2rem;
			line-height: 4rem;
			margin-top: -1rem;
		}

		@include media-breakpoint-up(xl) {
			font-size: 3rem;
			line-height: 4.5rem;
		}
	}
}

.quote-minimal {
	// position: relative;

	.quote__inner {
		margin-top: -12rem;
		padding-left: 2rem;

		@media(min-width: 414px) {
			margin-top: -9rem;
		}

		@include media-breakpoint-up(lg) {
			// padding-top: 5rem;
			padding-left: 14rem;
			// max-width: 45rem;
		}

		@include media-breakpoint-up(xxl) {
			padding-left: 13rem;
			margin-top: -10rem;
		}
	}

	.quote__icon {
		display: block;

		> img {
			max-width: 20rem;

			@include media-breakpoint-up(xl) {
				max-width: 40rem;
			}
		}
	}

	.quote__by {
		span.quote__name,
		span.quote__date {
			color: $gray-300;
			display: block;
		}

		span.quote__name {
			font-weight: bold;
			font-size: 1.5rem;
			line-height: 1rem;
			margin-bottom: 0.5rem;

			@include media-breakpoint-up(xl) {
				font-size: 2rem;
				line-height: 1.5rem;
				margin-bottom: 1.5rem;
			}
		}

		span.quote__date {
			text-transform: uppercase;
			font-size: 1rem;
			line-height: 2rem;
			// opacity: 0.5;
			font-weight: $font-weight-semi-bold;

			@include media-breakpoint-up(xl) {
				font-size: 1.5rem;
			}
		}
	}

	p.quote__statement {
		margin-bottom: 2rem;
		color: $primary;
		font-size: 3rem;
		line-height: 4rem;
		font-weight: 300;

		@include media-breakpoint-up(xl) {
			margin-bottom: 4rem;
		}
	}
}