#chapter-3 {

	.chapter-content {
		margin-top: -100%;

		@include media-breakpoint-up(md) {
			margin-top: -70%;
		}

		@include media-breakpoint-up(lg) {
			margin-top: -40%;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -20%;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: -30%;
		}
	}

	#section-1 {
		#chapter-3__el-1 {
			top: 4rem;
			z-index: 2;

			@include media-breakpoint-up(md) {
				margin-left: 2rem;
			}

			> img {
				max-width: 13rem;

				@include media-breakpoint-up(md) {
					max-width: 20rem;
				}

				@include media-breakpoint-up(xl) {
					max-width: 30rem;
				}
			}
		}

		#chapter-3__el-2 {
			margin-top: -2rem;
			margin-right: 2rem;

			@include media-breakpoint-up(md) {
				margin-top: -5rem;
				margin-right: 4rem;
			}

			@include media-breakpoint-up(xl) {
				margin-top: -15rem;
			}

			> img {
				max-width: 8rem;

				@include media-breakpoint-up(md) {
					max-width: 12rem;
				}

				@include media-breakpoint-up(xl) {
					max-width: 16rem;
				}
			}
		}
	}

	#section-2 {
		margin-top: 10rem;

		@include media-breakpoint-up(md) {
			margin-top: 25rem;
		}

		.section__bg {
			position: static;
			margin-top: 6rem;

			@include media-breakpoint-up(xl) {
				text-align: right;
				margin-top: -12%;

				> img {
					max-width: 60%;
				}
			}

			@include media-breakpoint-up(xxl) {
				margin-top: -9%;

				> img {
					max-width: 58%;
				}
			}

			@include media-breakpoint-up(hg) {
				margin-top: -7%;

				> img {
					max-width: 56%;
				}
			}
		}

		#chapter-3__el-3 {
			@include media-breakpoint-up(xl) {
				max-width: 45rem;
			}
		}

		#chapter-3__el-4 {
			@include media-breakpoint-up(xl) {
				position: relative;
				left: 8rem;
				margin-top: 10rem;
			}
		}
	}

	#section-3 {

		max-height: 1645px;
		overflow: hidden;

		@include media-breakpoint-up(md) {
			padding-bottom: 18rem;
		}

		@media(min-width: 1024px) {
			padding-bottom: 31rem;
		}

		@include media-breakpoint-up(xl) {
			padding-bottom: 21rem;
			margin-top: 8rem;
		}

		@include media-breakpoint-up(xxl) {
			padding-bottom: 0;
		}

		.section__bg {
			width: 60%;
		}

		.section__content {
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
		}

		#chapter-3__el-5-6 {
			padding-top: 8rem;

			@include media-breakpoint-up(md) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		#chapter-3__el-5 {
			@include media-breakpoint-up(xl) {
				padding-right: 2rem;
			}
			@include media-breakpoint-up(xxl) {
				padding-right: 5rem;
			}
		}

		#chapter-3__el-6 {
			margin-left: -$grid-gutter-width / 2;
			margin-right: -$grid-gutter-width / 2;
			margin-top: 2rem;

			@include media-breakpoint-up(md) {
				margin: 0;
			    position: absolute;
			    top: 8rem;
			    left: 0;
			    max-width: 50%;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 80rem;
			}
		}

		#chapter-3__el-7-8 {
			margin-top: 4rem;

			@include media-breakpoint-up(md) {
				margin-top: 15%;
			}
		}

		#chapter-3__el-7 {

		}

		#chapter-3__el-8 {
			@include media-breakpoint-up(md) {
				width: 26rem;
				margin: 0;
			}
		}

		#chapter-3__el-9 {
			margin-top: 4rem;
			
			@include media-breakpoint-up(md) {
				margin-top: 0;
			}
		}
	}

	#section-4 {
		margin-top: 4rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		#chapter-3__el-11 {
			margin-left: -$grid-gutter-width / 2;
			margin-right: -$grid-gutter-width / 2;

			@include media-breakpoint-up(md) {
				margin: 0;
			}
		}

		#chapter-3__el-12-13 {
			@include media-breakpoint-up(md) {
				margin-top: -5rem;
				position: relative;
				z-index: 4;
			}
		}

		#chapter-3__el-12 {
			@include media-breakpoint-up(md) {
				margin: 0;
				position: relative;
				left: -4rem;
			}
		}

		#chapter-3__el-13 {
			text-align: center;
			margin-top: 4rem;
			position: relative;

			@include media-breakpoint-up(md) {
				margin: 0;
			}

			@include media-breakpoint-up(lg) {
				left: -10rem;
			}

			> img {
				max-width: 20rem;

				@include media-breakpoint-up(md) {
					max-width: 35rem;
				}

				@include media-breakpoint-up(lg) {
					max-width: 40rem;
				}

				@include media-breakpoint-up(xl) {
					max-width: 50rem;
				}
			}
		}
	}
}