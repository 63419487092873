#chapter-2 {

	.chapter-content {
		margin-top: -10%;

		@include media-breakpoint-up(xl) {
			margin-top: -20%;
		}
	}

	#section-1 {
		z-index: 3;

		#chapter-2__el-1 {
			max-width: 16rem;
			margin-bottom: 3rem;

			@include media-breakpoint-up(md) {
				max-width: 20rem;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 26rem;
				margin: 0 auto 5rem auto;
			}
		}

		#chapter-2__el-3 {
			max-width: 16rem;
			margin: 3rem auto;

			@include media-breakpoint-up(md) {
				max-width: 20rem;
			}

			@include media-breakpoint-up(lg) {
				max-width: 26rem;
				margin: 3rem 0 0 0;
			}

			@include media-breakpoint-up(xl) {
				max-width: 32rem;
				margin: 3rem 0 0 0;
			}

			@include media-breakpoint-up(xxl) {
				margin: 5rem 0 0 -10rem;
			}
		}

		#chapter-2__el-5-6 {
			@include media-breakpoint-up(md) {
				margin-top: -16rem;
			}

			@include media-breakpoint-up(xl) {
				margin-top: -30rem;
			}
		}
	}

	#section-2 {
		z-index: 2;

		@include media-breakpoint-up(md) {
			padding-top: 8rem;
		}

		.section__content {
			@include media-breakpoint-up(xl) {
				position: absolute;
				top: 10rem;
				left: 0;
				width: 100%;
			}
		}

		#chapter-2__el-7-8 {
			padding-left: 0;
			padding-right: 0;

			@include media-breakpoint-up(sm) {
				padding-left: $grid-gutter-width / 2;
				padding-right: $grid-gutter-width / 2;
			}
		}

		#chapter-2__el-7 {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;

			@include media-breakpoint-up(sm) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		#chapter-2__el-8 {
			@include media-breakpoint-up(xl) {
				padding-top: 10rem;
			}

			@include media-breakpoint-up(xxl) {
				padding-top: 30rem;
			}
		}

		#chapter-2__el-9 {

			@include media-breakpoint-up(lg) {
				max-width: 60rem;
			}

			@include media-breakpoint-up(xl) {
				margin-top: 0;
				position: absolute;
				left: 0;
				max-width: 70rem;
				bottom: 0;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 50%;
				bottom: 15%;
			}

			@include media-breakpoint-up(hg) {
				bottom: 20%;
			}

			@include media-breakpoint-up(xhg) {
				bottom: 30%;
				max-width: 60%;
			}

			.text--caption {
				text-align: center;
				padding-left: 1.5rem;

				@include media-breakpoint-up(md) {
					text-align: left;
				}
			}
		}
	}

	#section-3 {
		z-index: 3;

		@include media-breakpoint-up(xxl) {
		    margin-top: -13rem;
		}

		.section__content {
		    @include media-breakpoint-up(md) {
		    	position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
		    }

		    @include media-breakpoint-up(xxl) {
				top: -10rem;
		    }
		}

		.section__bg {
			@include media-breakpoint-up(md) {
				margin-top: 20rem;
			}

			@include media-breakpoint-up(xxl) {
				margin-top: 0;
			}

			.text--caption {
				position: absolute;
				bottom: 10%;
				right: $grid-gutter-width / 2;
				color: $white;
			}
		}

		#chapter-2__el-10 {
			@include media-breakpoint-up(md) {
				padding-top: 12rem;
			}

			@include media-breakpoint-up(xxl) {
				padding-top: 22rem;
			}
		}

		#chapter-2__el-11 {
			max-width: 18rem;
			margin: 2rem auto 4rem auto;
			text-align: right;

			@include media-breakpoint-up(md) {
				max-width: 25rem;
				text-align: left;
				margin-top: 0;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 100%;
			}
		}

		#chapter-2__el-12 {
			@include media-breakpoint-up(md) {
				margin-top: -10rem;
			}

			@include media-breakpoint-up(lg) {
				margin-top: -6rem;
			}

			@include media-breakpoint-up(xxl) {
				margin-top: -19rem;
			}
		}
	}

	#section-4 {

		@include media-breakpoint-up(md) {
			margin-top: -14rem;
			z-index: 4;
		}

		@include media-breakpoint-up(lg) {
			margin-top: -18rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -40rem;
		}

		@media(min-width: 1360px) {
			margin-top: -57rem;
			max-width: 75%;
		}

		@media(min-width: 1800px) {
			margin-top: -82rem;
		}

		.col-12:first-child {
			max-width: 72rem;
		}

		#chapter-2__el-13 {
			margin-top: 4rem;
			margin-bottom: 2rem;
			margin-left: -$grid-gutter-width / 2;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
				margin-top: 0;
			}
		}

		#chapter-2__el-14 {
			text-align: right;

			@include media-breakpoint-up(md) {
				text-align: left;
			}
		}
	}

	#section-5 {
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;

		@include media-breakpoint-up(xl) {
			padding-left: 20%;
		}

		@media(min-width: 1360px) {
			padding-left: 25%;
		}

		@media(min-width: 1800px) {
			padding-left: 32%;
		}

		#chapter-2__el-16 {
			@include media-breakpoint-up(md) {
				position: relative;
				right: -$grid-gutter-width / 2;
			}
		}

		#chapter-2__el-17 {
			@include media-breakpoint-up(md) {
				padding-right: 5rem;
			}

			@include media-breakpoint-up(lg) {
				padding-left: $grid-gutter-width;
			}
		}

		#chapter-2__el-18 {

			@include media-breakpoint-up(md) {
				margin-top: 4rem;
				text-align: center;
			}

			@include media-breakpoint-up(xl) {
				text-align: left;
				padding-left: $grid-gutter-width;
			}

			> img {
				max-width: 20rem;

				@include media-breakpoint-up(xl) {
					max-width: 28rem;
				}
			}
		}

		#chapter-2__el-19 {
			position: relative;
			right: -7rem;

			// @include media-breakpoint-up(md) {
			// 	margin-right: -$grid-gutter-width / 2;
			// }

			> img {
				max-width: 38.4rem;

				@include media-breakpoint-up(md) {
					max-width: 100%;
				}
			}
		}
	}
}