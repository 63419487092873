//
// Headings
//

h1, h2,
.h1, .h2 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  line-height: $headings-line-height;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:last-child {
    margin-bottom: 0;
  }
}

h1, .h1 {
  font-size: $h1-font-size-sm;
  line-height: $h1-line-height-sm;
  color: $h1-color;

  @include media-breakpoint-up(lg) {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }
}

h2, .h2 {
  font-size: $h2-font-size-sm;
  color: $h2-color;

  @include media-breakpoint-up(lg) {
    font-size: $h2-font-size;
  }
}

//
// Text colours
//

@each $color, $value in $theme-colors {
  .text-stroke--#{$color} {
    text-shadow: -1.5px -1.5px 0 $value, 1.5px -1.5px 0 $value, -1.5px 1.5px 0 $value, 1.5px 1.5px 0 $value;
  }
}

@each $color, $value in $grays {
  .text-stroke--gray-#{$color} {
    text-shadow: -1.5px -1.5px 0 $value, 1.5px -1.5px 0 $value, -1.5px 1.5px 0 $value, 1.5px 1.5px 0 $value;
  }
}

@each $color, $value in $theme-colors {
  .color--#{$color} {
    color: $value !important;
  }
}

@each $color, $value in $grays {
  .color--gray-#{$color} {
    color: $value !important;
  }
}

//
// Jumbo text
//

.text--jumbo {
  font-size: $jumbo-font-size-sm;
  line-height: $jumbo-line-height-sm;
  font-family: $headings-font-family;
  letter-spacing: 0;

  @include media-breakpoint-up(lg) {
    font-size: $jumbo-font-size-lg !important;
    line-height: $jumbo-line-height-lg !important;
  }

  @include media-breakpoint-up(xxl) {
    font-size: $jumbo-font-size-xxl !important;
    line-height: $jumbo-line-height-xxl !important;
  }
}

//
// Quote
//

.text--quote {
  font-family: $font-family-base;
  font-weight: $font-weight-light;
  color: $primary;
  font-size: 3rem;
  line-height: 5rem;

  @include media-breakpoint-up(lg) {
    font-size: 5rem;
    line-height: 7rem;
  }
}

//
// Quote name
//

.text--quote-name {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
  color: $primary;
  font-size: 1.5rem;
  line-height: 2rem;

  @include media-breakpoint-up(lg) {
    font-size: 3rem;
    line-height: 5rem;
  }
}

//
// Quote date
//

.text--quote-date {
  font-family: $font-family-base;
  font-weight: $font-weight-light;
  color: $primary;
  font-size: 1rem;
  line-height: 2rem;
  opacity: 0.5;

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

//
// Stat number
//

.text--stat-number {
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  color: $primary;
  text-transform: uppercase;
  font-size: 6.6rem;
  line-height: 6.2rem;

  @include media-breakpoint-up(lg) {
    font-size: 11rem;
    line-height: 10.3rem;
  }
}

//
// Stat word
//

.text--stat-word {
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  color: $gray-300;
  text-transform: uppercase;
  font-size: 3.3rem;
  line-height: 3.1rem;
  letter-spacing: 0.47px;

  @include media-breakpoint-up(lg) {
    font-size: 5.5rem;
    line-height: 5.2rem;
    letter-spacing: 0.79px;
  }
}

//
// Call out
//

.text--callout {
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  color: $primary;
  text-transform: uppercase;
  font-size: 6rem;
  line-height: 5rem;
  letter-spacing: 0.9px;

  @include media-breakpoint-up(lg) {
    font-size: 10rem;
    line-height: 8rem;
    letter-spacing: 1.5px;
    max-width: 25rem;
  }

  &.text--callout-fw {
    max-width: 100%;
  }

  span {
    display: block;
    font-size: 15rem;
    color: $gray-100;
    height: 0;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      font-size: 26rem;
    }
  }

  span.name,
  span.name-2 {
    height: 100%;
    color: $gray-300;
    display: block;
    font-family: $font-family-base;
    letter-spacing: 0;
  }

  span.name {
    margin-top: 2rem;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;
    text-transform: none;

    @include media-breakpoint-up(xl) {
      margin-top: 4rem;
      font-size: 2rem;
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  span.name-2 {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 2rem;
    // opacity: 0.5;
    font-weight: $font-weight-semi-bold;

    @include media-breakpoint-up(xl) {
      font-size: 1.5rem;
    }
  }

  &.text--callout-quote {
    padding-top: 5rem;
  }
}

//
// Caption
//

.text--caption {
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  color: $black;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
}

.text--slide-count {
  padding-left: 1.5rem;
  font-weight: bold;
  color: #A7A9AC;
  font-size: 1.3rem;
  line-height: 1.5rem;

  strong {
    color: #000;
  }
}

//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
  @include font-size($small-font-size);
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
  padding-left: 0;
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size(90%);
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  @include font-size($blockquote-font-size);
}

.blockquote-footer {
  display: block;
  @include font-size($blockquote-small-font-size);
  color: $blockquote-small-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}