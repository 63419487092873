.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 2rem 0.5rem;
	z-index: $zindex-navbar;
	top: 4.5rem;
	transition: top $transition-speed * 3 ease;

	@include media-breakpoint-up(md) {
		top: 7rem;

		&.transitioned {
			top: 0;
		}
	}

	@include media-breakpoint-up(xl) {
		padding: 3.5rem 2.5rem;
	}

	.brand {
		display: inline-block;
		max-width: 7rem;
		width: 100%;
		height: auto;
		padding: 0;
		margin: 0;

		@include media-breakpoint-up(xl) {
			max-width: 10rem;
		}
	}

	.btn--back {
		height: 3rem;
		width: 3rem;
		border: 1px solid $white;
		border-radius: 50%;
		position: relative;
		display: inline-block;
		opacity: 0;
		z-index: -1;
		transition: opacity $transition-speed*2 ease;
		transition-delay: 500ms;

		@include media-breakpoint-up(lg) {
			height: 5rem;
			width: 5rem;
		}

		> img {
			max-width: 1rem;
			position: absolute;
			top: 50%;
			left: 48%;
			transform: translate(-50%, -50%);

			@include media-breakpoint-up(lg) {
				max-width: 2rem;
			}
		}
	}
}