.number-scroll-container {
	@include media-breakpoint-up(xxl) {
		margin-top: 8.5rem;
	}
}

.number-scroll {
	display: flex;
	margin-bottom: 5rem;

	@include media-breakpoint-up(lg) {
		margin-bottom: 10rem;
	}

	@include media-breakpoint-up(xl) {
		margin-bottom: 15rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.number-scroll__number {
	    width: 8rem;
	    padding-right: 2rem;
	    text-align: right;
	    overflow: hidden;

	    @include media-breakpoint-up(xl) {
	    	width: 12rem;
	    	padding-right: 1.5rem;
	    }

		h1 {
			font-size: 11.5rem;
    		line-height: 9.5rem;

    		@include media-breakpoint-up(xl) {
			    font-size: 20rem;
    			line-height: 15.5rem;
    		}

    		span {
    			display: inline-block;
    		}
		}
	}

	.number-scroll__text {
		h1 {
			font-size: 4.9rem;
			line-height: 4.5rem;

			@include media-breakpoint-up(xl) {
				font-size: 7.5rem;
    			line-height: 7.5rem;
			}
		}
		span {
			display: block;

			&.number-scroll__text__fill {
				color: $black;
			}

			&.number-scroll__text__hollow {
				letter-spacing: 3.5px;
				color: $white;
				text-shadow: -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black, 1px 1px 0 $black;
			}
		}
	}
}