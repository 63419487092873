#chapter-4 {

	.chapter-content {
		margin-top: 140%;

		@include media-breakpoint-up(md) {
			margin-top: 35rem;
		}

		@media(min-width: 1024px) {
			margin-top: 22rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 18rem;
		}

		@media(min-width: 1400px) {
			margin-top: 0;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: 11%;
		}

		@include media-breakpoint-up(xhg) {
			margin-top: -5%;
		}
	}

	#section-1 {
		margin-top: -5rem;

		@include media-breakpoint-up(lg) {
			margin-top: 35rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -5rem;
		}

		#chapter-4__el-1 {
			text-align: right;
			padding-right: 11rem;
			margin-bottom: 4rem;

			@include media-breakpoint-up(md) {
				text-align: left;
				padding-right: 0;
			}

			@include media-breakpoint-up(xl) {
				padding-left: 5rem;
				margin-bottom: 8.5rem;
			}

			img {
				max-width: 10rem;

				@include media-breakpoint-up(xl) {
					max-width: 13rem;
				}
			}
		}

		#chapter-4__el-2 {
			text-align: right;

			@include media-breakpoint-up(md) {
				text-align: left;
				// padding-left: 11rem;
			}

			img {
				max-width: 15rem;

				@include media-breakpoint-up(xl) {
					max-width: 22rem;
				}
			}
		}

		#chapter-4__el-3 {
			margin-top: 4rem;
			max-width: 45rem;

			@include media-breakpoint-up(md) {
				margin-top: 0;
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: 8.5rem;
			}
		}
	}

	#section-3 {
		.section__bg {
			@include media-breakpoint-up(md) {
				margin-top: -20rem;
			}

			@include media-breakpoint-up(xl) {
				margin-top: 0;
			}
		}
		.section__content--top {
			z-index: 2;
			position: relative;

			@include media-breakpoint-up(xl) {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				z-index: 2;
			}
		}

		.section__content--bottom {
			@include media-breakpoint-up(md) {
				position: absolute;
				left: 0;
				bottom: 10%;
				width: 100%;
				z-index: 2;

				* {
					color: $white;
				}
			}

			@include media-breakpoint-up(xl) {
				bottom: 20%;
			}
		}

		.badge {
			width: 100%;
			margin: 0 auto 3rem auto;

			.badge__content p {
				@include media-breakpoint-up(md) {
					font-size: 2.5rem;
				}
			}
		}

		.quote-minimal {
			@include media-breakpoint-up(md) {
				text-align: right;
			}

			@include media-breakpoint-up(xl) {
				position: relative;
				left: -10rem;

			}
			.quote__icon {
				@include media-breakpoint-up(xl) {
					margin-top: -23rem;
				}
			}
			.quote__inner {
				margin-top: -7rem;

				@include media-breakpoint-up(md) {
					margin-top: -16rem;
				}

				@include media-breakpoint-up(xl) {
					position: relative;
					right: -4rem;
				}
			}
		}

		#chapter-4__el-7 {
			margin-top: 6rem;
		}
	}

	#section-4 {
		@include media-breakpoint-up(xl) {
			margin-bottom: 20rem;
		}

		#chapter-4__el-8 {
			@include media-breakpoint-up(md) {
				margin-top: 0;
			}
		}

		.number-scroll-container {
			@include media-breakpoint-up(xl) {
				position: relative;
				left: -8rem;
			}
		}

		#chapter-4__el-14 {
			margin-bottom: 5rem;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}
		}
	}

	#section-5 {
		.owl-carousel--split-primary-container {
			@include media-breakpoint-up(xl) {
				top: -15rem;
			}
		}

		.owl-carousel--split-off-screen.owl-carousel--split-right {
			bottom: -100%;

			@include media-breakpoint-up(xl) {
				bottom: 0;
			}
		}
	}

	#section-6 {
		#chapter-4__el-19,
		#chapter-4__el-20 {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;

			@include media-breakpoint-up(md) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		#chapter-4__el-19 {
			max-width: 43rem;

			@include media-breakpoint-up(xl) {
				margin-bottom: 15rem;
			}
		}

		#chapter-4__el-21 {
			@include media-breakpoint-up(md) {
				position: absolute;
				bottom: 0;
				right: 0;
				max-width: 58%;
				margin-bottom: 0;
				z-index: 2;
			}

			@include media-breakpoint-up(xl) {
				bottom: 7%;
				max-width: 59%;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 50%;
			}
		}
	}

	#section-7 {
		@include media-breakpoint-up(md) {
			margin-top: 4rem;
		}

		.row {
			@include media-breakpoint-up(md) {
				position: relative;
				right: -$grid-gutter-width / 2;
			}
		}

		#chapter-4__el-21 {
			@include media-breakpoint-up(md) {
				margin-top: 0;
			}
		}
	}
}