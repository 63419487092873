.hero {
	// min-height: 100vh;
	width: 100%;
	background-size: cover;
	background-position: center -70px;
	background-repeat: no-repeat;
	position: relative;
	background-image: url('../images/banners/hero_mobile.jpg');
	// overflow: hidden;

	@include media-breakpoint-up(sm) {
		background-image: url('../images/banners/hero_desktop.jpg');
	}

	@include media-breakpoint-up(md) {
		background-position: center center;
	}

	> .container {
		min-height: 100vh;
		display: flex;
		align-items: center;

		@media(max-width: 768px) {
			max-width: 100%;
			width: 100%;
			padding: 0;
		}
	}

	.owl-carousel--hero {

		.owl-nav {
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    display: none;

		    @include media-breakpoint-up(xl) {
		    	display: block;
		    }

		    .owl-prev,
		    .owl-next {
		    	border: 0 !important;
		    	appearance: none !important;
		    	background: none !important;
		    	outline: none !important;
		    	position: absolute;
				height: 36%;
				width: 18%;
		    	top: 50%;
		    	transform: translateY(-50%);
		    	display: block;
		    	opacity: 0;
		    }

		    .owl-prev {
		    	left: 0;
		    	cursor: url('../images/global/icons/left_arrow.svg'), auto;
		    }

		    .owl-next {
		    	right: 0;
		    	cursor: url('../images/global/icons/right_arrow.svg'), auto;
		    }
		}

		.owl-item {
			transition: opacity $transition-speed ease;
			// min-height: 100vh;
			position: relative;

			&.active {
				z-index: 9;
			}

			.item {
				text-align: center;
				height: 100%;
				position: relative;
				transition: max-width $transition-speed*2 ease;
				display: flex;
				align-items: center;
				justify-content: center;

				.item__title,
				.item__sub-title,
				.item__foreground__title {
					text-transform: uppercase;
					margin-top: 0;
					text-shadow: 10px 11px 29px rgba(18, 15, 15, 0.29);
				}
			}

			.item__bg,
			.item__foreground {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				transform: translateY(-50%);

				.item__bg__inner {
					transition: transform 1.5s ease, opacity $transition-speed*2 ease;
				}

				img {
					transition: max-width $transition-speed*4 ease, max-height $transition-speed*4 ease;
				}
			}

			.item__bg {
				z-index: 1;
			}

			.item__foreground {
				z-index: 4;
				opacity: 0;
				transition: opacity $transition-speed*2 ease;

				.item__foreground__inner {
					transition: transform 1.5s ease;
				}

				.item__foreground__title {
					color: $primary;
					transition: opacity $transition-speed*2 ease;
					margin-bottom: 0;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 100%;
					text-align: center;
					z-index: 6;
					font-size: 3.5rem;
					opacity: 0;
					transition-delay: 1s;

					@media(min-width: 375px) {
						font-size: 4.2rem;
					}

					@include media-breakpoint-up(md) {
						font-size: 6rem;
					}

					@include media-breakpoint-up(lg) {
						font-size: 8rem;
					}

					@include media-breakpoint-up(xl) {
						transform: scale(1.2);
					}

					@include media-breakpoint-up(xxl) {
						transform: scale(1.5);
					}
				}
			}

			.item__players {
				margin: 0 auto;
				opacity: 0;
				transition: opacity $transition-speed*2 ease, transform $transition-speed*3 ease;
				position: relative;
				z-index: 2;
				transform: scale(0.8);

				&.scale-out {
					transform: scale(0.8) !important;
					opacity: 0 !important;
				}

				> img {
					max-height: 30rem;
					width: auto;

					@media(min-width: 375px) {
						max-height: 35rem;
					}

					@media(min-width: 414px) {
						max-height: 40rem;
					}

					@include media-breakpoint-up(sm) {
						max-height: 50rem;
					}

					@include media-breakpoint-up(md) {
						max-height: 55rem;
					}

					@media(min-width: 1024px) {
						max-height: 65rem;
					}

					@include media-breakpoint-up(xl) {
						max-height: 70rem;
					}

					@include media-breakpoint-up(xxl) {
						max-height: 80rem;
					}
				}
			}

			.item__text {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				transform: translateY(-50%);
				text-align: center;
				opacity: 0;
				transition: opacity $transition-speed ease;
				z-index: 3;

				.item__title,
				.item__sub-title {
					color: $white;
				}

				.item__title {
					transition: transform $transition-speed*5 ease, margin $transition-speed*4 ease, opacity $transition-speed*2 ease;
				}

				.item__sub-title {
					transition: opacity $transition-speed ease;
				}

				.item__title {
					font-size: 10rem;
					line-height: 6rem;
					margin-bottom: 2rem;

					@media(min-width: 375px) {
						font-size: 12rem;
						line-height: 8rem;
					}

					@include media-breakpoint-up(sm) {
						font-size: 16rem;
						line-height: 12rem;
						margin-bottom: 3rem;
					}

					@include media-breakpoint-up(md) {
						font-size: 20rem;
						line-height: 16rem;
						margin-bottom: 1rem;
					}

					@include media-breakpoint-up(lg) {
						font-size: 26rem;
						line-height: 20rem;
					}

					@include media-breakpoint-up(xxl) {
						font-size: 33rem;
						line-height: 30rem;
					}
				}

				.item__sub-title {
					font-size: 3.5rem;
					line-height: 2.5rem;
					margin-bottom: 0;

					@media(min-width: 375px) {
						font-size: 4.5rem;
						line-height: 2.5rem;
					}

					@media(min-width: 414px) {
						font-size: 5rem;
						line-height: 3rem;
					}

					@include media-breakpoint-up(sm) {
						font-size: 6rem;
						line-height: 3rem;
					}

					@include media-breakpoint-up(md) {
						font-size: 5rem;
						line-height: 2rem;
					}

					@include media-breakpoint-up(lg) {
						font-size: 8rem;
						line-height: 5rem;
					}

					@include media-breakpoint-up(xxl) {
						font-size: 9rem;
						line-height: 7rem;
					}
				}
			}

			.item__line {
				position: absolute;
				top: 80%;
				left: 50%;
				height: 200px;
				width: 3px;
				background-color: $primary;
				transform-origin: 100% 0;
				transform: scaleY(0);
				transition: transform $transition-speed*5 ease, opacity $transition-speed ease;
				z-index: 5;

				@include media-breakpoint-up(lg) {
					top: 70%;
					height: 300px;
				}

				@include media-breakpoint-up(xxl) {
					top: 80%;
				}

				&.color--white {
					background-color: $white;
				}
			}

			.item#item-8 {
				.item__text {
					display: none;
				}
				
				.item__players img {
					max-height: 10rem;

					@media (min-width: 414px) {
						max-height: 13rem;
					}

					@include media-breakpoint-up(md) {
						max-height: 15rem;
					}

					@include media-breakpoint-up(lg) {
						max-height: 20rem;
					}

					@include media-breakpoint-up(xxl) {
						max-height: 30rem;
					}
				}

				// .item__foreground__inner {
				// 	margin-top: 0 !important;
				// }

				.item__foreground__inner--front {
					opacity: 0;
					position: absolute;
				    top: 50%;
				    left: 0;
				    width: 100%;
				    transform: translateY(-50%);
				    transition: opacity $transition-speed ease;

				    > img {
				    	@media(min-width: 375px) {
				    		transform: scale(1.2);
				    	}

				    	@include media-breakpoint-up(md) {
				    		transform: scale(1.7);
				    	}

				    	@include media-breakpoint-up(xl) {
							transform: scale(1.9);
				    	}

				    	@include media-breakpoint-up(xxl) {
							transform: scale(2.1);
				    	}
				    }
				}
			}

			&:not(.active) {
				.item__bg {
					transform: translateY(-50%);

					.item__bg__inner {
						transform: scale(0.7) !important;
						opacity: 0.5 !important;
					}
				}

				.item__players {
					transform: scale(0.8);
					opacity: 0;
				}
			}

			&.active--current {
				.item__players,
				.item__text {
					opacity: 1;
				}

				.item__players {
					transform: scale(1);
				}

				.item__text {
					transition-delay: 0.5s;
				}
			}

			&.active--prev .item__bg .item__bg__inner {
				transform-origin: right;
			}

			&.active--next .item__bg  .item__bg__inner {
				transform-origin: left;
			}
		}
	}

	.hero__dots {
		position: absolute;
		left: 50%;
		bottom: 2rem;
		width: 85%;
		transform: translateX(-50%);
		z-index: $zindex-navbar + 2;
		text-align: center;
		display: flex;
	    justify-content: space-between;
	    transition: opacity $transition-speed ease;

		&::before {
			content: '';
			display: block;
			width: 100%;
			position: absolute;
			top: 55%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 1px;
			background-color: $white;

			@media(min-width: 1024px) {
				top: 58.8%;
			}

			@include media-breakpoint-up(xl) {
				top: 0;
				left: auto;
				right: 14px;
				transform: none !important;
				width: 1px;
				height: 100%;
			}
		}

		@media(min-width: 375px) {
			bottom: 3rem;
		}

		@include media-breakpoint-up(md) {
			bottom: 5rem;
			width: 60%;
		}

		@include media-breakpoint-up(xl) {
			right: 3rem;
			top: 0;
			left: auto;
			bottom: auto;
			height: 100%;
			width: 3rem;
			display: flex;
		    flex-direction: column;
			align-items: center;
		    justify-content: center;
		}

		@include media-breakpoint-up(xxl) {
			right: 5rem;
		}

		.owl-dot {
			appearance: none !important;
			outline: none !important;
			border: 0 !important;
			background-color: transparent !important;
			padding: 0 !important;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			z-index: 2;
			cursor: pointer;

			@include media-breakpoint-up(xl) {
				display: block;
				margin-bottom: 4.5rem;
				width: 100%;
				text-align: center;

				&::after {
					display: inline-block;
					vertical-align: middle;
					color: $white;
					text-transform: uppercase;
					font-family: $headings-font-family;
					font-size: 3rem;
					line-height: 3rem;
					letter-spacing: 2px;
					position: absolute;
					left: 3rem;
					top: 7px;
					opacity: 0;
					transition: opacity $transition-speed ease;
				}

				&:first-child::after {
					content: 'Intro';
				}

				&:nth-child(2)::after {
					content: 'One';
				}

				&:nth-child(3)::after {
					content: 'Two';
				}

				&:nth-child(4)::after {
					content: 'Three';
				}

				&:nth-child(5) {
					@include media-breakpoint-up(xl) {
						> span {
							border: 1px solid $black;
						}
					}
					&::after {
						content: 'Four';
						color: $black;
					}
				}

				&:nth-child(6)::after {
					content: 'Five';
				}

				&:nth-child(7)::after {
					content: 'Six';
				}

				&:nth-child(8)::after {
					content: 'Seven';
				}

				&:last-child {
					margin-bottom: 0;

					&::after {
						content: 'Eight';
					}
				}
			}

			@include media-breakpoint-up(xxl) {
				margin-bottom: 5rem;

				&::after {
					left: 3.5rem;
				}
			}

			> span {
				display: inline-block;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background-color: $white;
				transition: transform $transition-speed ease;

				@include media-breakpoint-up(xl) {
					margin: 0 auto;
					width: 9px;
					height: 9px;
				}
			}

			&.active {
				> span {
					transform: scale(1.5);

					@include media-breakpoint-up(xl) {
						transform: scale(1.7);
					}
				}

				&::after {
					opacity: 1;
				}
			}
		}
	}

	.hero__btn {
		position: absolute;
		bottom: 13%;
		z-index: 5;
		width: 100%;
		text-align: center;
		transition: opacity $transition-speed ease;

		@include media-breakpoint-up(lg) {
			bottom: 13%;
		}

		@include media-breakpoint-up(xl) {
			bottom: 11%;
		}

		.btn {
			padding: 1.2rem 2rem 1rem 2.8rem;
			font-size: 1.1rem;

			@include media-breakpoint-up(md) {
				padding: 1.7rem 2rem 1.5rem 2.5rem;
				font-size: 1.5rem;
			}
		}
	}

	.hero__loader {
		position: absolute;
		left: 0.5rem;
		bottom: 2rem;
		display: none;

		@include media-breakpoint-up(lg) {
			left: 2.5rem;
			bottom: 3.5rem;
		}

		> img {
			max-width: 7rem;

			@include media-breakpoint-up(lg) {
				max-width: 10rem;
			}
		}
	}

	&.transitioned {
		background-image: none;

		.owl-carousel--hero .owl-item.active .item__line {
			transform: scaleY(1);
			opacity: 1;
			transition-delay: 1.5s;
		}

		.owl-carousel--hero .owl-item.active .item__foreground .item__foreground__title {
			opacity: 1;
		}

		.owl-carousel--hero .owl-item.active #item-8 .item__foreground__inner--front {
			opacity: 1;
		}
	}
}