// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  letter-spacing: $btn-letter-spacing;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid;
  padding: 0.75rem $btn-padding-x-sm;
  font-size: $btn-font-size-sm - 0.5rem;
  border-radius: $btn-border-radius;
  transition: all $transition-speed ease;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  min-width: $btn-width-sm;

  @media(min-width: 375px) {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius);
  }

  @include hover {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
    cursor: default;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  > .fa {
    font-size: $btn-font-size-sm - 0.25rem;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    right: 1rem;

    @media(min-width: 375px) {
      font-size: $btn-font-size-sm*1.5;
    }

    @include media-breakpoint-up(sm) {
      right: $btn-padding-x-sm;
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn--#{$color} {
    background-color: transparent;
    color: $value;
    border-color: $value;
    background-color: rgba($value, 0.5);

    &:hover,
    &:focus {
      background-color: $value;
      color: $black;
      background-color: $value;
    }

    &.disabled,
    &:disabled {
      &:hover,
      &:focus {
        background-color: $value;
        color: $black;
        border-color: $value;
      }
    }
  }
}

//
// Block button
//

.btn--block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn--block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}