.footer {
	position: relative;
	display: none;
	z-index: 1;

	&[data-current-chapter="0"] {
		z-index: 11;
		margin-top: -112%;

		@include media-breakpoint-up(md) {
			margin-top: -42%;
		}
	}

	&[data-current-chapter="1"] {
		margin-top: -15rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -15rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: -25rem;
		}
	}

	&[data-current-chapter="2"] {
		margin-top: -11rem;

		@include media-breakpoint-up(md) {
			margin-top: 2rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -10rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: -20rem;
		}
	}

	&[data-current-chapter="3"] {
		margin-top: -11rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -8rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: -20rem;
		}
	}


	&[data-current-chapter="4"] {
		margin-top: -11rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -10rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: -20rem;
		}
	}

	&[data-current-chapter="5"] {
		margin-top: -12rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -10rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: -15rem;
		}
	}

	&[data-current-chapter="6"] {
		margin-top: -5rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -10rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: -15rem;
		}

		.btn--next-chapter {
			@include media-breakpoint-down(xs) {
				.chapter-year {
					font-size: 6rem !important;
					line-height: 7rem !important;
				}

				&::after {
					right: -4.5rem;
				}
			}
		}
	}

	&[data-current-chapter="7"] {
		margin-top: -114%;
		z-index: unset;

		@include media-breakpoint-up(md) {
			margin-top: -46%;
		}

		.btn--next-chapter {
			@include media-breakpoint-down(xs) {
				.chapter-year {
					font-size: 6rem;
				}

				&::after {
					right: -4.5rem;
				}
			}
		}
	}

	&[data-current-chapter="8"] {
		margin-top: -50%;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -15%;
		}

		.footer__top .btn--next-chapter h1.chapter-year {
			font-size: 4rem;
			line-height: 5rem;

			@media (min-width: 375px) {
				font-size: 6rem;
    			line-height: 7rem;
			}

			@include media-breakpoint-up(md) {
				font-size: 12rem;
				line-height: 10rem;
			}
		}
	}

	.footer__bg img {
		width: 100%;
		height: auto;
	}

	.footer__bg--chapter-0,
	.footer__bg--chapter-7 {
		display: none;
	}

	.footer__top {
		position: absolute;
		left: 0;
		bottom: 50%;
		width: 100%;

		@include media-breakpoint-up(md) {
			bottom: 55%;
		}

		.btn--next-chapter {
			text-decoration: none;
			position: relative;
			display: inline-block;
			right: 2rem;

			@media(min-width: 375px) {
				right: 1rem;
			}

			// @media(min-width: 414px) {
			// 	right: 3rem;
			// }

			@include media-breakpoint-up(md) {
				float: right;
			    position: relative;
   				left: -4rem;
			}

			&::after {
				content: url('../images/global/icons/right_arrow_long_blue.svg');
				display: block;
				position: absolute;
				top: 50%;
				right: -1rem;
				transform: translateY(-50%);

				@include media-breakpoint-up(md) {
					right: -4rem;
				}
			}

			span {
				display: block;
			}

			span.chapter-number,
			span.chapter-title {
				font-family: $headings-font-family;
				font-size: 2.1rem;
				letter-spacing: 0.6px;
				line-height: 2.1rem;
				text-transform: uppercase;
				color: $gray-300;

				@include media-breakpoint-up(md) {
					font-size: 3rem;
					line-height: 3rem;
				}
			}

			span.chapter-number > span {
				display: inline-block;
			}

			h1.chapter-year {
				margin-bottom: 0;
				font-size: 6rem;
				line-height: 5rem;

				@media(min-width: 375px) {
					font-size: 8rem;
					line-height: 7rem;
				}

				@include media-breakpoint-up(md) {
					font-size: 14rem;
					line-height: 12rem;
				}
			}

			&[data-next="3"]::after,
			&[data-next="4"]::after,
			&[data-next="5"]::after,
			&[data-next="6"]::after,
			&[data-next="7"]::after,
			&[data-next="8"]::after {
				right: -4rem;
			}

			&[data-next="8"] {
				z-index: 99;
			}
		}

		.btn--back-to-top {
			text-decoration: none;
			position: relative;
			display: inline-block;

			span {
				display: inline-block;
				vertical-align: middle;
			}

			span.back-to-top__img {
				margin-right: 0.5rem;

				@include media-breakpoint-up(md) {
					width: 2.4rem;
					height: 2.4rem;
					margin-right: 1rem;
				}
			}

			span.back-to-top__text {
				font-family: $headings-font-family;
				letter-spacing: 0.6px;
				text-transform: uppercase;
				color: $gray-300;
				font-size: 2.1rem;
				line-height: 3.5rem;
				position: relative;
				top: 2px;

				@include media-breakpoint-up(md) {
					font-size: 3rem;
					line-height: 3rem;
					top: 4px;
				}

				@include media-breakpoint-up(lg) {
					top: 11px;
				}

				@include media-breakpoint-up(xl) {
					top: 11px;
				}
			}
		}
	}

	.footer__content {
		position: absolute;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    z-index: 1;
	    padding-bottom: 0.5rem;
	    color: $white;

	    @media(min-width: 414px) {
	    	padding-bottom: 2rem;
	    }

	    @include media-breakpoint-up(xxl) {
	    	padding-bottom: 6%;
	    }

	    .footer__middle {

			.footer__logo {
				display: inline-block;
				width: 100%;
				height: auto;

				&.footer__logo--1 {
					max-width: 9rem;

					@include media-breakpoint-up(xl) {
						max-width: 15rem;
					}
				}

				&.footer__logo--2 {
					max-width: 6.7rem;

					@include media-breakpoint-up(xl) {
						max-width: 11.2rem;
					}
				}
			}

			.btn {
				margin-top: 2rem;
				background-color: transparent !important;

				&:hover {
					border-color: $primary;
					background-color: $primary !important;
					color: $white !important;
				}

				@media(min-width: 375px) {
					margin-top: 3rem;
				}

				@include media-breakpoint-up(md) {
					margin: 0 auto;
					max-width: 38rem;
				}

				@include media-breakpoint-up(lg) {
					max-width: 60rem;
				}

				@include media-breakpoint-up(xl) {
					padding: 1.8rem 2rem 1.5rem 2rem;
				    font-size: 2.5rem;
				    line-height: 2.5rem;
				    border-radius: 0;
				    
					> .fa {
						font-size: $btn-font-size*1.5;
			      		right: $btn-padding-x;
					}
				}

				@include media-breakpoint-up(xxl) {
					max-width: 50rem;
				}
			}
		}

	    .footer__bottom {
	    	border-top: 1px solid $white;
	    	padding-top: 1rem;
	    	margin-top: 2rem;

	    	@media(min-width: 375px) {
				margin-top: 3rem;
	    	}

	    	@media(min-width: 414px) {
				padding-top: 1.2rem;
				margin-top: 4rem;
	    	}

	    	@include media-breakpoint-up(md) {
	    		margin-top: 1.5rem;
	    	}

	    	@include media-breakpoint-up(lg) {
				margin-top: 3.5rem;
	    	}

			a,
			p {
				color: $white;
				text-decoration: none;
				display: inline-block;
			}

			.small {
				font-size: 0.8rem;
				line-height: 1.3rem;

				@include media-breakpoint-up(sm) {
					font-size: $small-font-size;
				}
			}
	    }
	}
}