.dual-scroll {
	// overflow: hidden;

	.container {
		// @include media-breakpoint-up(xl) {
		// 	max-width: 960px !important;
		// }
	}

	.dual-scroll__text {
		display: none;

		@include media-breakpoint-up(md) {
			display: block;
		}

		// @include media-breakpoint-up(xxl) {
		// 	padding-top: 200%;
		// }

		h1 {
			margin-bottom: 4rem;

			&:last-child {
				margin-bottom: 0;
			}

			span {
				display: inline-block;
			}

			span.color--black {
				margin-right: -3px;

				@include media-breakpoint-up(xl) {
					margin-right: -7px;
				}
			}

			span.color--white {
				text-shadow: -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black, 1px 1px 0 $black;
				letter-spacing: 3.5px;
			}

			@include media-breakpoint-up(xl) {
				font-size: 8.5rem;
				line-height: 7.5rem;
				margin-bottom: 3rem;
			}
		}
	}
}