#chapter-7 {

	.chapter-content {
		margin-top: -30%;

		@include media-breakpoint-up(xl) {
			margin-top: -20%;
		}

		@media(min-width: 1400px) {
			margin-top: -10%;
		}
	}

	#section-1 {
		#chapter-7__el-2 {
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				left: 0;
				max-width: 45%;
				padding-left: 0;
				padding-right: 0;
				margin: 0;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 40%;
			}

			@media(min-width: 1800px) {
				max-width: 43%;
			}
		}
	}

	#section-2 {
		@include media-breakpoint-up(md) {

		}

		@include media-breakpoint-up(xxl) {
			margin-top: 20rem;
			margin-bottom: 20rem;
		}

		@media(min-width: 1800px) {
			margin-top: 25rem;
			margin-bottom: 25rem;
		}

		#chapter-7__el-4 {
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				right: 0;
				max-width: 45%;
				padding-left: 0;
				padding-right: 0;
				margin: 0;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 40%;
			}

			@media(min-width: 1800px) {
				max-width: 43%;
			}

			.text--caption {
				padding-left: $grid-gutter-width / 2;
			}
		}
	}

	#section-3 {
		#chapter-7__el-5 {
			padding-bottom: 11rem;
			position: relative;

			@include media-breakpoint-up(md) {
				margin-left: -$grid-gutter-width / 2;
			}

			@include media-breakpoint-up(lg) {
				padding-bottom: 20rem;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 105rem;
				padding-bottom: 25rem;
			}

			img {
				width: 100%;
			}

			.l-2 {
				position: absolute;
				bottom: 0;
				right: 0;
				max-width: 20rem;

				@include media-breakpoint-up(md) {
					max-width: 25rem;
				}

				@include media-breakpoint-up(lg) {
					max-width: 35rem;
				}

				@include media-breakpoint-up(xl) {
					max-width: 45rem;
				}

				@include media-breakpoint-up(xxl) {
					max-width: 55rem;
    				right: -13rem;
				}

				// @media(min-width: 1800px) {
				// 	max-width: 35rem;
				// }
			}

			.text--caption {
				@include media-breakpoint-up(md) {
					padding-left: $grid-gutter-width / 2;
				}
			}
		}

		#chapter-7__el-6 {
			margin-top: -9rem;
			max-width: 20rem;

			@media(min-width: 375px) {
				max-width: 26rem;
			}

			@media(min-width: 414px) {
				min-width: 28rem;
			}

			@include media-breakpoint-up(md) {
				margin-top: 0;
				max-width: 39.6rem;
			}
		}
	}

	#section-4 {

		@include media-breakpoint-up(md) {
			margin-top: 8.5rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: 12rem;
		}

		#chapter-7__el-7 {
			margin-top: 4rem;

			@include media-breakpoint-up(md) {
				margin-top: 0;
				margin-right: -$grid-gutter-width / 2;
			}
		}


		#chapter-7__el-8 {
			@include media-breakpoint-down(xs) {
				padding: 0;
				margin-top: 4rem;
			}

			@include media-breakpoint-up(xl) {
				position: absolute;
				top: 0;
				left: -7rem;
				max-width: 40rem;
				width: 100%;
				padding: 2rem;
			}

			@include media-breakpoint-up(xxl) {
				left: -12rem;
				max-width: 50rem;
			}

			@media(min-width: 1800px) {
				left: -22rem;
			}
		}

		#chapter-7__el-7-a {
			position: relative;
			z-index: 2;
			margin-top: -50%;
			max-width: 15rem;
		}

		#chapter-7__el-9 {
			margin-top: 4rem;
		    margin-bottom: 4rem;
		    margin-left: $grid-gutter-width / 2;
		    max-width: 15rem;

			@include media-breakpoint-up(md) {
				margin-right: 0;
				max-width: 22rem;
			}

			@include media-breakpoint-up(xl) {
				max-width: 35rem;
				margin-top: 0;
			}
		}
	}

	#section-5 {
		#chapter-7__el-10 {
			margin-bottom: 4rem;
			position: relative;
			z-index: 2;

			@include media-breakpoint-down(xs) {
				padding: 0;
			}

			@include media-breakpoint-up(md) {
				margin-bottom: -7rem;
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: -12rem;
			}

			p {
				margin-bottom: 03;
			}
		}
	}

	#section-6 {
		@include media-breakpoint-up(md) {
			margin-top: 4rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 8.5rem;
		}

		.owl-carousel--split-primary-container {
			top: 0;
		}

		.owl-carousel--split-off-screen {
			@include media-breakpoint-up(xl) {
				bottom: 50%;
    			transform: translateY(50%);
			}
		}

		#chapter-7__el-13 {
			margin: 4rem 0;
			max-width: 39rem;

			@include media-breakpoint-up(md) {
				margin: 0;
			}
		}

		#chapter-7__el-14 {
			margin-top: -6rem;

			@include media-breakpoint-up(lg) {
				// margin-top: -15rem;
				position: relative;
				left: 10rem;
			}

			@include media-breakpoint-up(xl) {
				margin-top: -10rem;
				max-width: 59rem;
				left: 0;
			}

			.quote__inner {
				margin-top: -10rem;
    			padding-left: 1rem;

				@include media-breakpoint-up(xl) {
					margin-top: -12rem;
					padding-left: 0;
					position: relative;
					left: -15rem;
				}

				@include media-breakpoint-up(xl) {
					left: -25rem;
				}
			}

			.quote__statement {
				font-size: 1.6rem;
    			line-height: 2.5rem;

				@include media-breakpoint-up(xl) {
					font-size: 3rem;
    				line-height: 4rem;
				}
			}
		}
	}

	#section-7 {
		#chapter-7__el-15 {
			margin-bottom: 2rem;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}
		}
	}

	#section-8 {
		#chapter-7__el-16 {
			margin-top: 2rem;
			margin-bottom: 8rem;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}

			@include media-breakpoint-up(lg) {
				margin-top: 4rem;
			}
		}

		#chapter-7__el-17 {
			@include media-breakpoint-up(md) {
				margin-top: 5rem;
			}

			@include media-breakpoint-up(lg) {
				margin-top: 10rem;
			}

			@include media-breakpoint-up(xl) {
				max-width: 85% !important;
			}

			span.type-in {
				font-family: "DharmaGothicCW01";
			    font-weight: 800;
			    color: #008AAB;
			    text-transform: uppercase;
			    font-size: 6rem;
			    line-height: 5rem;
			    letter-spacing: 0.9px;

				@include media-breakpoint-up(lg) {
				    font-size: 10rem;
				    line-height: 8rem;
				    letter-spacing: 1.5px;
				}
			}
		}
	}

	#section-9 {
		.section__content {
			position: absolute;
			left: 0;
			bottom: 20%;
			width: 100%;

			.container {
				@include media-breakpoint-up(xxl) {
					max-width: 1300px;
				}
			}

			.btn--back-to-top {
				text-decoration: none;
				position: relative;
				display: inline-block;

				span {
					display: inline-block;
					vertical-align: middle;
				}

				span.back-to-top__img {
					margin-right: 0.5rem;

					@include media-breakpoint-up(md) {
						width: 2.4rem;
						height: 2.4rem;
						margin-right: 1rem;
					}
				}

				span.back-to-top__text {
					font-family: $headings-font-family;
					letter-spacing: 0.6px;
					text-transform: uppercase;
					color: $gray-300;
					font-size: 2.1rem;
					line-height: 3.5rem;
					position: relative;
					top: 2px;

					@include media-breakpoint-up(md) {
						font-size: 3rem;
						line-height: 3rem;
						top: 4px;
					}

					@include media-breakpoint-up(lg) {
						top: 11px;
					}

					@include media-breakpoint-up(xl) {
						top: 11px;
					}
				}
			}
		}
	}
}