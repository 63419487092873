@import url(/assets/fonts/BrandonGrotesque/stylesheet.css);
@import url(/assets/fonts/DharmaGothic/stylesheet.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #E5E5E5;
  --gray-dark: #E5E5E5;
  --primary: #008AAB;
  --secondary: #000;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #E5E5E5;
  --dark: #E5E5E5;
  --black: #000;
  --white: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1500px;
  --breakpoint-hg: 2000px;
  --breakpoint-xhg: 2300px;
  --font-family-sans-serif: "Brandon Grotesque", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #808285;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #E5E5E5;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1300px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-hg-1, .col-hg-2, .col-hg-3, .col-hg-4, .col-hg-5, .col-hg-6, .col-hg-7, .col-hg-8, .col-hg-9, .col-hg-10, .col-hg-11, .col-hg-12, .col-hg,
.col-hg-auto, .col-xhg-1, .col-xhg-2, .col-xhg-3, .col-xhg-4, .col-xhg-5, .col-xhg-6, .col-xhg-7, .col-xhg-8, .col-xhg-9, .col-xhg-10, .col-xhg-11, .col-xhg-12, .col-xhg,
.col-xhg-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1500px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 2000px) {
  .col-hg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-hg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-hg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-hg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-hg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-hg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-hg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-hg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-hg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-hg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-hg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-hg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-hg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-hg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-hg-first {
    order: -1;
  }
  .order-hg-last {
    order: 13;
  }
  .order-hg-0 {
    order: 0;
  }
  .order-hg-1 {
    order: 1;
  }
  .order-hg-2 {
    order: 2;
  }
  .order-hg-3 {
    order: 3;
  }
  .order-hg-4 {
    order: 4;
  }
  .order-hg-5 {
    order: 5;
  }
  .order-hg-6 {
    order: 6;
  }
  .order-hg-7 {
    order: 7;
  }
  .order-hg-8 {
    order: 8;
  }
  .order-hg-9 {
    order: 9;
  }
  .order-hg-10 {
    order: 10;
  }
  .order-hg-11 {
    order: 11;
  }
  .order-hg-12 {
    order: 12;
  }
  .offset-hg-0 {
    margin-left: 0;
  }
  .offset-hg-1 {
    margin-left: 8.33333%;
  }
  .offset-hg-2 {
    margin-left: 16.66667%;
  }
  .offset-hg-3 {
    margin-left: 25%;
  }
  .offset-hg-4 {
    margin-left: 33.33333%;
  }
  .offset-hg-5 {
    margin-left: 41.66667%;
  }
  .offset-hg-6 {
    margin-left: 50%;
  }
  .offset-hg-7 {
    margin-left: 58.33333%;
  }
  .offset-hg-8 {
    margin-left: 66.66667%;
  }
  .offset-hg-9 {
    margin-left: 75%;
  }
  .offset-hg-10 {
    margin-left: 83.33333%;
  }
  .offset-hg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 2300px) {
  .col-xhg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xhg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xhg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xhg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xhg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xhg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xhg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xhg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xhg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xhg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xhg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xhg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xhg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xhg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xhg-first {
    order: -1;
  }
  .order-xhg-last {
    order: 13;
  }
  .order-xhg-0 {
    order: 0;
  }
  .order-xhg-1 {
    order: 1;
  }
  .order-xhg-2 {
    order: 2;
  }
  .order-xhg-3 {
    order: 3;
  }
  .order-xhg-4 {
    order: 4;
  }
  .order-xhg-5 {
    order: 5;
  }
  .order-xhg-6 {
    order: 6;
  }
  .order-xhg-7 {
    order: 7;
  }
  .order-xhg-8 {
    order: 8;
  }
  .order-xhg-9 {
    order: 9;
  }
  .order-xhg-10 {
    order: 10;
  }
  .order-xhg-11 {
    order: 11;
  }
  .order-xhg-12 {
    order: 12;
  }
  .offset-xhg-0 {
    margin-left: 0;
  }
  .offset-xhg-1 {
    margin-left: 8.33333%;
  }
  .offset-xhg-2 {
    margin-left: 16.66667%;
  }
  .offset-xhg-3 {
    margin-left: 25%;
  }
  .offset-xhg-4 {
    margin-left: 33.33333%;
  }
  .offset-xhg-5 {
    margin-left: 41.66667%;
  }
  .offset-xhg-6 {
    margin-left: 50%;
  }
  .offset-xhg-7 {
    margin-left: 58.33333%;
  }
  .offset-xhg-8 {
    margin-left: 66.66667%;
  }
  .offset-xhg-9 {
    margin-left: 75%;
  }
  .offset-xhg-10 {
    margin-left: 83.33333%;
  }
  .offset-xhg-11 {
    margin-left: 91.66667%;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 0.7;
  color: #E5E5E5;
  text-align: center;
  white-space: nowrap;
  background-color: #A7A9AC;
  border: 1px solid #E5E5E5;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 2.75rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.925rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #008AAB !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #006178 !important;
}

.bg-secondary {
  background-color: #000 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: black !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #E5E5E5 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cccccc !important;
}

.bg-dark {
  background-color: #E5E5E5 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #cccccc !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #808285 !important;
}

.border-top {
  border-top: 1px solid #808285 !important;
}

.border-right {
  border-right: 1px solid #808285 !important;
}

.border-bottom {
  border-bottom: 1px solid #808285 !important;
}

.border-left {
  border-left: 1px solid #808285 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #008AAB !important;
}

.border-secondary {
  border-color: #000 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #E5E5E5 !important;
}

.border-dark {
  border-color: #E5E5E5 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1500px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 2000px) {
  .d-hg-none {
    display: none !important;
  }
  .d-hg-inline {
    display: inline !important;
  }
  .d-hg-inline-block {
    display: inline-block !important;
  }
  .d-hg-block {
    display: block !important;
  }
  .d-hg-table {
    display: table !important;
  }
  .d-hg-table-row {
    display: table-row !important;
  }
  .d-hg-table-cell {
    display: table-cell !important;
  }
  .d-hg-flex {
    display: flex !important;
  }
  .d-hg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 2300px) {
  .d-xhg-none {
    display: none !important;
  }
  .d-xhg-inline {
    display: inline !important;
  }
  .d-xhg-inline-block {
    display: inline-block !important;
  }
  .d-xhg-block {
    display: block !important;
  }
  .d-xhg-table {
    display: table !important;
  }
  .d-xhg-table-row {
    display: table-row !important;
  }
  .d-xhg-table-cell {
    display: table-cell !important;
  }
  .d-xhg-flex {
    display: flex !important;
  }
  .d-xhg-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1500px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 2000px) {
  .flex-hg-row {
    flex-direction: row !important;
  }
  .flex-hg-column {
    flex-direction: column !important;
  }
  .flex-hg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-hg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-hg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-hg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-hg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-hg-fill {
    flex: 1 1 auto !important;
  }
  .flex-hg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-hg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-hg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-hg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-hg-start {
    justify-content: flex-start !important;
  }
  .justify-content-hg-end {
    justify-content: flex-end !important;
  }
  .justify-content-hg-center {
    justify-content: center !important;
  }
  .justify-content-hg-between {
    justify-content: space-between !important;
  }
  .justify-content-hg-around {
    justify-content: space-around !important;
  }
  .align-items-hg-start {
    align-items: flex-start !important;
  }
  .align-items-hg-end {
    align-items: flex-end !important;
  }
  .align-items-hg-center {
    align-items: center !important;
  }
  .align-items-hg-baseline {
    align-items: baseline !important;
  }
  .align-items-hg-stretch {
    align-items: stretch !important;
  }
  .align-content-hg-start {
    align-content: flex-start !important;
  }
  .align-content-hg-end {
    align-content: flex-end !important;
  }
  .align-content-hg-center {
    align-content: center !important;
  }
  .align-content-hg-between {
    align-content: space-between !important;
  }
  .align-content-hg-around {
    align-content: space-around !important;
  }
  .align-content-hg-stretch {
    align-content: stretch !important;
  }
  .align-self-hg-auto {
    align-self: auto !important;
  }
  .align-self-hg-start {
    align-self: flex-start !important;
  }
  .align-self-hg-end {
    align-self: flex-end !important;
  }
  .align-self-hg-center {
    align-self: center !important;
  }
  .align-self-hg-baseline {
    align-self: baseline !important;
  }
  .align-self-hg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 2300px) {
  .flex-xhg-row {
    flex-direction: row !important;
  }
  .flex-xhg-column {
    flex-direction: column !important;
  }
  .flex-xhg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xhg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xhg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xhg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xhg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xhg-fill {
    flex: 1 1 auto !important;
  }
  .flex-xhg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xhg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xhg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xhg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xhg-start {
    justify-content: flex-start !important;
  }
  .justify-content-xhg-end {
    justify-content: flex-end !important;
  }
  .justify-content-xhg-center {
    justify-content: center !important;
  }
  .justify-content-xhg-between {
    justify-content: space-between !important;
  }
  .justify-content-xhg-around {
    justify-content: space-around !important;
  }
  .align-items-xhg-start {
    align-items: flex-start !important;
  }
  .align-items-xhg-end {
    align-items: flex-end !important;
  }
  .align-items-xhg-center {
    align-items: center !important;
  }
  .align-items-xhg-baseline {
    align-items: baseline !important;
  }
  .align-items-xhg-stretch {
    align-items: stretch !important;
  }
  .align-content-xhg-start {
    align-content: flex-start !important;
  }
  .align-content-xhg-end {
    align-content: flex-end !important;
  }
  .align-content-xhg-center {
    align-content: center !important;
  }
  .align-content-xhg-between {
    align-content: space-between !important;
  }
  .align-content-xhg-around {
    align-content: space-around !important;
  }
  .align-content-xhg-stretch {
    align-content: stretch !important;
  }
  .align-self-xhg-auto {
    align-self: auto !important;
  }
  .align-self-xhg-start {
    align-self: flex-start !important;
  }
  .align-self-xhg-end {
    align-self: flex-end !important;
  }
  .align-self-xhg-center {
    align-self: center !important;
  }
  .align-self-xhg-baseline {
    align-self: baseline !important;
  }
  .align-self-xhg-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1500px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

@media (min-width: 2000px) {
  .float-hg-left {
    float: left !important;
  }
  .float-hg-right {
    float: right !important;
  }
  .float-hg-none {
    float: none !important;
  }
}

@media (min-width: 2300px) {
  .float-xhg-left {
    float: left !important;
  }
  .float-xhg-right {
    float: right !important;
  }
  .float-xhg-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1500px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 2000px) {
  .m-hg-0 {
    margin: 0 !important;
  }
  .mt-hg-0,
  .my-hg-0 {
    margin-top: 0 !important;
  }
  .mr-hg-0,
  .mx-hg-0 {
    margin-right: 0 !important;
  }
  .mb-hg-0,
  .my-hg-0 {
    margin-bottom: 0 !important;
  }
  .ml-hg-0,
  .mx-hg-0 {
    margin-left: 0 !important;
  }
  .m-hg-1 {
    margin: 0.25rem !important;
  }
  .mt-hg-1,
  .my-hg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-hg-1,
  .mx-hg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-hg-1,
  .my-hg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-hg-1,
  .mx-hg-1 {
    margin-left: 0.25rem !important;
  }
  .m-hg-2 {
    margin: 0.5rem !important;
  }
  .mt-hg-2,
  .my-hg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-hg-2,
  .mx-hg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-hg-2,
  .my-hg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-hg-2,
  .mx-hg-2 {
    margin-left: 0.5rem !important;
  }
  .m-hg-3 {
    margin: 1rem !important;
  }
  .mt-hg-3,
  .my-hg-3 {
    margin-top: 1rem !important;
  }
  .mr-hg-3,
  .mx-hg-3 {
    margin-right: 1rem !important;
  }
  .mb-hg-3,
  .my-hg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-hg-3,
  .mx-hg-3 {
    margin-left: 1rem !important;
  }
  .m-hg-4 {
    margin: 1.5rem !important;
  }
  .mt-hg-4,
  .my-hg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-hg-4,
  .mx-hg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-hg-4,
  .my-hg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-hg-4,
  .mx-hg-4 {
    margin-left: 1.5rem !important;
  }
  .m-hg-5 {
    margin: 3rem !important;
  }
  .mt-hg-5,
  .my-hg-5 {
    margin-top: 3rem !important;
  }
  .mr-hg-5,
  .mx-hg-5 {
    margin-right: 3rem !important;
  }
  .mb-hg-5,
  .my-hg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-hg-5,
  .mx-hg-5 {
    margin-left: 3rem !important;
  }
  .p-hg-0 {
    padding: 0 !important;
  }
  .pt-hg-0,
  .py-hg-0 {
    padding-top: 0 !important;
  }
  .pr-hg-0,
  .px-hg-0 {
    padding-right: 0 !important;
  }
  .pb-hg-0,
  .py-hg-0 {
    padding-bottom: 0 !important;
  }
  .pl-hg-0,
  .px-hg-0 {
    padding-left: 0 !important;
  }
  .p-hg-1 {
    padding: 0.25rem !important;
  }
  .pt-hg-1,
  .py-hg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-hg-1,
  .px-hg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-hg-1,
  .py-hg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-hg-1,
  .px-hg-1 {
    padding-left: 0.25rem !important;
  }
  .p-hg-2 {
    padding: 0.5rem !important;
  }
  .pt-hg-2,
  .py-hg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-hg-2,
  .px-hg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-hg-2,
  .py-hg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-hg-2,
  .px-hg-2 {
    padding-left: 0.5rem !important;
  }
  .p-hg-3 {
    padding: 1rem !important;
  }
  .pt-hg-3,
  .py-hg-3 {
    padding-top: 1rem !important;
  }
  .pr-hg-3,
  .px-hg-3 {
    padding-right: 1rem !important;
  }
  .pb-hg-3,
  .py-hg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-hg-3,
  .px-hg-3 {
    padding-left: 1rem !important;
  }
  .p-hg-4 {
    padding: 1.5rem !important;
  }
  .pt-hg-4,
  .py-hg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-hg-4,
  .px-hg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-hg-4,
  .py-hg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-hg-4,
  .px-hg-4 {
    padding-left: 1.5rem !important;
  }
  .p-hg-5 {
    padding: 3rem !important;
  }
  .pt-hg-5,
  .py-hg-5 {
    padding-top: 3rem !important;
  }
  .pr-hg-5,
  .px-hg-5 {
    padding-right: 3rem !important;
  }
  .pb-hg-5,
  .py-hg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-hg-5,
  .px-hg-5 {
    padding-left: 3rem !important;
  }
  .m-hg-n1 {
    margin: -0.25rem !important;
  }
  .mt-hg-n1,
  .my-hg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-hg-n1,
  .mx-hg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-hg-n1,
  .my-hg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-hg-n1,
  .mx-hg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-hg-n2 {
    margin: -0.5rem !important;
  }
  .mt-hg-n2,
  .my-hg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-hg-n2,
  .mx-hg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-hg-n2,
  .my-hg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-hg-n2,
  .mx-hg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-hg-n3 {
    margin: -1rem !important;
  }
  .mt-hg-n3,
  .my-hg-n3 {
    margin-top: -1rem !important;
  }
  .mr-hg-n3,
  .mx-hg-n3 {
    margin-right: -1rem !important;
  }
  .mb-hg-n3,
  .my-hg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-hg-n3,
  .mx-hg-n3 {
    margin-left: -1rem !important;
  }
  .m-hg-n4 {
    margin: -1.5rem !important;
  }
  .mt-hg-n4,
  .my-hg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-hg-n4,
  .mx-hg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-hg-n4,
  .my-hg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-hg-n4,
  .mx-hg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-hg-n5 {
    margin: -3rem !important;
  }
  .mt-hg-n5,
  .my-hg-n5 {
    margin-top: -3rem !important;
  }
  .mr-hg-n5,
  .mx-hg-n5 {
    margin-right: -3rem !important;
  }
  .mb-hg-n5,
  .my-hg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-hg-n5,
  .mx-hg-n5 {
    margin-left: -3rem !important;
  }
  .m-hg-auto {
    margin: auto !important;
  }
  .mt-hg-auto,
  .my-hg-auto {
    margin-top: auto !important;
  }
  .mr-hg-auto,
  .mx-hg-auto {
    margin-right: auto !important;
  }
  .mb-hg-auto,
  .my-hg-auto {
    margin-bottom: auto !important;
  }
  .ml-hg-auto,
  .mx-hg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 2300px) {
  .m-xhg-0 {
    margin: 0 !important;
  }
  .mt-xhg-0,
  .my-xhg-0 {
    margin-top: 0 !important;
  }
  .mr-xhg-0,
  .mx-xhg-0 {
    margin-right: 0 !important;
  }
  .mb-xhg-0,
  .my-xhg-0 {
    margin-bottom: 0 !important;
  }
  .ml-xhg-0,
  .mx-xhg-0 {
    margin-left: 0 !important;
  }
  .m-xhg-1 {
    margin: 0.25rem !important;
  }
  .mt-xhg-1,
  .my-xhg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xhg-1,
  .mx-xhg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xhg-1,
  .my-xhg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xhg-1,
  .mx-xhg-1 {
    margin-left: 0.25rem !important;
  }
  .m-xhg-2 {
    margin: 0.5rem !important;
  }
  .mt-xhg-2,
  .my-xhg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xhg-2,
  .mx-xhg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xhg-2,
  .my-xhg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xhg-2,
  .mx-xhg-2 {
    margin-left: 0.5rem !important;
  }
  .m-xhg-3 {
    margin: 1rem !important;
  }
  .mt-xhg-3,
  .my-xhg-3 {
    margin-top: 1rem !important;
  }
  .mr-xhg-3,
  .mx-xhg-3 {
    margin-right: 1rem !important;
  }
  .mb-xhg-3,
  .my-xhg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xhg-3,
  .mx-xhg-3 {
    margin-left: 1rem !important;
  }
  .m-xhg-4 {
    margin: 1.5rem !important;
  }
  .mt-xhg-4,
  .my-xhg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xhg-4,
  .mx-xhg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xhg-4,
  .my-xhg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xhg-4,
  .mx-xhg-4 {
    margin-left: 1.5rem !important;
  }
  .m-xhg-5 {
    margin: 3rem !important;
  }
  .mt-xhg-5,
  .my-xhg-5 {
    margin-top: 3rem !important;
  }
  .mr-xhg-5,
  .mx-xhg-5 {
    margin-right: 3rem !important;
  }
  .mb-xhg-5,
  .my-xhg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xhg-5,
  .mx-xhg-5 {
    margin-left: 3rem !important;
  }
  .p-xhg-0 {
    padding: 0 !important;
  }
  .pt-xhg-0,
  .py-xhg-0 {
    padding-top: 0 !important;
  }
  .pr-xhg-0,
  .px-xhg-0 {
    padding-right: 0 !important;
  }
  .pb-xhg-0,
  .py-xhg-0 {
    padding-bottom: 0 !important;
  }
  .pl-xhg-0,
  .px-xhg-0 {
    padding-left: 0 !important;
  }
  .p-xhg-1 {
    padding: 0.25rem !important;
  }
  .pt-xhg-1,
  .py-xhg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xhg-1,
  .px-xhg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xhg-1,
  .py-xhg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xhg-1,
  .px-xhg-1 {
    padding-left: 0.25rem !important;
  }
  .p-xhg-2 {
    padding: 0.5rem !important;
  }
  .pt-xhg-2,
  .py-xhg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xhg-2,
  .px-xhg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xhg-2,
  .py-xhg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xhg-2,
  .px-xhg-2 {
    padding-left: 0.5rem !important;
  }
  .p-xhg-3 {
    padding: 1rem !important;
  }
  .pt-xhg-3,
  .py-xhg-3 {
    padding-top: 1rem !important;
  }
  .pr-xhg-3,
  .px-xhg-3 {
    padding-right: 1rem !important;
  }
  .pb-xhg-3,
  .py-xhg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xhg-3,
  .px-xhg-3 {
    padding-left: 1rem !important;
  }
  .p-xhg-4 {
    padding: 1.5rem !important;
  }
  .pt-xhg-4,
  .py-xhg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xhg-4,
  .px-xhg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xhg-4,
  .py-xhg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xhg-4,
  .px-xhg-4 {
    padding-left: 1.5rem !important;
  }
  .p-xhg-5 {
    padding: 3rem !important;
  }
  .pt-xhg-5,
  .py-xhg-5 {
    padding-top: 3rem !important;
  }
  .pr-xhg-5,
  .px-xhg-5 {
    padding-right: 3rem !important;
  }
  .pb-xhg-5,
  .py-xhg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xhg-5,
  .px-xhg-5 {
    padding-left: 3rem !important;
  }
  .m-xhg-n1 {
    margin: -0.25rem !important;
  }
  .mt-xhg-n1,
  .my-xhg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xhg-n1,
  .mx-xhg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xhg-n1,
  .my-xhg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xhg-n1,
  .mx-xhg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xhg-n2 {
    margin: -0.5rem !important;
  }
  .mt-xhg-n2,
  .my-xhg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xhg-n2,
  .mx-xhg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xhg-n2,
  .my-xhg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xhg-n2,
  .mx-xhg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xhg-n3 {
    margin: -1rem !important;
  }
  .mt-xhg-n3,
  .my-xhg-n3 {
    margin-top: -1rem !important;
  }
  .mr-xhg-n3,
  .mx-xhg-n3 {
    margin-right: -1rem !important;
  }
  .mb-xhg-n3,
  .my-xhg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xhg-n3,
  .mx-xhg-n3 {
    margin-left: -1rem !important;
  }
  .m-xhg-n4 {
    margin: -1.5rem !important;
  }
  .mt-xhg-n4,
  .my-xhg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xhg-n4,
  .mx-xhg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xhg-n4,
  .my-xhg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xhg-n4,
  .mx-xhg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xhg-n5 {
    margin: -3rem !important;
  }
  .mt-xhg-n5,
  .my-xhg-n5 {
    margin-top: -3rem !important;
  }
  .mr-xhg-n5,
  .mx-xhg-n5 {
    margin-right: -3rem !important;
  }
  .mb-xhg-n5,
  .my-xhg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xhg-n5,
  .mx-xhg-n5 {
    margin-left: -3rem !important;
  }
  .m-xhg-auto {
    margin: auto !important;
  }
  .mt-xhg-auto,
  .my-xhg-auto {
    margin-top: auto !important;
  }
  .mr-xhg-auto,
  .mx-xhg-auto {
    margin-right: auto !important;
  }
  .mb-xhg-auto,
  .my-xhg-auto {
    margin-bottom: auto !important;
  }
  .ml-xhg-auto,
  .mx-xhg-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1500px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 2000px) {
  .text-hg-left {
    text-align: left !important;
  }
  .text-hg-right {
    text-align: right !important;
  }
  .text-hg-center {
    text-align: center !important;
  }
}

@media (min-width: 2300px) {
  .text-xhg-left {
    text-align: left !important;
  }
  .text-xhg-right {
    text-align: right !important;
  }
  .text-xhg-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 800 !important;
}

.font-weight-bolder {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #008AAB !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #004c5f !important;
}

.text-secondary {
  color: #000 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: black !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #E5E5E5 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #bfbfbf !important;
}

.text-dark {
  color: #E5E5E5 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #bfbfbf !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: #E5E5E5 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #E5E5E5;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #808285 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #808285;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #808285;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Brandon Grotesque", sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 3rem;
  color: #000;
  text-align: left;
  background-color: #fff;
}

body::before {
  content: '';
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  background-image: url("../images/global/PortAdelaide_A_2020_RGB.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 11rem;
  height: 28rem;
}

@media (min-width: 768px) {
  body::before {
    width: 18rem;
  }
}

@media (min-width: 992px) {
  body {
    font-size: 2.2rem;
    line-height: 4rem;
  }
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.7rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol > li,
ul > li,
dl > li {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 800;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: 500;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #008AAB;
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #004c5f;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #E5E5E5;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 0.5rem;
  z-index: 1090;
  top: 4.5rem;
  transition: top 0.9s ease;
}

@media (min-width: 768px) {
  .header {
    top: 7rem;
  }
  .header.transitioned {
    top: 0;
  }
}

@media (min-width: 1200px) {
  .header {
    padding: 3.5rem 2.5rem;
  }
}

.header .brand {
  display: inline-block;
  max-width: 7rem;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

@media (min-width: 1200px) {
  .header .brand {
    max-width: 10rem;
  }
}

.header .btn--back {
  height: 3rem;
  width: 3rem;
  border: 1px solid #fff;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.6s ease;
  transition-delay: 500ms;
}

@media (min-width: 992px) {
  .header .btn--back {
    height: 5rem;
    width: 5rem;
  }
}

.header .btn--back > img {
  max-width: 1rem;
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  .header .btn--back > img {
    max-width: 2rem;
  }
}

.footer {
  position: relative;
  display: none;
  z-index: 1;
}

.footer[data-current-chapter="0"] {
  z-index: 11;
  margin-top: -112%;
}

@media (min-width: 768px) {
  .footer[data-current-chapter="0"] {
    margin-top: -42%;
  }
}

.footer[data-current-chapter="1"] {
  margin-top: -15rem;
}

@media (min-width: 768px) {
  .footer[data-current-chapter="1"] {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .footer[data-current-chapter="1"] {
    margin-top: -15rem;
  }
}

@media (min-width: 1500px) {
  .footer[data-current-chapter="1"] {
    margin-top: -25rem;
  }
}

.footer[data-current-chapter="2"] {
  margin-top: -11rem;
}

@media (min-width: 768px) {
  .footer[data-current-chapter="2"] {
    margin-top: 2rem;
  }
}

@media (min-width: 1200px) {
  .footer[data-current-chapter="2"] {
    margin-top: -10rem;
  }
}

@media (min-width: 1500px) {
  .footer[data-current-chapter="2"] {
    margin-top: -20rem;
  }
}

.footer[data-current-chapter="3"] {
  margin-top: -11rem;
}

@media (min-width: 768px) {
  .footer[data-current-chapter="3"] {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .footer[data-current-chapter="3"] {
    margin-top: -8rem;
  }
}

@media (min-width: 1500px) {
  .footer[data-current-chapter="3"] {
    margin-top: -20rem;
  }
}

.footer[data-current-chapter="4"] {
  margin-top: -11rem;
}

@media (min-width: 768px) {
  .footer[data-current-chapter="4"] {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .footer[data-current-chapter="4"] {
    margin-top: -10rem;
  }
}

@media (min-width: 1500px) {
  .footer[data-current-chapter="4"] {
    margin-top: -20rem;
  }
}

.footer[data-current-chapter="5"] {
  margin-top: -12rem;
}

@media (min-width: 768px) {
  .footer[data-current-chapter="5"] {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .footer[data-current-chapter="5"] {
    margin-top: -10rem;
  }
}

@media (min-width: 1500px) {
  .footer[data-current-chapter="5"] {
    margin-top: -15rem;
  }
}

.footer[data-current-chapter="6"] {
  margin-top: -5rem;
}

@media (min-width: 768px) {
  .footer[data-current-chapter="6"] {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .footer[data-current-chapter="6"] {
    margin-top: -10rem;
  }
}

@media (min-width: 1500px) {
  .footer[data-current-chapter="6"] {
    margin-top: -15rem;
  }
}

@media (max-width: 575.98px) {
  .footer[data-current-chapter="6"] .btn--next-chapter .chapter-year {
    font-size: 6rem !important;
    line-height: 7rem !important;
  }
  .footer[data-current-chapter="6"] .btn--next-chapter::after {
    right: -4.5rem;
  }
}

.footer[data-current-chapter="7"] {
  margin-top: -114%;
  z-index: unset;
}

@media (min-width: 768px) {
  .footer[data-current-chapter="7"] {
    margin-top: -46%;
  }
}

@media (max-width: 575.98px) {
  .footer[data-current-chapter="7"] .btn--next-chapter .chapter-year {
    font-size: 6rem;
  }
  .footer[data-current-chapter="7"] .btn--next-chapter::after {
    right: -4.5rem;
  }
}

.footer[data-current-chapter="8"] {
  margin-top: -50%;
}

@media (min-width: 768px) {
  .footer[data-current-chapter="8"] {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .footer[data-current-chapter="8"] {
    margin-top: -15%;
  }
}

.footer[data-current-chapter="8"] .footer__top .btn--next-chapter h1.chapter-year {
  font-size: 4rem;
  line-height: 5rem;
}

@media (min-width: 375px) {
  .footer[data-current-chapter="8"] .footer__top .btn--next-chapter h1.chapter-year {
    font-size: 6rem;
    line-height: 7rem;
  }
}

@media (min-width: 768px) {
  .footer[data-current-chapter="8"] .footer__top .btn--next-chapter h1.chapter-year {
    font-size: 12rem;
    line-height: 10rem;
  }
}

.footer .footer__bg img {
  width: 100%;
  height: auto;
}

.footer .footer__bg--chapter-0,
.footer .footer__bg--chapter-7 {
  display: none;
}

.footer .footer__top {
  position: absolute;
  left: 0;
  bottom: 50%;
  width: 100%;
}

@media (min-width: 768px) {
  .footer .footer__top {
    bottom: 55%;
  }
}

.footer .footer__top .btn--next-chapter {
  text-decoration: none;
  position: relative;
  display: inline-block;
  right: 2rem;
}

@media (min-width: 375px) {
  .footer .footer__top .btn--next-chapter {
    right: 1rem;
  }
}

@media (min-width: 768px) {
  .footer .footer__top .btn--next-chapter {
    float: right;
    position: relative;
    left: -4rem;
  }
}

.footer .footer__top .btn--next-chapter::after {
  content: url("../images/global/icons/right_arrow_long_blue.svg");
  display: block;
  position: absolute;
  top: 50%;
  right: -1rem;
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .footer .footer__top .btn--next-chapter::after {
    right: -4rem;
  }
}

.footer .footer__top .btn--next-chapter span {
  display: block;
}

.footer .footer__top .btn--next-chapter span.chapter-number,
.footer .footer__top .btn--next-chapter span.chapter-title {
  font-family: "DharmaGothicCW01";
  font-size: 2.1rem;
  letter-spacing: 0.6px;
  line-height: 2.1rem;
  text-transform: uppercase;
  color: #808285;
}

@media (min-width: 768px) {
  .footer .footer__top .btn--next-chapter span.chapter-number,
  .footer .footer__top .btn--next-chapter span.chapter-title {
    font-size: 3rem;
    line-height: 3rem;
  }
}

.footer .footer__top .btn--next-chapter span.chapter-number > span {
  display: inline-block;
}

.footer .footer__top .btn--next-chapter h1.chapter-year {
  margin-bottom: 0;
  font-size: 6rem;
  line-height: 5rem;
}

@media (min-width: 375px) {
  .footer .footer__top .btn--next-chapter h1.chapter-year {
    font-size: 8rem;
    line-height: 7rem;
  }
}

@media (min-width: 768px) {
  .footer .footer__top .btn--next-chapter h1.chapter-year {
    font-size: 14rem;
    line-height: 12rem;
  }
}

.footer .footer__top .btn--next-chapter[data-next="3"]::after, .footer .footer__top .btn--next-chapter[data-next="4"]::after, .footer .footer__top .btn--next-chapter[data-next="5"]::after, .footer .footer__top .btn--next-chapter[data-next="6"]::after, .footer .footer__top .btn--next-chapter[data-next="7"]::after, .footer .footer__top .btn--next-chapter[data-next="8"]::after {
  right: -4rem;
}

.footer .footer__top .btn--next-chapter[data-next="8"] {
  z-index: 99;
}

.footer .footer__top .btn--back-to-top {
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.footer .footer__top .btn--back-to-top span {
  display: inline-block;
  vertical-align: middle;
}

.footer .footer__top .btn--back-to-top span.back-to-top__img {
  margin-right: 0.5rem;
}

@media (min-width: 768px) {
  .footer .footer__top .btn--back-to-top span.back-to-top__img {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
  }
}

.footer .footer__top .btn--back-to-top span.back-to-top__text {
  font-family: "DharmaGothicCW01";
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #808285;
  font-size: 2.1rem;
  line-height: 3.5rem;
  position: relative;
  top: 2px;
}

@media (min-width: 768px) {
  .footer .footer__top .btn--back-to-top span.back-to-top__text {
    font-size: 3rem;
    line-height: 3rem;
    top: 4px;
  }
}

@media (min-width: 992px) {
  .footer .footer__top .btn--back-to-top span.back-to-top__text {
    top: 11px;
  }
}

@media (min-width: 1200px) {
  .footer .footer__top .btn--back-to-top span.back-to-top__text {
    top: 11px;
  }
}

.footer .footer__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding-bottom: 0.5rem;
  color: #fff;
}

@media (min-width: 414px) {
  .footer .footer__content {
    padding-bottom: 2rem;
  }
}

@media (min-width: 1500px) {
  .footer .footer__content {
    padding-bottom: 6%;
  }
}

.footer .footer__content .footer__middle .footer__logo {
  display: inline-block;
  width: 100%;
  height: auto;
}

.footer .footer__content .footer__middle .footer__logo.footer__logo--1 {
  max-width: 9rem;
}

@media (min-width: 1200px) {
  .footer .footer__content .footer__middle .footer__logo.footer__logo--1 {
    max-width: 15rem;
  }
}

.footer .footer__content .footer__middle .footer__logo.footer__logo--2 {
  max-width: 6.7rem;
}

@media (min-width: 1200px) {
  .footer .footer__content .footer__middle .footer__logo.footer__logo--2 {
    max-width: 11.2rem;
  }
}

.footer .footer__content .footer__middle .btn {
  margin-top: 2rem;
  background-color: transparent !important;
}

.footer .footer__content .footer__middle .btn:hover {
  border-color: #008AAB;
  background-color: #008AAB !important;
  color: #fff !important;
}

@media (min-width: 375px) {
  .footer .footer__content .footer__middle .btn {
    margin-top: 3rem;
  }
}

@media (min-width: 768px) {
  .footer .footer__content .footer__middle .btn {
    margin: 0 auto;
    max-width: 38rem;
  }
}

@media (min-width: 992px) {
  .footer .footer__content .footer__middle .btn {
    max-width: 60rem;
  }
}

@media (min-width: 1200px) {
  .footer .footer__content .footer__middle .btn {
    padding: 1.8rem 2rem 1.5rem 2rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
    border-radius: 0;
  }
  .footer .footer__content .footer__middle .btn > .fa {
    font-size: 3.75rem;
    right: 2rem;
  }
}

@media (min-width: 1500px) {
  .footer .footer__content .footer__middle .btn {
    max-width: 50rem;
  }
}

.footer .footer__content .footer__bottom {
  border-top: 1px solid #fff;
  padding-top: 1rem;
  margin-top: 2rem;
}

@media (min-width: 375px) {
  .footer .footer__content .footer__bottom {
    margin-top: 3rem;
  }
}

@media (min-width: 414px) {
  .footer .footer__content .footer__bottom {
    padding-top: 1.2rem;
    margin-top: 4rem;
  }
}

@media (min-width: 768px) {
  .footer .footer__content .footer__bottom {
    margin-top: 1.5rem;
  }
}

@media (min-width: 992px) {
  .footer .footer__content .footer__bottom {
    margin-top: 3.5rem;
  }
}

.footer .footer__content .footer__bottom a,
.footer .footer__content .footer__bottom p {
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

.footer .footer__content .footer__bottom .small {
  font-size: 0.8rem;
  line-height: 1.3rem;
}

@media (min-width: 576px) {
  .footer .footer__content .footer__bottom .small {
    font-size: 1.1rem;
  }
}

.container--fw {
  max-width: 100% !important;
}

@media (min-width: 1200px) {
  .container--narrow {
    max-width: 960px;
  }
}

@media (max-width: 575.98px) {
  .container--flush-sm-down {
    max-width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .container--flush-sm-down .wpb_column {
    padding-left: 0;
    padding-right: 0;
  }
}

.pt {
  padding-top: 4rem;
}

@media (min-width: 992px) {
  .pt {
    padding-top: 8.5rem;
  }
}

.pb {
  padding-bottom: 4rem;
}

@media (min-width: 992px) {
  .pb {
    padding-bottom: 8.5rem;
  }
}

.pt-less {
  padding-top: 2rem;
}

@media (min-width: 992px) {
  .pt-less {
    padding-top: 2.5rem;
  }
}

.pb-less {
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .pb-less {
    padding-bottom: 2.5rem;
  }
}

.mt {
  margin-top: 4rem;
}

@media (min-width: 992px) {
  .mt {
    margin-top: 8.5rem;
  }
}

.mb {
  margin-bottom: 4rem;
}

@media (min-width: 992px) {
  .mb {
    margin-bottom: 8.5rem;
  }
}

.mt-less {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .mt-less {
    margin-top: 2.5rem;
  }
}

.mb-less {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .mb-less {
    margin-bottom: 2.5rem;
  }
}

.bg--primary {
  background-color: #008AAB;
}

.bg--secondary {
  background-color: #000;
}

.bg--success {
  background-color: #28a745;
}

.bg--info {
  background-color: #17a2b8;
}

.bg--warning {
  background-color: #ffc107;
}

.bg--danger {
  background-color: #dc3545;
}

.bg--light {
  background-color: #E5E5E5;
}

.bg--dark {
  background-color: #E5E5E5;
}

.bg--black {
  background-color: #000;
}

.bg--white {
  background-color: #fff;
}

.bg--gray-100 {
  background-color: #E5E5E5;
}

.bg--gray-200 {
  background-color: #A7A9AC;
}

.bg--gray-300 {
  background-color: #808285;
}

.bg--gray-400 {
  background-color: #E5E5E5;
}

.bg--gray-500 {
  background-color: #E5E5E5;
}

.bg--gray-600 {
  background-color: #E5E5E5;
}

.bg--gray-700 {
  background-color: #E5E5E5;
}

.bg--gray-800 {
  background-color: #E5E5E5;
}

.bg--gray-900 {
  background-color: #E5E5E5;
}

.rel {
  position: relative;
}

.o-flow--hidden {
  overflow: hidden;
}

.site {
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s ease;
  background-color: #fff;
}

.site.site--loading {
  opacity: 0;
}

::-webkit-scrollbar {
  display: none;
}

h1, h2,
.h1, .h2 {
  margin-bottom: 1.7rem;
  font-family: "DharmaGothicCW01";
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h1:last-child, h2:last-child,
.h1:last-child, .h2:last-child {
  margin-bottom: 0;
}

h1, .h1 {
  font-size: 4.5rem;
  line-height: 4.5rem;
  color: #008AAB;
}

@media (min-width: 992px) {
  h1, .h1 {
    font-size: 7rem;
    line-height: 6rem;
  }
}

h2, .h2 {
  font-size: 4rem;
  color: #000;
}

@media (min-width: 992px) {
  h2, .h2 {
    font-size: 8rem;
  }
}

.text-stroke--primary {
  text-shadow: -1.5px -1.5px 0 #008AAB, 1.5px -1.5px 0 #008AAB, -1.5px 1.5px 0 #008AAB, 1.5px 1.5px 0 #008AAB;
}

.text-stroke--secondary {
  text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000;
}

.text-stroke--success {
  text-shadow: -1.5px -1.5px 0 #28a745, 1.5px -1.5px 0 #28a745, -1.5px 1.5px 0 #28a745, 1.5px 1.5px 0 #28a745;
}

.text-stroke--info {
  text-shadow: -1.5px -1.5px 0 #17a2b8, 1.5px -1.5px 0 #17a2b8, -1.5px 1.5px 0 #17a2b8, 1.5px 1.5px 0 #17a2b8;
}

.text-stroke--warning {
  text-shadow: -1.5px -1.5px 0 #ffc107, 1.5px -1.5px 0 #ffc107, -1.5px 1.5px 0 #ffc107, 1.5px 1.5px 0 #ffc107;
}

.text-stroke--danger {
  text-shadow: -1.5px -1.5px 0 #dc3545, 1.5px -1.5px 0 #dc3545, -1.5px 1.5px 0 #dc3545, 1.5px 1.5px 0 #dc3545;
}

.text-stroke--light {
  text-shadow: -1.5px -1.5px 0 #E5E5E5, 1.5px -1.5px 0 #E5E5E5, -1.5px 1.5px 0 #E5E5E5, 1.5px 1.5px 0 #E5E5E5;
}

.text-stroke--dark {
  text-shadow: -1.5px -1.5px 0 #E5E5E5, 1.5px -1.5px 0 #E5E5E5, -1.5px 1.5px 0 #E5E5E5, 1.5px 1.5px 0 #E5E5E5;
}

.text-stroke--black {
  text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000;
}

.text-stroke--white {
  text-shadow: -1.5px -1.5px 0 #fff, 1.5px -1.5px 0 #fff, -1.5px 1.5px 0 #fff, 1.5px 1.5px 0 #fff;
}

.text-stroke--gray-100 {
  text-shadow: -1.5px -1.5px 0 #E5E5E5, 1.5px -1.5px 0 #E5E5E5, -1.5px 1.5px 0 #E5E5E5, 1.5px 1.5px 0 #E5E5E5;
}

.text-stroke--gray-200 {
  text-shadow: -1.5px -1.5px 0 #A7A9AC, 1.5px -1.5px 0 #A7A9AC, -1.5px 1.5px 0 #A7A9AC, 1.5px 1.5px 0 #A7A9AC;
}

.text-stroke--gray-300 {
  text-shadow: -1.5px -1.5px 0 #808285, 1.5px -1.5px 0 #808285, -1.5px 1.5px 0 #808285, 1.5px 1.5px 0 #808285;
}

.text-stroke--gray-400 {
  text-shadow: -1.5px -1.5px 0 #E5E5E5, 1.5px -1.5px 0 #E5E5E5, -1.5px 1.5px 0 #E5E5E5, 1.5px 1.5px 0 #E5E5E5;
}

.text-stroke--gray-500 {
  text-shadow: -1.5px -1.5px 0 #E5E5E5, 1.5px -1.5px 0 #E5E5E5, -1.5px 1.5px 0 #E5E5E5, 1.5px 1.5px 0 #E5E5E5;
}

.text-stroke--gray-600 {
  text-shadow: -1.5px -1.5px 0 #E5E5E5, 1.5px -1.5px 0 #E5E5E5, -1.5px 1.5px 0 #E5E5E5, 1.5px 1.5px 0 #E5E5E5;
}

.text-stroke--gray-700 {
  text-shadow: -1.5px -1.5px 0 #E5E5E5, 1.5px -1.5px 0 #E5E5E5, -1.5px 1.5px 0 #E5E5E5, 1.5px 1.5px 0 #E5E5E5;
}

.text-stroke--gray-800 {
  text-shadow: -1.5px -1.5px 0 #E5E5E5, 1.5px -1.5px 0 #E5E5E5, -1.5px 1.5px 0 #E5E5E5, 1.5px 1.5px 0 #E5E5E5;
}

.text-stroke--gray-900 {
  text-shadow: -1.5px -1.5px 0 #E5E5E5, 1.5px -1.5px 0 #E5E5E5, -1.5px 1.5px 0 #E5E5E5, 1.5px 1.5px 0 #E5E5E5;
}

.color--primary {
  color: #008AAB !important;
}

.color--secondary {
  color: #000 !important;
}

.color--success {
  color: #28a745 !important;
}

.color--info {
  color: #17a2b8 !important;
}

.color--warning {
  color: #ffc107 !important;
}

.color--danger {
  color: #dc3545 !important;
}

.color--light {
  color: #E5E5E5 !important;
}

.color--dark {
  color: #E5E5E5 !important;
}

.color--black {
  color: #000 !important;
}

.color--white {
  color: #fff !important;
}

.color--gray-100 {
  color: #E5E5E5 !important;
}

.color--gray-200 {
  color: #A7A9AC !important;
}

.color--gray-300 {
  color: #808285 !important;
}

.color--gray-400 {
  color: #E5E5E5 !important;
}

.color--gray-500 {
  color: #E5E5E5 !important;
}

.color--gray-600 {
  color: #E5E5E5 !important;
}

.color--gray-700 {
  color: #E5E5E5 !important;
}

.color--gray-800 {
  color: #E5E5E5 !important;
}

.color--gray-900 {
  color: #E5E5E5 !important;
}

.text--jumbo {
  font-size: 20rem;
  line-height: 20rem;
  font-family: "DharmaGothicCW01";
  letter-spacing: 0;
}

@media (min-width: 992px) {
  .text--jumbo {
    font-size: 70rem !important;
    line-height: 70rem !important;
  }
}

@media (min-width: 1500px) {
  .text--jumbo {
    font-size: 97rem !important;
    line-height: 97rem !important;
  }
}

.text--quote {
  font-family: "Brandon Grotesque", sans-serif;
  font-weight: 300;
  color: #008AAB;
  font-size: 3rem;
  line-height: 5rem;
}

@media (min-width: 992px) {
  .text--quote {
    font-size: 5rem;
    line-height: 7rem;
  }
}

.text--quote-name {
  font-family: "Brandon Grotesque", sans-serif;
  font-weight: 800;
  color: #008AAB;
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (min-width: 992px) {
  .text--quote-name {
    font-size: 3rem;
    line-height: 5rem;
  }
}

.text--quote-date {
  font-family: "Brandon Grotesque", sans-serif;
  font-weight: 300;
  color: #008AAB;
  font-size: 1rem;
  line-height: 2rem;
  opacity: 0.5;
}

@media (min-width: 992px) {
  .text--quote-date {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.text--stat-number {
  font-family: "DharmaGothicCW01";
  font-weight: 800;
  color: #008AAB;
  text-transform: uppercase;
  font-size: 6.6rem;
  line-height: 6.2rem;
}

@media (min-width: 992px) {
  .text--stat-number {
    font-size: 11rem;
    line-height: 10.3rem;
  }
}

.text--stat-word {
  font-family: "DharmaGothicCW01";
  font-weight: 800;
  color: #808285;
  text-transform: uppercase;
  font-size: 3.3rem;
  line-height: 3.1rem;
  letter-spacing: 0.47px;
}

@media (min-width: 992px) {
  .text--stat-word {
    font-size: 5.5rem;
    line-height: 5.2rem;
    letter-spacing: 0.79px;
  }
}

.text--callout {
  font-family: "DharmaGothicCW01";
  font-weight: 800;
  color: #008AAB;
  text-transform: uppercase;
  font-size: 6rem;
  line-height: 5rem;
  letter-spacing: 0.9px;
}

@media (min-width: 992px) {
  .text--callout {
    font-size: 10rem;
    line-height: 8rem;
    letter-spacing: 1.5px;
    max-width: 25rem;
  }
}

.text--callout.text--callout-fw {
  max-width: 100%;
}

.text--callout span {
  display: block;
  font-size: 15rem;
  color: #E5E5E5;
  height: 0;
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .text--callout span {
    font-size: 26rem;
  }
}

.text--callout span.name,
.text--callout span.name-2 {
  height: 100%;
  color: #808285;
  display: block;
  font-family: "Brandon Grotesque", sans-serif;
  letter-spacing: 0;
}

.text--callout span.name {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
  text-transform: none;
}

@media (min-width: 1200px) {
  .text--callout span.name {
    margin-top: 4rem;
    font-size: 2rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.text--callout span.name-2 {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .text--callout span.name-2 {
    font-size: 1.5rem;
  }
}

.text--callout.text--callout-quote {
  padding-top: 5rem;
}

.text--caption {
  font-family: "Brandon Grotesque", sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .text--caption {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
}

.text--slide-count {
  padding-left: 1.5rem;
  font-weight: bold;
  color: #A7A9AC;
  font-size: 1.3rem;
  line-height: 1.5rem;
}

.text--slide-count strong {
  color: #000;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 1.1rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 2.75rem;
}

.blockquote-footer {
  display: block;
  font-size: 1.1rem;
  color: #E5E5E5;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.btn {
  display: inline-block;
  font-family: "Brandon Grotesque", sans-serif;
  font-weight: 500;
  letter-spacing: 6px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  border-radius: 0;
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  min-width: 15rem;
}

@media (min-width: 375px) {
  .btn {
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0;
  }
}

.btn:hover {
  color: #000;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
  cursor: default;
}

.btn > .fa {
  font-size: 1.25rem;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 1rem;
}

@media (min-width: 375px) {
  .btn > .fa {
    font-size: 2.25rem;
  }
}

@media (min-width: 576px) {
  .btn > .fa {
    right: 2rem;
  }
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn--primary {
  background-color: transparent;
  color: #008AAB;
  border-color: #008AAB;
  background-color: rgba(0, 138, 171, 0.5);
}

.btn--primary:hover, .btn--primary:focus {
  background-color: #008AAB;
  color: #000;
  background-color: #008AAB;
}

.btn--primary.disabled:hover, .btn--primary.disabled:focus, .btn--primary:disabled:hover, .btn--primary:disabled:focus {
  background-color: #008AAB;
  color: #000;
  border-color: #008AAB;
}

.btn--secondary {
  background-color: transparent;
  color: #000;
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
}

.btn--secondary:hover, .btn--secondary:focus {
  background-color: #000;
  color: #000;
  background-color: #000;
}

.btn--secondary.disabled:hover, .btn--secondary.disabled:focus, .btn--secondary:disabled:hover, .btn--secondary:disabled:focus {
  background-color: #000;
  color: #000;
  border-color: #000;
}

.btn--success {
  background-color: transparent;
  color: #28a745;
  border-color: #28a745;
  background-color: rgba(40, 167, 69, 0.5);
}

.btn--success:hover, .btn--success:focus {
  background-color: #28a745;
  color: #000;
  background-color: #28a745;
}

.btn--success.disabled:hover, .btn--success.disabled:focus, .btn--success:disabled:hover, .btn--success:disabled:focus {
  background-color: #28a745;
  color: #000;
  border-color: #28a745;
}

.btn--info {
  background-color: transparent;
  color: #17a2b8;
  border-color: #17a2b8;
  background-color: rgba(23, 162, 184, 0.5);
}

.btn--info:hover, .btn--info:focus {
  background-color: #17a2b8;
  color: #000;
  background-color: #17a2b8;
}

.btn--info.disabled:hover, .btn--info.disabled:focus, .btn--info:disabled:hover, .btn--info:disabled:focus {
  background-color: #17a2b8;
  color: #000;
  border-color: #17a2b8;
}

.btn--warning {
  background-color: transparent;
  color: #ffc107;
  border-color: #ffc107;
  background-color: rgba(255, 193, 7, 0.5);
}

.btn--warning:hover, .btn--warning:focus {
  background-color: #ffc107;
  color: #000;
  background-color: #ffc107;
}

.btn--warning.disabled:hover, .btn--warning.disabled:focus, .btn--warning:disabled:hover, .btn--warning:disabled:focus {
  background-color: #ffc107;
  color: #000;
  border-color: #ffc107;
}

.btn--danger {
  background-color: transparent;
  color: #dc3545;
  border-color: #dc3545;
  background-color: rgba(220, 53, 69, 0.5);
}

.btn--danger:hover, .btn--danger:focus {
  background-color: #dc3545;
  color: #000;
  background-color: #dc3545;
}

.btn--danger.disabled:hover, .btn--danger.disabled:focus, .btn--danger:disabled:hover, .btn--danger:disabled:focus {
  background-color: #dc3545;
  color: #000;
  border-color: #dc3545;
}

.btn--light {
  background-color: transparent;
  color: #E5E5E5;
  border-color: #E5E5E5;
  background-color: rgba(229, 229, 229, 0.5);
}

.btn--light:hover, .btn--light:focus {
  background-color: #E5E5E5;
  color: #000;
  background-color: #E5E5E5;
}

.btn--light.disabled:hover, .btn--light.disabled:focus, .btn--light:disabled:hover, .btn--light:disabled:focus {
  background-color: #E5E5E5;
  color: #000;
  border-color: #E5E5E5;
}

.btn--dark {
  background-color: transparent;
  color: #E5E5E5;
  border-color: #E5E5E5;
  background-color: rgba(229, 229, 229, 0.5);
}

.btn--dark:hover, .btn--dark:focus {
  background-color: #E5E5E5;
  color: #000;
  background-color: #E5E5E5;
}

.btn--dark.disabled:hover, .btn--dark.disabled:focus, .btn--dark:disabled:hover, .btn--dark:disabled:focus {
  background-color: #E5E5E5;
  color: #000;
  border-color: #E5E5E5;
}

.btn--black {
  background-color: transparent;
  color: #000;
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
}

.btn--black:hover, .btn--black:focus {
  background-color: #000;
  color: #000;
  background-color: #000;
}

.btn--black.disabled:hover, .btn--black.disabled:focus, .btn--black:disabled:hover, .btn--black:disabled:focus {
  background-color: #000;
  color: #000;
  border-color: #000;
}

.btn--white {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
}

.btn--white:hover, .btn--white:focus {
  background-color: #fff;
  color: #000;
  background-color: #fff;
}

.btn--white.disabled:hover, .btn--white.disabled:focus, .btn--white:disabled:hover, .btn--white:disabled:focus {
  background-color: #fff;
  color: #000;
  border-color: #fff;
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--block + .btn--block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
  display: flex;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item .text--caption,
.owl-carousel .owl-item .text--slide-count {
  padding-left: 1.5rem;
  padding-bottom: 1rem;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

.owl-carousel--uneven .owl-stage {
  padding-left: 0 !important;
}

.owl-carousel--uneven .owl-item:nth-child(even) .item {
  padding-top: 4rem;
}

@media (min-width: 1500px) {
  .owl-carousel--uneven .owl-item:nth-child(even) .item {
    padding-top: 9rem;
  }
}

@media (min-width: 1200px) {
  .owl-carousel--split-primary-container {
    position: relative;
    top: -25rem;
  }
}

.owl-carousel--badges {
  display: block !important;
}

.owl-carousel--split.owl-rtl .item__desc {
  direction: ltr !important;
}

.owl-carousel--split-off-screen {
  max-width: 54rem;
  position: absolute;
  bottom: 0;
}

.owl-carousel--split-off-screen.owl-carousel--split-left {
  left: -30rem;
}

.owl-carousel--split-off-screen.owl-carousel--split-right {
  right: -30rem;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15%, 0);
    transform: translate3d(0, 15%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15%, 0);
    transform: translate3d(0, 15%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

@keyframes floating {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

.floating {
  transform: translatey(0px);
  animation: floating 3s ease-in-out infinite;
}

.text-block:not(.floating),
.fade-in-up {
  opacity: 0;
}

.type-in {
  opacity: 0;
  transition: opacity 1.5s ease;
}

@media (min-width: 1200px) {
  .telstra-header {
    height: 71px;
  }
}

#bphf-top {
  width: 100% !important;
  position: relative;
}

#tmx-header {
  margin: 0 auto;
  white-space: nowrap;
  overflow: visible;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
  font-size: 15px !important;
}

#tmx-skip {
  position: absolute;
}

#tmx-skip a {
  position: absolute;
  left: 0;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  width: auto;
  height: auto;
}

#tmx-skip a:active, #tmx-skip a:focus {
  position: static;
}

#tmx-content {
  position: absolute;
  left: 0;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#tmx-global {
  display: none;
}

#tmx-network {
  height: 45px;
  width: 100%;
  background: #00b9fc;
  background: -moz-linear-gradient(top, #00b9fc 1%, #00a5ff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #00b9fc), color-stop(100%, #00a5ff));
  background: -webkit-linear-gradient(top, #00b9fc 1%, #00a5ff 100%);
  background: -o-linear-gradient(top, #00b9fc 1%, #00a5ff 100%);
  background: -ms-linear-gradient(top, #00b9fc 1%, #00a5ff 100%);
  background: linear-gradient(to bottom, #00b9fc 1%, #00a5ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00b9fc',endColorstr='#00a5ff',GradientType=0);
}

#tmx-network .tmx-wrap {
  position: relative;
}

#tmx-network .tmx-tmedia {
  line-height: 39px;
  width: 83px;
  float: left;
  margin-left: 6px;
}

#tmx-network .tmx-tmedia svg {
  height: 50px;
  width: 90px;
  display: block;
  box-sizing: content-box !important;
}

#bphf-top .tmediasvg {
  fill: #fff;
  color: #46008b;
}

#tmx-network .tmx-trigger {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
}

#tmx-network .tmx-trigger.tmx-active {
  background-color: #fff;
}

@media (max-width: 750px) {
  #tmx-network .tmx-trigger.tmx-show-xs {
    display: block;
  }
}

@media (min-width: 750px) {
  #tmx-network .tmx-trigger.tmx-show-lg {
    display: block;
  }
}

#tmx-network .tmx-icon {
  width: 40px;
  height: 45px;
  position: absolute;
  cursor: pointer;
  right: 0;
}

#tmx-network .tmx-icon span {
  width: 23px !important;
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff !important;
  border-radius: 8px;
  right: 7px;
  opacity: 1;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}

#tmx-network .tmx-icon.tmx-active span {
  background-color: #02adfa !important;
  right: 7px;
}

#tmx-network .tmx-icon span:nth-child(1) {
  top: 13px;
}

#tmx-network .tmx-icon span:nth-child(2) {
  top: 19px;
}

#tmx-network .tmx-icon span:nth-child(3) {
  top: 25px;
}

#tmx-network .tmx-icon.tmx-active span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 20px;
}

#tmx-network .tmx-icon.tmx-active span:nth-child(2) {
  opacity: 0;
}

#tmx-network .tmx-icon.tmx-active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 20px;
}

#tmx-narrow {
  height: 45px;
  margin: 0;
}

#tmx-narrow .tmx-menu {
  margin: 0;
  padding: 0;
  padding-right: 50px;
  text-align: right;
}

#tmx-narrow .tmx-menu .tmx-item {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
}

#tmx-narrow .tmx-menu a {
  color: #FFF;
  text-decoration: none;
  line-height: 39px;
}

#tmx-narrow .tmx-menu-mobile {
  list-style: none;
  margin: 0 42px;
  padding-top: 4px;
}

#tmx-narrow .tmx-menu-mobile .tmx-item {
  float: right;
  padding: 0 3px;
  color: #fff;
}

#tmx-narrow .tmx-menu-mobile .tmx-item.tmx-active {
  background-color: #fff;
  color: #00b9fc;
}

#tmx-narrow .tmx-menu-mobile .tmx-item a {
  border: 2px solid #fff;
  border-radius: 50%;
  display: block;
  padding: 5px;
  height: 22px;
  width: 22px;
  box-sizing: content-box !important;
  position: relative;
}

#tmx-narrow .tmx-menu-mobile svg {
  height: 20px;
  width: 22px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#tmx-narrow .tmx-menu-mobile .tmx-search {
  height: 24px;
  width: 25px;
  line-height: 28px;
}

#tmx-menu-aside .tmx-item {
  white-space: normal;
  font-weight: normal;
}

#tmx-menu-aside-mobile {
  margin: -9999px 0;
  padding: 16px;
  position: absolute;
  background: #fff;
  background: -moz-linear-gradient(top, #fff 52%, #ececec 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(52%, #fff), color-stop(100%, #ececec));
  background: -webkit-linear-gradient(top, #fff 52%, #ececec 100%);
  background: -o-linear-gradient(top, #fff 52%, #ececec 100%);
  background: -ms-linear-gradient(top, #fff 52%, #ececec 100%);
  background: linear-gradient(to bottom, #fff 52%, #ececec 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#ececec',GradientType=0);
  width: 100%;
  z-index: 10001;
}

#tmx-narrow .tmx-menu-aside.tmx-active, #tmx-menu-aside-mobile.tmx-active {
  margin: 0;
}

#tmx-narrow .tmx-menu-list, #tmx-menu-aside-mobile .tmx-menu-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#tmx-narrow .tmx-menu-list .tmx-item a, #tmx-menu-aside-mobile .tmx-menu-list .tmx-item a {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  width: 48%;
  float: left;
  border-bottom: 1px solid #d0d0d0;
  padding: 6px 0;
  white-space: normal;
}

#tmx-menu-aside-mobile img {
  display: none;
}

#tmx-wide {
  display: none;
}

#tmx-search-h {
  display: none;
  position: absolute;
  top: 45px;
  padding: 0;
  width: 100%;
  height: 110%;
  z-index: 1;
}

#tmx-search-h.tmx-active {
  display: block;
  background-color: #fff;
}

#tmx-search-form-h {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

#tmx-search-form-h .tmx-fieldset {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
  display: block;
  padding: 8px;
  margin: 0;
  border: 0 none;
  position: relative;
}

#tmx-search-form-h .tmx-default {
  position: absolute;
  top: 13px;
  font-size: 13px;
  color: #575756;
  background-color: #fff;
  z-index: 0;
  text-align: left;
}

#tmx-search-form-h .tmx-default .tmx-i-search {
  height: 21px;
  vertical-align: middle;
  width: 43px;
}

#tmx-search-form-h .tmx-default .tmx-i-google {
  height: 24px;
  vertical-align: middle;
  width: 83px;
}

#tmx-search-input-h {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
  display: block;
  width: 93%;
  padding-left: 2%;
  height: 30px;
  border: 1px solid #ccc;
  z-index: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
}

#tmx-search-input-h.tmx-active {
  -webkit-transition: background-color .4s,color .4s;
  transition: background-color .4s,color .4s;
  background-color: #FFF;
}

#tmx-network .tmx-search-ptab {
  display: none;
}

#tmx-search-form-h .tmx-legend, #tmx-search-form-f .tmx-legend {
  position: absolute;
  left: -9999px;
}

@font-face {
  font-family: "gravur";
  src: url("/bphf/res/fonts/gravur.eot");
  src: url("/bphf/res/fonts/gravur.eot#iefix") format("embedded-opentype"), url("/bphf/res/fonts/gravur.woff") format("woff"), url("/bphf/res/fonts/gravur.ttf") format("truetype"), url("/bphf/res/fonts/gravur.svg#gravur") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media (min-width: 750px) {
  .tmx-visible-xs {
    display: none !important;
  }
  #bphf-top {
    height: auto !important;
  }
  #tmx-header {
    height: 80px;
  }
  #tmx-global {
    display: block;
    height: 37px;
    width: 100%;
    overflow: hidden;
    background: #575757;
    background: -moz-linear-gradient(top, #575757 47%, #575757 47%, #3d3d3d 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(47%, #575757), color-stop(47%, #575757), color-stop(100%, #3d3d3d));
    background: -webkit-linear-gradient(top, #575757 47%, #575757 47%, #3d3d3d 100%);
    background: -o-linear-gradient(top, #575757 47%, #575757 47%, #3d3d3d 100%);
    background: -ms-linear-gradient(top, #575757 47%, #575757 47%, #3d3d3d 100%);
    background: linear-gradient(to bottom, #575757 47%, #575757 47%, #3d3d3d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#575757',endColorstr='#3d3d3d',GradientType=0);
  }
  #tmx-global .tmx-wrap {
    height: auto;
    width: 100%;
    max-width: 988px;
    margin: 0 auto;
  }
  #tmx-global .tmx-left {
    float: left;
    width: 78%;
  }
  #tmx-global .tmx-right {
    float: right;
    width: 22%;
  }
  #tmx-global .tmx-menu {
    margin: 0;
    padding: 0;
  }
  #tmx-global .tmx-menu .tmx-item {
    margin: 0;
    padding: 0 12px;
    border-left: 1px solid #3d3d3d;
    list-style-type: none;
    display: block;
    float: left;
    position: relative;
    font-weight: normal;
    font-size: 12px;
    -webkit-transition: background-color .2s;
    -moz-transition: background-color .2s;
    transition: background-color .2s;
  }
  #tmx-global .tmx-menu .tmx-item:last-child {
    border-right: 1px solid #3d3d3d;
  }
  #tmx-global .tmx-menu.tmx-right .tmx-item:first-child {
    border-right: 1px solid #3d3d3d;
  }
  #tmx-global .tmx-menu.tmx-right .tmx-item:last-child {
    border-right: 0;
  }
  #tmx-global .tmx-menu .tmx-item svg {
    height: 39px;
    width: 20px;
    vertical-align: middle;
    margin-top: -5px;
  }
  #tmx-global .tmx-menu .tmx-item:hover {
    background-color: #3b3b3b;
  }
  #tmx-global .tmx-menu.tmx-right .tmx-item {
    float: right;
  }
  #tmx-global .tmx-menu .tmx-item:after {
    content: " ";
    border-right: 1px solid #5e5e5e;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
  #tmx-global .tmx-menu a {
    color: #FFF;
    text-decoration: none;
    line-height: 39px;
    display: block;
  }
  #tmx-network {
    height: 34px;
    overflow: hidden;
  }
  #tmx-network .tmx-wrap {
    height: auto;
    width: 100%;
    max-width: 988px;
    margin: 0 auto;
  }
  #tmx-network .tmx-left {
    float: left;
  }
  #tmx-network .tmx-right {
    float: right;
  }
  #tmx-network .tmx-menu .tmx-item svg {
    height: 39px;
    width: 20px;
    vertical-align: middle;
    margin-top: -2px;
  }
  #tmx-network .tmx-search {
    display: block;
    top: 0;
    right: 95px;
    padding: 0;
    width: 160px;
    height: 39px;
    line-height: 25px;
    text-align: left;
  }
  #tmx-search-h.tmx-active {
    background-color: transparent;
  }
  #tmx-search-form-h .tmx-default {
    font-size: 11px;
    width: 200px;
    height: 24px;
    line-height: 24px;
    top: 7px;
  }
  #tmx-search-form-h .tmx-default .tmx-i-search {
    height: 16px;
    vertical-align: middle;
    width: 32px;
  }
  #tmx-search-form-h .tmx-default .tmx-i-google {
    height: 25px;
    vertical-align: middle;
    width: 54px;
  }
  #tmx-search-form-h .tmx-input {
    width: 190px;
    font-size: 12px;
    height: 20px;
    padding-left: 10px;
    border: 0;
  }
  #tmx-narrow, #tmx-menu-aside-mobile {
    display: none;
  }
  #tmx-wide {
    display: block;
    max-height: 39px;
    overflow: hidden;
  }
  #tmx-wide .tmx-menu {
    margin: 0;
    padding: 0;
    width: 95%;
    float: left;
  }
  #tmx-wide .tmx-menu .tmx-item {
    margin: 0;
    padding: 0 11px;
    list-style-type: none;
    display: block;
    float: left;
    font-weight: normal;
    border-right: 1px solid #52bdf2;
    -webkit-transition: background-color .2s;
    -moz-transition: background-color .2s;
    transition: background-color .2s;
  }
  #tmx-wide .tmx-menu .tmx-item:first-child {
    border-left: 1px solid #52bdf2;
  }
  #tmx-wide .tmx-menu .tmx-item:last-child {
    border-right: 0;
  }
  #tmx-wide .tmx-menu .tmx-item:hover {
    background-color: #3bcaff;
  }
  #tmx-wide .tmx-menu a {
    color: #FFF;
    text-decoration: none;
    line-height: 39px;
    display: block;
    font-size: 1.1em;
    font-family: gravur,sans-serif;
    text-transform: uppercase;
    font-weight: normal;
  }
  #tmx-wide .tmx-menu-aside {
    position: absolute;
    right: 0;
    top: 40px;
    width: 27%;
    color: #414141;
    opacity: 0;
    padding: 15px;
    background: #fff;
    background: -moz-linear-gradient(top, #fff 52%, #ececec 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(52%, #fff), color-stop(100%, #ececec));
    background: -webkit-linear-gradient(top, #fff 52%, #ececec 100%);
    background: -o-linear-gradient(top, #fff 52%, #ececec 100%);
    background: -ms-linear-gradient(top, #fff 52%, #ececec 100%);
    background: linear-gradient(to bottom, #fff 52%, #ececec 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#ececec',GradientType=0);
    -webkit-box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.17);
    box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.17);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: opacity .25s;
    -moz-transition: opacity .25s;
    transition: opacity .25s;
    margin: -9999px 0;
    z-index: 10000;
  }
  #tmx-wide .tmx-menu-aside .tmx-left {
    margin-top: 6px;
    width: 78%;
    padding: 0;
  }
  #tmx-wide .tmx-menu-aside .tmx-right {
    padding: 0;
  }
  #tmx-wide .tmx-menu-aside.tmx-active {
    margin: 0;
    opacity: 1;
  }
  #tmx-wide .tmx-menu-aside img {
    width: 100%;
    display: block;
  }
  #tmx-wide .tmx-menu-list {
    margin: 0;
    list-style-type: none;
    text-align: left;
    width: 100%;
  }
  #tmx-wide .tmx-menu-list.tmx-right .tmx-item {
    width: 100%;
    float: left;
  }
  #tmx-wide .tmx-menu-list.tmx-right .tmx-item a {
    display: block;
    border-bottom: 1px solid #dfdfdf;
    padding: 4px 0;
  }
  #tmx-wide .tmx-menu-list.tmx-right .tmx-item:last-child {
    border-bottom: 0;
  }
  #tmx-wide .tmx-menu-list.tmx-left .tmx-item {
    width: 18.8%;
    float: left;
    margin-right: 8px;
    text-align: center;
  }
  #tmx-wide .tmx-menu-list.tmx-left .tmx-item:last-child {
    margin-right: 0;
  }
  #tmx-wide .tmx-menu-list.tmx-left .tmx-item img {
    border: 1px solid #efefef;
  }
  #tmx-wide .tmx-menu-list .tmx-item a {
    color: #414141;
    text-decoration: none;
    font-size: 13px;
  }
  #tmx-wide .tmx-menu-list .tmx-item a:hover {
    color: #939393;
  }
  #tmx-network .tmx-tmedia {
    margin-left: 2px;
    margin-right: 14px;
  }
  #tmx-network .tmx-tmedia svg {
    height: 39px;
    width: 80px;
  }
  #tmx-network .tmx-icon {
    height: 40px;
  }
  #tmx-network .tmx-icon span:nth-child(1) {
    top: 11px;
  }
  #tmx-network .tmx-icon span:nth-child(2) {
    top: 17px;
  }
  #tmx-network .tmx-icon span:nth-child(3) {
    top: 23px;
  }
  #tmx-network .tmx-search-ptab {
    display: none;
  }
  #tmx-network .tmx-icon span {
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  #tmx-network .tmx-icon.tmx-active span:nth-child(1) {
    top: 11px;
  }
  #tmx-network .tmx-icon.tmx-active span:nth-child(3) {
    top: 27px;
  }
}

.sie {
  position: fixed;
  right: 10px;
  bottom: 0;
  animation: lup 3s;
  transform: translate(0, 320px);
  z-index: 99999999;
}

.siel {
  z-index: 99999999;
  height: 21px;
  width: 168px;
  position: absolute;
  -webkit-box-shadow: 2px 2px 11px 9px #06ffda;
  -moz-box-shadow: 2px 2px 11px 9px #06ffda;
  box-shadow: 2px 2px 11px 9px #06ffda;
  background-color: #06ffd9;
  border-radius: 10px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.siel.l1 {
  transform: rotate(45deg) translate(-9999px, -9999px);
  animation: lone .5s .7s 2;
}

.siel.l2 {
  transform: rotate(45deg) translate(-9999px, -9999px);
  animation: ltwo .5s .7s 2;
}

@keyframes lup {
  0 {
    transform: translate(0, 320px);
  }
  20% {
    transform: translate(0, 0);
  }
  26% {
    -webkit-filter: brightness(1);
  }
  27% {
    -webkit-filter: brightness(1.4);
  }
  28% {
    -webkit-filter: brightness(1);
  }
  43% {
    -webkit-filter: brightness(1);
  }
  44% {
    -webkit-filter: brightness(1.4);
  }
  45% {
    -webkit-filter: brightness(1);
  }
  70% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 320px);
  }
}

@keyframes lone {
  14% {
    transform: rotate(45deg) translate(0, 59px);
  }
  100% {
    transform: rotate(45deg) translate(-1100px, 50px);
  }
}

@keyframes ltwo {
  14% {
    display: block;
    transform: rotate(45deg) translate(70px, -19px);
  }
  100% {
    display: block;
    transform: rotate(45deg) translate(-1100px, -50px);
  }
}

#tmx-footer {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 16px;
  padding: 14px;
  box-sizing: border-box;
  background-color: #FFF;
  width: 100%;
  margin: 0 auto;
  border-top: 4px solid #DDD;
  clear: both;
}

#tmx-footer .tmx-menu {
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  padding: 0 10px;
}

#tmx-footer .tmx-menu .tmx-item {
  margin: 0;
  list-style-type: none;
  display: block;
  font-weight: normal;
}

#tmx-footer .tmx-menu.tmx-left a {
  padding: 7px 0;
}

#tmx-footer .tmx-tmedia {
  padding: 14px 0;
}

#tmx-footer svg {
  width: 81px;
  height: 23px;
  vertical-align: middle;
}

#tmx-footer .tmediasvg {
  fill: #004ba0;
  color: #00afed;
}

#tmx-footer .tmx-menu.tmx-left a {
  display: block;
  text-decoration: none;
  color: #4b4b4b;
  border-bottom: 1px solid #dcdcdc;
}

#tmx-promo {
  height: 292px;
  width: 100%;
}

#tmx-search-form-f {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

#tmx-search-form-f .tmx-fieldset {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
  display: block;
  padding: 8px;
  margin: 0;
  border: 0 none;
  position: relative;
}

#tmx-search-form-f .tmx-default {
  display: block;
  position: absolute;
  top: 13px;
  left: 10px;
  font-size: 13px;
  color: #575756;
  background-color: #FFF;
  z-index: 0;
  text-align: left;
}

#tmx-search-form-f .tmx-default .tmx-i-search {
  height: 21px;
  vertical-align: middle;
  width: 43px;
}

#tmx-search-form-f .tmx-default .tmx-i-google {
  height: 28px;
  vertical-align: middle;
  width: 70px;
  margin-left: 7px;
}

#tmx-search-input-f {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
  display: inline-block;
  width: 96%;
  height: 35px;
  border: 1px solid #ccc;
  padding: 0 2%;
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  z-index: 1;
}

#tmx-search-input-f.tmx-active {
  background-color: #FFF;
}

#tmx-search-f .tmx-submit {
  display: none;
}

@media (min-width: 750px) and (max-width: 960px) {
  #tmx-global .tmx-menu .tmx-item {
    padding: 0 7px;
  }
  #tmx-wide .tmx-menu a {
    font-size: 1.06em;
  }
  #tmx-wide .tmx-menu .tmx-item {
    padding: 0 9px;
  }
  #tmx-network .tmx-tmedia {
    margin-left: 4px;
  }
  #tmx-network #tmx-search-h {
    display: none;
    opacity: 0;
    transition: opacity .3s;
  }
  #tmx-network #tmx-search-h.tmx-active {
    top: 39px;
    width: 50%;
    right: 0;
    background-color: #fff;
    display: block;
    opacity: 1;
    z-index: 9999;
    height: 46px;
  }
  #tmx-search-form-h .tmx-input {
    width: 91%;
    border: 1px solid #ccc;
    height: 25px;
  }
  #tmx-search-form-h .tmx-default .tmx-i-google {
    height: 31px;
  }
  #tmx-network .tmx-search-ptab {
    position: absolute;
    right: 40px;
    top: 0;
    cursor: pointer;
    width: 40px;
    text-align: center;
    display: block;
  }
  #tmx-network .tmx-search.tmx-active ~ .tmx-search-ptab {
    background-color: #fff;
  }
  #tmx-network .tmx-search-ptab svg {
    width: 20px;
    height: 36px;
    color: #fff;
  }
  #tmx-network .tmx-search.tmx-active ~ .tmx-search-ptab svg {
    color: #00b9fc;
  }
}

@media (min-width: 989px) {
  #tmx-search-f {
    max-width: 988px;
    margin: 0 auto;
  }
  #tmx-search-input-f {
    -webkit-box-sizing: content-box !important;
    -moz-box-sizing: content-box !important;
    box-sizing: content-box !important;
    display: block;
    width: 80%;
    height: 35px;
    float: left;
    padding: 0 2%;
    background-color: rgba(255, 255, 255, 0);
  }
  #tmx-search-f .tmx-submit {
    display: block;
    color: #0061d5;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #0061d5 !important;
    line-height: 35px;
    padding: 0;
    border: 0;
    margin: 0;
    font-size: 16px;
    width: 15%;
    float: right;
    position: relative;
  }
  #tmx-search-f .tmx-submit .tmx-arrow {
    color: #0061d5;
    border-left: 1px solid #0061d5 !important;
    display: block;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 23%;
    float: right;
  }
  #tmx-search-f .tmx-submit .tmx-arrow .tmx-arrow-inner {
    display: inline-block;
    border-style: solid;
    border-color: #0061d5;
    border-top-width: 1px;
    border-right-width: 1px;
    height: 9px;
    width: 9px;
    border-left: 0 none;
    border-bottom: 0 none;
    margin: 1px auto;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: 12px;
  }
  #tmx-search-f .tmx-submit:hover {
    background-color: #0061d5;
    color: #fff;
  }
  #tmx-search-f .tmx-submit:hover .tmx-arrow {
    border-left: 1px solid #fff !important;
    color: #FFF;
  }
  #tmx-search-f .tmx-submit:hover .tmx-arrow-inner {
    border-color: #FFF;
  }
  #tmx-footer {
    font-size: 13px;
    line-height: 28px;
    text-align: center;
  }
  #tmx-footer .tmx-wrap {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  #tmx-footer .tmx-menu.tmx-left, #tmx-footer .tmx-menu.tmx-right {
    display: inline-block;
  }
  #tmx-footer .tmx-menu .tmx-item {
    display: inline-block;
  }
  #tmx-footer .tmx-menu.tmx-left a {
    display: inline-block;
    text-decoration: none;
    color: #4b4b4b;
    border-bottom: 0 none;
    border-right: 1px solid #dcdcdc;
    padding: 0 10px;
  }
  #tmx-promo {
    width: 988px;
    max-width: 988px;
  }
}

body.tmhf-hidedropdown .tmx-trigger {
  display: none !important;
}

@media (max-width: 749px) {
  body.tmhf-m-slim .tmx-menu-mobile, body.tmhf-m-slim .tmx-trigger {
    display: none !important;
  }
  body.tmhf-m-slim #tmx-network {
    height: 30px;
  }
  body.tmhf-m-slim #tmx-network .tmx-tmedia {
    margin-left: 0;
  }
  body.tmhf-m-slim #tmx-network .tmx-tmedia svg {
    height: 30px;
    width: 65px;
    padding: 0 0 0 9px;
  }
  body.tmhf-m-slim.tmhf-m-right #tmx-network .tmx-tmedia {
    float: right;
  }
}

body.is-using-site-nav #bphf-top {
  z-index: 2000 !important;
}

#tmx-afl-header {
  height: 34px;
  width: 100%;
  background-image: linear-gradient(to bottom, #001437, #000c2a);
  font-family: "Titillium Upright","Trebuchet MS","Lucida Sans Unicode","Lucida Grande","Lucida Sans",Arial,sans-serif !important;
  font-weight: 600;
  font-size: 15px;
  color: #fff;
}

#tmx-afl-header * {
  box-sizing: content-box !important;
}

#tmx-afl-header .tmx-afl-wrap {
  position: relative;
  height: inherit;
  width: 100%;
}

#tmx-afl-header .tmx-afl-left {
  float: left;
  height: inherit;
}

#tmx-afl-header .tmx-afl-right {
  position: absolute;
  right: 0;
  height: inherit;
}

#tmx-afl-header .tmx-afl-trigger {
  display: table-cell;
  height: inherit;
  vertical-align: middle;
  cursor: pointer;
}

#tmx-afl-header .tmx-afl-cell {
  display: table-cell;
  vertical-align: middle;
}

#tmx-afl-header .tmx-afl-arrow {
  border-color: transparent;
  border-top-color: #fff;
  border-style: solid;
  border-width: 4px 4px 0;
  display: block;
  margin: 4px 0 0 6px;
}

#tmx-afl-header .tmx-afl-menu {
  display: table-cell;
  height: inherit;
  vertical-align: middle;
  margin: 0;
  padding: 0 17px 0 20px;
  list-style-type: none;
}

#tmx-afl-header .tmx-afl-div {
  display: table-cell;
  height: inherit;
  position: relative;
  width: 2px;
}

#tmx-afl-header .tmx-afl-div:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid #01285e;
  margin: 6px 0;
}

#tmx-afl-header .tmx-afl-menu a {
  display: block;
  color: inherit;
  text-decoration: none;
  line-height: 20px;
}

#tmx-afl-header .tmx-afl-item {
  padding: 10px 0;
}

#tmx-afl-telstra-menu .tmx-afl-menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #000c2a;
  height: auto;
  padding: 20px 10px 50px 10px;
  columns: 2;
  font-family: gravur,sans-serif;
  z-index: 2000 !important;
}

#tmx-afl-telstra-menu.tmx-afl-active .tmx-afl-menu {
  display: block;
}

#tmx-afl-telstra-menu .tmx-afl-item {
  display: inline-block;
  width: 78%;
  padding: 9px;
  margin: 0 20px 0 0;
  text-align: left;
  border-bottom: 1px solid #fff;
  overflow: hidden;
}

#tmx-afl-telstra-menu .tmx-afl-svg-telstra {
  height: 25px;
  width: 28px;
  margin-top: 2px;
}

#tmx-afl-telstra-menu .tmx-afl-menu .tmx-afl-svg {
  height: 20px;
  width: 22px;
  float: left;
  padding-right: 10px;
}

#tmx-afl-header .tmx-afl-svg-livepass-logo {
  height: 19px;
  width: 60px;
  display: block;
  box-sizing: content-box !important;
}

#tmx-afl-header .tmx-afl-svg-livepass-cta {
  height: 24px;
  width: 136px;
  display: block;
  box-sizing: content-box !important;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #001437, #000c2a);
}

#tmx-afl-clubs-menu .tmx-afl-menu {
  display: none;
}

#tmx-afl-clubs-menu.tmx-afl-active {
  background-color: #f2f2f2;
  color: #001030;
}

#tmx-afl-clubs-menu.tmx-afl-active .tmx-afl-menu {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  height: auto;
  padding: 20px 0;
  text-align: center;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.25);
  z-index: 2000 !important;
}

#tmx-afl-clubs-menu .tmx-afl-close-icon {
  position: relative;
  margin-right: 7px;
  padding-right: 7px;
}

#tmx-afl-clubs-menu .tmx-afl-close-icon:before {
  position: absolute;
  top: 6px;
  right: -4px;
  height: 10px;
  width: 2px;
  background: #001030;
  content: "";
  transform: rotate(-45deg);
  border-radius: 5px;
}

#tmx-afl-clubs-menu .tmx-afl-close-icon:after {
  position: absolute;
  top: 6px;
  right: -4px;
  height: 10px;
  width: 2px;
  background: #001030;
  content: "";
  transform: rotate(45deg);
  border-radius: 5px;
}

#tmx-afl-clubs-menu .tmx-afl-close, #tmx-afl-clubs-menu.tmx-afl-active .tmx-afl-open {
  display: none;
}

#tmx-afl-clubs-menu .tmx-afl-open, #tmx-afl-clubs-menu.tmx-afl-active .tmx-afl-close {
  display: block;
}

#tmx-afl-clubs-menu .tmx-afl-item {
  padding: 0;
  display: inline-block;
  width: 15.5%;
  text-align: center;
}

#tmx-afl-clubs-menu .tmx-afl-svg {
  height: 50px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

#tmx-afl-clubs-menu .tmx-logo-adel {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-adel.svg");
}

#tmx-afl-clubs-menu .tmx-logo-bl {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-bl.svg");
}

#tmx-afl-clubs-menu .tmx-logo-carl {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-carl.svg");
}

#tmx-afl-clubs-menu .tmx-logo-coll {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/small/standard/logo-coll-small.svg");
}

#tmx-afl-clubs-menu .tmx-logo-ess {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-ess.svg");
}

#tmx-afl-clubs-menu .tmx-logo-fre {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-fre.svg");
}

#tmx-afl-clubs-menu .tmx-logo-gcfc {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/small/standard/logo-gcfc-small.svg");
}

#tmx-afl-clubs-menu .tmx-logo-geel {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-geel.svg");
}

#tmx-afl-clubs-menu .tmx-logo-gws {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-gws.svg");
}

#tmx-afl-clubs-menu .tmx-logo-haw {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-haw.svg");
}

#tmx-afl-clubs-menu .tmx-logo-melb {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-melb.svg");
}

#tmx-afl-clubs-menu .tmx-logo-nmfc {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-nmfc.svg");
}

#tmx-afl-clubs-menu .tmx-logo-port {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-port.svg");
}

#tmx-afl-clubs-menu .tmx-logo-rich {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-rich.svg");
}

#tmx-afl-clubs-menu .tmx-logo-stk {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-stk.svg");
}

#tmx-afl-clubs-menu .tmx-logo-syd {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-syd.svg");
}

#tmx-afl-clubs-menu .tmx-logo-wb {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-wb.svg");
}

#tmx-afl-clubs-menu .tmx-logo-wce {
  background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/standard/logo-wce.svg");
}

#tmx-afl-links-menu.tmx-afl-menu {
  padding-right: 0;
}

#tmx-afl-links-menu .tmx-afl-item {
  display: table-cell;
  height: inherit;
  vertical-align: middle;
  padding: 0 12px 0 0;
}

body.tmhf-afl-club #tmx-afl-header .tmx-afl-club-hidden {
  display: none;
}

#tmx-afl-header .tmx-afl-xlg-cell {
  display: none;
}

@media (min-width: 688px) {
  #tmx-afl-header {
    height: 36px;
  }
}

@media (min-width: 970px) {
  #tmx-afl-header {
    height: 50px;
    position: relative;
  }
  #tmx-afl-header .tmx-afl-wrap {
    position: initial;
  }
  #tmx-afl-header .tmx-afl-lg-hidden {
    display: none;
  }
  #tmx-afl-clubs-menu.tmx-afl-active {
    background-color: transparent;
    color: inherit;
  }
  #tmx-afl-clubs-menu .tmx-afl-menu {
    display: table-cell;
    padding: 0;
  }
  #tmx-afl-clubs-menu .tmx-afl-menu, #tmx-afl-clubs-menu.tmx-afl-active .tmx-afl-menu {
    display: table-cell;
    position: unset;
    background-color: transparent;
    height: inherit;
    padding: 0;
    box-shadow: none;
  }
  #tmx-afl-clubs-menu .tmx-afl-item {
    width: auto;
  }
  #tmx-afl-clubs-menu .tmx-afl-svg {
    height: 38px;
    width: 38px;
  }
  #tmx-afl-clubs-menu .tmx-logo-adel {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-adel-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-bl {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-bl-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-carl {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-carl-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-coll {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/small/standard/logo-coll-small.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-fre {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-fre-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-geel {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-geel-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-haw {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-haw-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-nmfc {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-nmfc-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-rich {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-rich-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-stk {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-stk-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-syd {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-syd-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-wb {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-wb-rev.svg");
  }
  #tmx-afl-clubs-menu .tmx-logo-wce {
    background-image: url("../../../static-resources/responsive/components/common/logos/club-logos/images/normal/reversed/logo-wce-rev.svg");
  }
}

@media (min-width: 1020px) {
  #tmx-afl-clubs-menu .tmx-afl-item a {
    padding: 0 .5%;
  }
}

@media (min-width: 1060px) {
  #tmx-afl-clubs-menu .tmx-afl-item a {
    padding: 0 3px;
  }
}

@media (min-width: 1100px) {
  #tmx-afl-clubs-menu .tmx-afl-item a {
    padding: 0 4px;
  }
}

@media (min-width: 1160px) {
  #tmx-afl-clubs-menu .tmx-afl-item a {
    padding: 0 5px;
  }
}

@media (min-width: 1281px) {
  #tmx-afl-header .tmx-afl-wrap {
    width: 1280px;
    margin: 0 auto;
  }
  #tmx-afl-header .tmx-afl-right {
    float: right;
    position: relative;
  }
  #tmx-afl-telstra-menu .tmx-afl-menu {
    columns: auto;
    width: 157px;
    padding: 12px;
    left: inherit;
    right: auto;
    margin-left: -20px;
  }
  #tmx-afl-telstra-menu .tmx-afl-item {
    padding: 12px;
  }
  #tmx-afl-header .tmx-afl-xlg-cell {
    display: table-cell;
  }
  #tmx-afl-telstra-menu .tmx-afl-svg-telstra {
    height: 32px;
    width: 32px;
  }
  #tmx-afl-clubs-menu .tmx-afl-item a {
    padding: 0 6px;
  }
}

@media (min-width: 1486px) {
  #tmx-afl-telstra-menu .tmx-afl-menu {
    margin-left: -116px;
  }
}

.hero {
  width: 100%;
  background-size: cover;
  background-position: center -70px;
  background-repeat: no-repeat;
  position: relative;
  background-image: url("../images/banners/hero_mobile.jpg");
}

@media (min-width: 576px) {
  .hero {
    background-image: url("../images/banners/hero_desktop.jpg");
  }
}

@media (min-width: 768px) {
  .hero {
    background-position: center center;
  }
}

.hero > .container {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .hero > .container {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }
}

.hero .owl-carousel--hero .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

@media (min-width: 1200px) {
  .hero .owl-carousel--hero .owl-nav {
    display: block;
  }
}

.hero .owl-carousel--hero .owl-nav .owl-prev,
.hero .owl-carousel--hero .owl-nav .owl-next {
  border: 0 !important;
  appearance: none !important;
  background: none !important;
  outline: none !important;
  position: absolute;
  height: 36%;
  width: 18%;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  opacity: 0;
}

.hero .owl-carousel--hero .owl-nav .owl-prev {
  left: 0;
  cursor: url("../images/global/icons/left_arrow.svg"), auto;
}

.hero .owl-carousel--hero .owl-nav .owl-next {
  right: 0;
  cursor: url("../images/global/icons/right_arrow.svg"), auto;
}

.hero .owl-carousel--hero .owl-item {
  transition: opacity 0.3s ease;
  position: relative;
}

.hero .owl-carousel--hero .owl-item.active {
  z-index: 9;
}

.hero .owl-carousel--hero .owl-item .item {
  text-align: center;
  height: 100%;
  position: relative;
  transition: max-width 0.6s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero .owl-carousel--hero .owl-item .item .item__title,
.hero .owl-carousel--hero .owl-item .item .item__sub-title,
.hero .owl-carousel--hero .owl-item .item .item__foreground__title {
  text-transform: uppercase;
  margin-top: 0;
  text-shadow: 10px 11px 29px rgba(18, 15, 15, 0.29);
}

.hero .owl-carousel--hero .owl-item .item__bg,
.hero .owl-carousel--hero .owl-item .item__foreground {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.hero .owl-carousel--hero .owl-item .item__bg .item__bg__inner,
.hero .owl-carousel--hero .owl-item .item__foreground .item__bg__inner {
  transition: transform 1.5s ease, opacity 0.6s ease;
}

.hero .owl-carousel--hero .owl-item .item__bg img,
.hero .owl-carousel--hero .owl-item .item__foreground img {
  transition: max-width 1.2s ease, max-height 1.2s ease;
}

.hero .owl-carousel--hero .owl-item .item__bg {
  z-index: 1;
}

.hero .owl-carousel--hero .owl-item .item__foreground {
  z-index: 4;
  opacity: 0;
  transition: opacity 0.6s ease;
}

.hero .owl-carousel--hero .owl-item .item__foreground .item__foreground__inner {
  transition: transform 1.5s ease;
}

.hero .owl-carousel--hero .owl-item .item__foreground .item__foreground__title {
  color: #008AAB;
  transition: opacity 0.6s ease;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  z-index: 6;
  font-size: 3.5rem;
  opacity: 0;
  transition-delay: 1s;
}

@media (min-width: 375px) {
  .hero .owl-carousel--hero .owl-item .item__foreground .item__foreground__title {
    font-size: 4.2rem;
  }
}

@media (min-width: 768px) {
  .hero .owl-carousel--hero .owl-item .item__foreground .item__foreground__title {
    font-size: 6rem;
  }
}

@media (min-width: 992px) {
  .hero .owl-carousel--hero .owl-item .item__foreground .item__foreground__title {
    font-size: 8rem;
  }
}

@media (min-width: 1200px) {
  .hero .owl-carousel--hero .owl-item .item__foreground .item__foreground__title {
    transform: scale(1.2);
  }
}

@media (min-width: 1500px) {
  .hero .owl-carousel--hero .owl-item .item__foreground .item__foreground__title {
    transform: scale(1.5);
  }
}

.hero .owl-carousel--hero .owl-item .item__players {
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.6s ease, transform 0.9s ease;
  position: relative;
  z-index: 2;
  transform: scale(0.8);
}

.hero .owl-carousel--hero .owl-item .item__players.scale-out {
  transform: scale(0.8) !important;
  opacity: 0 !important;
}

.hero .owl-carousel--hero .owl-item .item__players > img {
  max-height: 30rem;
  width: auto;
}

@media (min-width: 375px) {
  .hero .owl-carousel--hero .owl-item .item__players > img {
    max-height: 35rem;
  }
}

@media (min-width: 414px) {
  .hero .owl-carousel--hero .owl-item .item__players > img {
    max-height: 40rem;
  }
}

@media (min-width: 576px) {
  .hero .owl-carousel--hero .owl-item .item__players > img {
    max-height: 50rem;
  }
}

@media (min-width: 768px) {
  .hero .owl-carousel--hero .owl-item .item__players > img {
    max-height: 55rem;
  }
}

@media (min-width: 1024px) {
  .hero .owl-carousel--hero .owl-item .item__players > img {
    max-height: 65rem;
  }
}

@media (min-width: 1200px) {
  .hero .owl-carousel--hero .owl-item .item__players > img {
    max-height: 70rem;
  }
}

@media (min-width: 1500px) {
  .hero .owl-carousel--hero .owl-item .item__players > img {
    max-height: 80rem;
  }
}

.hero .owl-carousel--hero .owl-item .item__text {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 3;
}

.hero .owl-carousel--hero .owl-item .item__text .item__title,
.hero .owl-carousel--hero .owl-item .item__text .item__sub-title {
  color: #fff;
}

.hero .owl-carousel--hero .owl-item .item__text .item__title {
  transition: transform 1.5s ease, margin 1.2s ease, opacity 0.6s ease;
}

.hero .owl-carousel--hero .owl-item .item__text .item__sub-title {
  transition: opacity 0.3s ease;
}

.hero .owl-carousel--hero .owl-item .item__text .item__title {
  font-size: 10rem;
  line-height: 6rem;
  margin-bottom: 2rem;
}

@media (min-width: 375px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__title {
    font-size: 12rem;
    line-height: 8rem;
  }
}

@media (min-width: 576px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__title {
    font-size: 16rem;
    line-height: 12rem;
    margin-bottom: 3rem;
  }
}

@media (min-width: 768px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__title {
    font-size: 20rem;
    line-height: 16rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__title {
    font-size: 26rem;
    line-height: 20rem;
  }
}

@media (min-width: 1500px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__title {
    font-size: 33rem;
    line-height: 30rem;
  }
}

.hero .owl-carousel--hero .owl-item .item__text .item__sub-title {
  font-size: 3.5rem;
  line-height: 2.5rem;
  margin-bottom: 0;
}

@media (min-width: 375px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__sub-title {
    font-size: 4.5rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 414px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__sub-title {
    font-size: 5rem;
    line-height: 3rem;
  }
}

@media (min-width: 576px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__sub-title {
    font-size: 6rem;
    line-height: 3rem;
  }
}

@media (min-width: 768px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__sub-title {
    font-size: 5rem;
    line-height: 2rem;
  }
}

@media (min-width: 992px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__sub-title {
    font-size: 8rem;
    line-height: 5rem;
  }
}

@media (min-width: 1500px) {
  .hero .owl-carousel--hero .owl-item .item__text .item__sub-title {
    font-size: 9rem;
    line-height: 7rem;
  }
}

.hero .owl-carousel--hero .owl-item .item__line {
  position: absolute;
  top: 80%;
  left: 50%;
  height: 200px;
  width: 3px;
  background-color: #008AAB;
  transform-origin: 100% 0;
  transform: scaleY(0);
  transition: transform 1.5s ease, opacity 0.3s ease;
  z-index: 5;
}

@media (min-width: 992px) {
  .hero .owl-carousel--hero .owl-item .item__line {
    top: 70%;
    height: 300px;
  }
}

@media (min-width: 1500px) {
  .hero .owl-carousel--hero .owl-item .item__line {
    top: 80%;
  }
}

.hero .owl-carousel--hero .owl-item .item__line.color--white {
  background-color: #fff;
}

.hero .owl-carousel--hero .owl-item .item#item-8 .item__text {
  display: none;
}

.hero .owl-carousel--hero .owl-item .item#item-8 .item__players img {
  max-height: 10rem;
}

@media (min-width: 414px) {
  .hero .owl-carousel--hero .owl-item .item#item-8 .item__players img {
    max-height: 13rem;
  }
}

@media (min-width: 768px) {
  .hero .owl-carousel--hero .owl-item .item#item-8 .item__players img {
    max-height: 15rem;
  }
}

@media (min-width: 992px) {
  .hero .owl-carousel--hero .owl-item .item#item-8 .item__players img {
    max-height: 20rem;
  }
}

@media (min-width: 1500px) {
  .hero .owl-carousel--hero .owl-item .item#item-8 .item__players img {
    max-height: 30rem;
  }
}

.hero .owl-carousel--hero .owl-item .item#item-8 .item__foreground__inner--front {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease;
}

@media (min-width: 375px) {
  .hero .owl-carousel--hero .owl-item .item#item-8 .item__foreground__inner--front > img {
    transform: scale(1.2);
  }
}

@media (min-width: 768px) {
  .hero .owl-carousel--hero .owl-item .item#item-8 .item__foreground__inner--front > img {
    transform: scale(1.7);
  }
}

@media (min-width: 1200px) {
  .hero .owl-carousel--hero .owl-item .item#item-8 .item__foreground__inner--front > img {
    transform: scale(1.9);
  }
}

@media (min-width: 1500px) {
  .hero .owl-carousel--hero .owl-item .item#item-8 .item__foreground__inner--front > img {
    transform: scale(2.1);
  }
}

.hero .owl-carousel--hero .owl-item:not(.active) .item__bg {
  transform: translateY(-50%);
}

.hero .owl-carousel--hero .owl-item:not(.active) .item__bg .item__bg__inner {
  transform: scale(0.7) !important;
  opacity: 0.5 !important;
}

.hero .owl-carousel--hero .owl-item:not(.active) .item__players {
  transform: scale(0.8);
  opacity: 0;
}

.hero .owl-carousel--hero .owl-item.active--current .item__players,
.hero .owl-carousel--hero .owl-item.active--current .item__text {
  opacity: 1;
}

.hero .owl-carousel--hero .owl-item.active--current .item__players {
  transform: scale(1);
}

.hero .owl-carousel--hero .owl-item.active--current .item__text {
  transition-delay: 0.5s;
}

.hero .owl-carousel--hero .owl-item.active--prev .item__bg .item__bg__inner {
  transform-origin: right;
}

.hero .owl-carousel--hero .owl-item.active--next .item__bg .item__bg__inner {
  transform-origin: left;
}

.hero .hero__dots {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  width: 85%;
  transform: translateX(-50%);
  z-index: 1092;
  text-align: center;
  display: flex;
  justify-content: space-between;
  transition: opacity 0.3s ease;
}

.hero .hero__dots::before {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1px;
  background-color: #fff;
}

@media (min-width: 1024px) {
  .hero .hero__dots::before {
    top: 58.8%;
  }
}

@media (min-width: 1200px) {
  .hero .hero__dots::before {
    top: 0;
    left: auto;
    right: 14px;
    transform: none !important;
    width: 1px;
    height: 100%;
  }
}

@media (min-width: 375px) {
  .hero .hero__dots {
    bottom: 3rem;
  }
}

@media (min-width: 768px) {
  .hero .hero__dots {
    bottom: 5rem;
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .hero .hero__dots {
    right: 3rem;
    top: 0;
    left: auto;
    bottom: auto;
    height: 100%;
    width: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1500px) {
  .hero .hero__dots {
    right: 5rem;
  }
}

.hero .hero__dots .owl-dot {
  appearance: none !important;
  outline: none !important;
  border: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .hero .hero__dots .owl-dot {
    display: block;
    margin-bottom: 4.5rem;
    width: 100%;
    text-align: center;
  }
  .hero .hero__dots .owl-dot::after {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    text-transform: uppercase;
    font-family: "DharmaGothicCW01";
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 2px;
    position: absolute;
    left: 3rem;
    top: 7px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .hero .hero__dots .owl-dot:first-child::after {
    content: 'Intro';
  }
  .hero .hero__dots .owl-dot:nth-child(2)::after {
    content: 'One';
  }
  .hero .hero__dots .owl-dot:nth-child(3)::after {
    content: 'Two';
  }
  .hero .hero__dots .owl-dot:nth-child(4)::after {
    content: 'Three';
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .hero .hero__dots .owl-dot:nth-child(5) > span {
    border: 1px solid #000;
  }
}

@media (min-width: 1200px) {
  .hero .hero__dots .owl-dot:nth-child(5)::after {
    content: 'Four';
    color: #000;
  }
  .hero .hero__dots .owl-dot:nth-child(6)::after {
    content: 'Five';
  }
  .hero .hero__dots .owl-dot:nth-child(7)::after {
    content: 'Six';
  }
  .hero .hero__dots .owl-dot:nth-child(8)::after {
    content: 'Seven';
  }
  .hero .hero__dots .owl-dot:last-child {
    margin-bottom: 0;
  }
  .hero .hero__dots .owl-dot:last-child::after {
    content: 'Eight';
  }
}

@media (min-width: 1500px) {
  .hero .hero__dots .owl-dot {
    margin-bottom: 5rem;
  }
  .hero .hero__dots .owl-dot::after {
    left: 3.5rem;
  }
}

.hero .hero__dots .owl-dot > span {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.3s ease;
}

@media (min-width: 1200px) {
  .hero .hero__dots .owl-dot > span {
    margin: 0 auto;
    width: 9px;
    height: 9px;
  }
}

.hero .hero__dots .owl-dot.active > span {
  transform: scale(1.5);
}

@media (min-width: 1200px) {
  .hero .hero__dots .owl-dot.active > span {
    transform: scale(1.7);
  }
}

.hero .hero__dots .owl-dot.active::after {
  opacity: 1;
}

.hero .hero__btn {
  position: absolute;
  bottom: 13%;
  z-index: 5;
  width: 100%;
  text-align: center;
  transition: opacity 0.3s ease;
}

@media (min-width: 992px) {
  .hero .hero__btn {
    bottom: 13%;
  }
}

@media (min-width: 1200px) {
  .hero .hero__btn {
    bottom: 11%;
  }
}

.hero .hero__btn .btn {
  padding: 1.2rem 2rem 1rem 2.8rem;
  font-size: 1.1rem;
}

@media (min-width: 768px) {
  .hero .hero__btn .btn {
    padding: 1.7rem 2rem 1.5rem 2.5rem;
    font-size: 1.5rem;
  }
}

.hero .hero__loader {
  position: absolute;
  left: 0.5rem;
  bottom: 2rem;
  display: none;
}

@media (min-width: 992px) {
  .hero .hero__loader {
    left: 2.5rem;
    bottom: 3.5rem;
  }
}

.hero .hero__loader > img {
  max-width: 7rem;
}

@media (min-width: 992px) {
  .hero .hero__loader > img {
    max-width: 10rem;
  }
}

.hero.transitioned {
  background-image: none;
}

.hero.transitioned .owl-carousel--hero .owl-item.active .item__line {
  transform: scaleY(1);
  opacity: 1;
  transition-delay: 1.5s;
}

.hero.transitioned .owl-carousel--hero .owl-item.active .item__foreground .item__foreground__title {
  opacity: 1;
}

.hero.transitioned .owl-carousel--hero .owl-item.active #item-8 .item__foreground__inner--front {
  opacity: 1;
}

.text-block h1 {
  color: #000;
  margin-bottom: 4rem;
}

@media (min-width: 992px) {
  .text-block h1 {
    margin-bottom: 6rem;
  }
}

.text-block.text-block--overlay {
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  .text-block.text-block--overlay p {
    margin-bottom: 0;
    padding: 3rem 3rem 10rem 0;
    background-color: #fff;
    position: relative;
    bottom: -1px;
    z-index: 2;
    width: 150%;
  }
}

.text-block.text-block--padded {
  background-color: #fff;
  padding: 3rem;
}

@media (min-width: 992px) {
  .text-block.text-block--padded {
    padding: 5rem;
  }
}

.img-block > img {
  width: 100%;
  display: inline-block;
}

.img-caption {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .img-caption {
    max-width: 28rem;
  }
}

@media (min-width: 1200px) {
  .img-caption {
    margin-bottom: 4rem;
  }
}

.img-caption:last-child {
  margin-bottom: 0;
}

.img-caption .img-caption__img {
  margin-bottom: 1rem;
}

.img-caption .img-caption__img > img {
  width: 100%;
}

.img-caption .img-caption__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-caption .img-caption__text span {
  display: inline-block;
  font-family: "DharmaGothicCW01";
  font-size: 3.5rem;
  line-height: 3rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.img-caption .img-caption__text span.img-caption__name {
  color: #000;
  text-align: left;
}

.img-caption .img-caption__text span.img-caption__date {
  color: #008AAB;
  text-align: right;
}

.ml-spotlight {
  position: relative;
  margin-bottom: 18rem;
}

@media (min-width: 375px) {
  .ml-spotlight {
    margin-bottom: 24rem;
  }
}

@media (min-width: 768px) {
  .ml-spotlight {
    margin-bottom: 20rem;
  }
}

@media (min-width: 992px) {
  .ml-spotlight {
    max-width: 65rem;
    margin-bottom: 30rem;
  }
}

@media (min-width: 1200px) {
  .ml-spotlight {
    max-width: 80rem;
    margin-bottom: 24rem;
  }
}

@media (min-width: 1500px) {
  .ml-spotlight {
    max-width: 100rem;
  }
}

.ml-spotlight img {
  width: 100%;
}

.ml-spotlight .ml-spotlight__img-container {
  position: relative;
}

@media (min-width: 992px) {
  .ml-spotlight .ml-spotlight__img-container::after {
    content: '';
    display: block;
    z-index: 2;
    width: 20%;
    height: 57%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 1500px) {
  .ml-spotlight .ml-spotlight__img-container::after {
    height: 68%;
    width: 18%;
  }
}

.ml-spotlight .ml-spotlight__img-container .ml-spotlight__bg {
  z-index: 1;
}

.ml-spotlight .ml-spotlight__img-container .ml-spotlight__foreground {
  z-index: 3;
  position: absolute;
  top: 6rem;
  right: 0;
  max-width: 16rem;
}

@media (min-width: 375px) {
  .ml-spotlight .ml-spotlight__img-container .ml-spotlight__foreground {
    max-width: 20rem;
  }
}

@media (min-width: 992px) {
  .ml-spotlight .ml-spotlight__img-container .ml-spotlight__foreground {
    max-width: 23rem;
    right: 2rem;
  }
}

@media (min-width: 1200px) {
  .ml-spotlight .ml-spotlight__img-container .ml-spotlight__foreground {
    top: 3rem;
  }
}

@media (min-width: 1500px) {
  .ml-spotlight .ml-spotlight__img-container .ml-spotlight__foreground {
    max-width: 28rem;
  }
}

.ml-spotlight .ml-spotlight__caption {
  padding-left: 15px;
}

.ml-spotlight .ml-spotlight__text {
  z-index: 4;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .ml-spotlight .ml-spotlight__text {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    bottom: -19rem;
    max-width: 35rem;
    right: -27rem;
  }
}

@media (min-width: 1200px) {
  .ml-spotlight .ml-spotlight__text {
    bottom: -14rem;
  }
}

@media (min-width: 1500px) {
  .ml-spotlight .ml-spotlight__text {
    bottom: -4rem;
    right: -25.5rem;
    max-width: 37rem;
  }
}

.ml-spotlight-alt {
  position: relative;
  margin: 0 auto;
  max-width: 24rem;
}

@media (min-width: 768px) {
  .ml-spotlight-alt {
    margin: 0;
    max-width: 26rem;
  }
}

@media (min-width: 992px) {
  .ml-spotlight-alt {
    max-width: 36rem;
  }
}

@media (min-width: 1200px) {
  .ml-spotlight-alt {
    max-width: 40rem;
  }
}

.ml-spotlight-alt img {
  width: 100%;
}

.ml-spotlight-alt .ml-spotlight__text {
  position: absolute;
  bottom: 0;
  left: 3rem;
  z-index: 3;
  text-align: right;
}

.quote {
  overflow: hidden;
}

.quote img {
  width: 100%;
}

.quote .quote__inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.quote .quote__icon {
  display: block;
  color: rgba(255, 255, 255, 0.15);
  font-size: 135rem;
  line-height: 75rem;
  font-family: "DharmaGothicCW01";
  position: absolute;
  top: -3rem;
  left: -8rem;
}

@media (min-width: 375px) {
  .quote .quote__icon {
    font-size: 155rem;
    line-height: 85rem;
    position: absolute;
    left: -11rem;
  }
}

@media (min-width: 414px) {
  .quote .quote__icon {
    font-size: 180rem;
    line-height: 95rem;
  }
}

@media (min-width: 768px) {
  .quote .quote__icon {
    left: -3rem;
    line-height: 100rem;
  }
}

@media (min-width: 992px) {
  .quote .quote__icon {
    left: -11rem;
    line-height: 102rem;
  }
}

@media (min-width: 1200px) {
  .quote .quote__icon {
    font-size: 230rem;
    left: -18rem;
    line-height: 122rem;
  }
}

@media (min-width: 1500px) {
  .quote .quote__icon {
    font-size: 340rem;
    left: -33rem;
    line-height: 176rem;
  }
}

.quote .quote__by {
  padding-bottom: 7rem;
  max-width: 16rem;
  margin: 0 auto;
  text-align: right;
  position: relative;
}

.quote .quote__by span.quote__name,
.quote .quote__by span.quote__date {
  color: #fff;
  display: block;
}

.quote .quote__by span.quote__name {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 1200px) {
  .quote .quote__by span.quote__name {
    font-size: 2rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.quote .quote__by span.quote__date {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 2rem;
  opacity: 0.5;
}

@media (min-width: 1200px) {
  .quote .quote__by span.quote__date {
    font-size: 1.5rem;
  }
}

.quote p.quote__statement {
  margin-bottom: 0;
  color: #fff;
  font-size: 2rem;
  line-height: 4rem;
  font-weight: 300;
}

@media (min-width: 375px) {
  .quote p.quote__statement {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  .quote p.quote__statement {
    font-size: 2rem;
    line-height: 4rem;
    margin-top: -1rem;
  }
}

@media (min-width: 1200px) {
  .quote p.quote__statement {
    font-size: 3rem;
    line-height: 4.5rem;
  }
}

.quote-minimal .quote__inner {
  margin-top: -12rem;
  padding-left: 2rem;
}

@media (min-width: 414px) {
  .quote-minimal .quote__inner {
    margin-top: -9rem;
  }
}

@media (min-width: 992px) {
  .quote-minimal .quote__inner {
    padding-left: 14rem;
  }
}

@media (min-width: 1500px) {
  .quote-minimal .quote__inner {
    padding-left: 13rem;
    margin-top: -10rem;
  }
}

.quote-minimal .quote__icon {
  display: block;
}

.quote-minimal .quote__icon > img {
  max-width: 20rem;
}

@media (min-width: 1200px) {
  .quote-minimal .quote__icon > img {
    max-width: 40rem;
  }
}

.quote-minimal .quote__by span.quote__name,
.quote-minimal .quote__by span.quote__date {
  color: #808285;
  display: block;
}

.quote-minimal .quote__by span.quote__name {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 1200px) {
  .quote-minimal .quote__by span.quote__name {
    font-size: 2rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.quote-minimal .quote__by span.quote__date {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .quote-minimal .quote__by span.quote__date {
    font-size: 1.5rem;
  }
}

.quote-minimal p.quote__statement {
  margin-bottom: 2rem;
  color: #008AAB;
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 300;
}

@media (min-width: 1200px) {
  .quote-minimal p.quote__statement {
    margin-bottom: 4rem;
  }
}

.badge {
  position: relative;
  width: 23rem;
  margin: 0 auto 3rem auto;
  text-align: center;
}

.badge:last-child {
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .badge {
    width: 100%;
  }
}

.badge .badge__bg {
  background-image: url("../images/global/badge_2.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 14rem;
  height: 18rem;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .badge .badge__bg {
    width: 16rem;
    height: 26rem;
  }
}

.badge .badge__content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 11rem;
}

@media (min-width: 1200px) {
  .badge .badge__content {
    min-width: 15rem;
  }
}

.badge .badge__content h1 {
  font-size: 6rem;
  line-height: 4rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 1200px) {
  .badge .badge__content h1 {
    font-size: 8rem;
    line-height: 7rem;
  }
}

.badge .badge__content p {
  margin-bottom: 0;
  font-family: "DharmaGothicCW01";
  text-transform: uppercase;
  font-size: 2.8rem;
  line-height: 3rem;
  letter-spacing: 1px;
  color: #808285;
  padding: 0.5rem;
  margin-top: 1rem;
}

@media (min-width: 1200px) {
  .badge .badge__content p {
    font-size: 4rem;
    line-height: 4rem;
    margin-top: 0;
  }
}

.badge.badge--overflow .badge__bg {
  background-image: url("../images/global/badge_gap.png");
}

.badge.badge--overflow .badge__content {
  width: 100%;
}

@media (min-width: 1200px) {
  .badge.badge--overflow .badge__content {
    width: 105%;
  }
}

@media (min-width: 768px) {
  .badge.badge--left {
    margin: 0 0 5rem 0;
  }
}

@media (min-width: 1200px) {
  .sticky-block .badge {
    margin-bottom: 0;
  }
}

.owl-item .badge {
  margin-bottom: 0;
  width: 18rem;
}

@media (max-width: 575.98px) {
  .sticky-block .sticky-block__inner {
    position: static !important;
    width: 100% !important;
  }
}

.sticky-block .sticky-block__inner + div {
  display: none !important;
}

.embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1500px) {
  .number-scroll-container {
    margin-top: 8.5rem;
  }
}

.number-scroll {
  display: flex;
  margin-bottom: 5rem;
}

@media (min-width: 992px) {
  .number-scroll {
    margin-bottom: 10rem;
  }
}

@media (min-width: 1200px) {
  .number-scroll {
    margin-bottom: 15rem;
  }
}

.number-scroll:last-child {
  margin-bottom: 0;
}

.number-scroll .number-scroll__number {
  width: 8rem;
  padding-right: 2rem;
  text-align: right;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .number-scroll .number-scroll__number {
    width: 12rem;
    padding-right: 1.5rem;
  }
}

.number-scroll .number-scroll__number h1 {
  font-size: 11.5rem;
  line-height: 9.5rem;
}

@media (min-width: 1200px) {
  .number-scroll .number-scroll__number h1 {
    font-size: 20rem;
    line-height: 15.5rem;
  }
}

.number-scroll .number-scroll__number h1 span {
  display: inline-block;
}

.number-scroll .number-scroll__text h1 {
  font-size: 4.9rem;
  line-height: 4.5rem;
}

@media (min-width: 1200px) {
  .number-scroll .number-scroll__text h1 {
    font-size: 7.5rem;
    line-height: 7.5rem;
  }
}

.number-scroll .number-scroll__text span {
  display: block;
}

.number-scroll .number-scroll__text span.number-scroll__text__fill {
  color: #000;
}

.number-scroll .number-scroll__text span.number-scroll__text__hollow {
  letter-spacing: 3.5px;
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.dual-scroll .dual-scroll__text {
  display: none;
}

@media (min-width: 768px) {
  .dual-scroll .dual-scroll__text {
    display: block;
  }
}

.dual-scroll .dual-scroll__text h1 {
  margin-bottom: 4rem;
}

.dual-scroll .dual-scroll__text h1:last-child {
  margin-bottom: 0;
}

.dual-scroll .dual-scroll__text h1 span {
  display: inline-block;
}

.dual-scroll .dual-scroll__text h1 span.color--black {
  margin-right: -3px;
}

@media (min-width: 1200px) {
  .dual-scroll .dual-scroll__text h1 span.color--black {
    margin-right: -7px;
  }
}

.dual-scroll .dual-scroll__text h1 span.color--white {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  letter-spacing: 3.5px;
}

@media (min-width: 1200px) {
  .dual-scroll .dual-scroll__text h1 {
    font-size: 8.5rem;
    line-height: 7.5rem;
    margin-bottom: 3rem;
  }
}

@media (max-width: 1400px) {
  .paroller {
    transform: none !important;
  }
}

.chapters {
  display: none;
}

.chapters .chapter-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.chapters .chapter-bg .chapter-bg__inner {
  transform-origin: top;
  margin-left: -2px;
}

.chapters .chapter-bg .chapter-bg__inner > img {
  width: 100%;
}

.chapter-content {
  position: relative;
  z-index: 9;
}

@media (min-width: 1500px) {
  .chapter-content .container {
    max-width: 1140px;
  }
}

.chapter-content .section__bg {
  position: relative;
}

.chapter-content .section__bg > img {
  width: 100%;
}

.chapter-content .section__bg .section__bg__tear {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
}

.chapter-content .section__bg .section__bg__tear > img {
  width: 100%;
}

.chapter-content .section__bg .section__bg__tear.section__bg__tear--before {
  bottom: auto;
  top: -1px;
}

#chapter-0 .chapter-content {
  margin-top: 10rem;
}

@media (min-width: 768px) {
  #chapter-0 .chapter-content {
    margin-top: -10%;
  }
}

@media (min-width: 1200px) {
  #chapter-0 .chapter-content {
    margin-top: -30%;
  }
}

@media (min-width: 1500px) {
  #chapter-0 .chapter-content {
    margin-top: -25%;
  }
}

#chapter-0 #section-1 .container {
  padding-left: 0;
  padding-right: 0;
}

#chapter-0 #section-1 .section__bg {
  margin-top: -12.5rem;
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  #chapter-0 #section-1 .section__bg {
    margin-top: -30%;
  }
}

#chapter-0 #section-1 #chapter-0__el-1 {
  margin-top: 4rem;
}

@media (min-width: 768px) {
  #chapter-0 #section-1 #chapter-0__el-1 {
    margin-top: 0;
    position: relative;
    left: 8rem;
    top: -5rem;
  }
}

@media (min-width: 992px) {
  #chapter-0 #section-1 #chapter-0__el-1 {
    left: 20%;
    width: 110%;
  }
}

@media (min-width: 1024px) {
  #chapter-0 #section-1 #chapter-0__el-1 {
    left: 35%;
    top: -7rem;
  }
}

@media (min-width: 1500px) {
  #chapter-0 #section-1 #chapter-0__el-1 {
    left: 55%;
  }
}

#chapter-0 #section-1 #chapter-0__el-1 .text--callout {
  text-align: center;
}

@media (min-width: 768px) {
  #chapter-0 #section-1 #chapter-0__el-1 .text--callout {
    text-align: left;
  }
}

@media (min-width: 992px) {
  #chapter-0 #section-1 #chapter-0__el-1 .text--callout {
    font-size: 7rem;
    line-height: 6rem;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-1 #chapter-0__el-1 .text--callout {
    font-size: 10rem;
    line-height: 8rem;
  }
}

@media (min-width: 992px) {
  #chapter-0 #section-1 #chapter-0__el-1 .text--callout.color--black {
    text-align: right;
    margin-right: 2rem;
  }
}

@media (min-width: 1024px) {
  #chapter-0 #section-1 #chapter-0__el-1 .text--callout.color--primary {
    margin-bottom: -6.1rem;
  }
}

@media (min-width: 1383px) {
  #chapter-0 #section-1 #chapter-0__el-1 .text--callout.color--primary {
    margin-bottom: -8.2rem;
  }
}

#chapter-0 #section-1 #chapter-0__el-2 {
  margin-top: -10rem;
  padding-left: 1rem;
}

@media (min-width: 992px) {
  #chapter-0 #section-2 {
    margin-top: 4rem;
  }
}

#chapter-0 #section-2 .container {
  padding-left: 0;
  padding-right: 0;
}

#chapter-0 #section-2 #chapter-0__el-3 {
  position: relative;
  margin-top: 8rem;
}

@media (min-width: 375px) {
  #chapter-0 #section-2 #chapter-0__el-3 {
    margin-top: 9rem;
  }
}

@media (min-width: 414px) {
  #chapter-0 #section-2 #chapter-0__el-3 {
    margin-top: 10rem;
  }
}

@media (min-width: 768px) {
  #chapter-0 #section-2 #chapter-0__el-3 {
    margin-bottom: 0;
  }
}

#chapter-0 #section-2 #chapter-0__el-3 img {
  width: 100%;
  display: inline-block;
}

#chapter-0 #section-2 #chapter-0__el-3 .l-2 {
  position: absolute;
  bottom: -1rem;
  right: 0;
  width: 100%;
  text-align: center;
}

#chapter-0 #section-2 #chapter-0__el-3 .l-2 > img {
  width: 90%;
}

@media (min-width: 1200px) {
  #chapter-0 #section-2 #chapter-0__el-3 .l-2 {
    bottom: -3rem;
    right: -25%;
  }
}

#chapter-0 #section-2 #chapter-0__el-4 {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 4.5rem;
}

@media (min-width: 768px) {
  #chapter-0 #section-2 #chapter-0__el-4 {
    margin-top: 0;
    padding-left: 0;
    padding-right: 3rem;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-2 #chapter-0__el-4 {
    padding-right: 0;
    max-width: 35rem;
  }
}

#chapter-0 #section-2 #chapter-0__el-4 p:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  #chapter-0 #section-3 {
    margin-top: 8.5rem;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-3 {
    margin-top: 15rem;
  }
}

#chapter-0 #section-3 .section__content {
  position: relative;
  z-index: 2;
}

#chapter-0 #section-3 .section__bg--bottom {
  position: relative;
  z-index: 2;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  #chapter-0 #section-3 .section__bg--bottom {
    margin-top: 4rem;
    margin-bottom: 0;
  }
}

#chapter-0 #section-3 .section__bg--right {
  position: absolute;
  top: 10%;
  right: 0;
  width: 60%;
  z-index: 1;
}

@media (min-width: 768px) {
  #chapter-0 #section-3 #chapter-0__el-5,
  #chapter-0 #section-3 #chapter-0__el-6,
  #chapter-0 #section-3 #chapter-0__el-7 {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-3 #chapter-0__el-6 {
    max-width: 47rem;
  }
}

@media (min-width: 768px) {
  #chapter-0 #section-3 #chapter-0__el-7-wrapper {
    margin-top: -5%;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-3 #chapter-0__el-7-wrapper {
    margin-top: -10%;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-3 #chapter-0__el-7 {
    position: relative;
    left: -7rem;
  }
}

#chapter-0 #section-3 #chapter-0__el-8,
#chapter-0 #section-3 #chapter-0__el-9 {
  max-width: 47rem;
}

@media (min-width: 768px) {
  #chapter-0 #section-3 #chapter-0__el-8-wrapper {
    margin-top: 4rem;
  }
}

@media (min-width: 992px) {
  #chapter-0 #section-3 #chapter-0__el-8-wrapper {
    margin-top: 8.5rem;
  }
}

@media (min-width: 768px) {
  #chapter-0 #section-3 #chapter-0__el-9 {
    margin-top: 0;
  }
}

#chapter-0 #section-5 {
  margin-top: 6rem;
}

@media (min-width: 768px) {
  #chapter-0 #section-5 {
    margin-top: 10rem;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-5 {
    margin-top: 20rem;
  }
}

#chapter-0 #section-5 .section__bg {
  margin-top: -10rem;
}

@media (min-width: 768px) {
  #chapter-0 #section-5 .section__bg {
    margin-top: -50%;
  }
}

#chapter-0 #section-5 #chapter-0__el-12 {
  position: relative;
  z-index: 2;
}

#chapter-0 #section-6 {
  z-index: 2;
  margin-top: -5rem;
}

@media (min-width: 768px) {
  #chapter-0 #section-6 {
    margin-top: -10rem;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-6 {
    margin-top: -10%;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-6 .quote-minimal .quote__inner {
    position: relative;
    left: 20%;
  }
}

@media (min-width: 768px) {
  #chapter-0 #section-7 {
    margin-top: 5rem;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-7 {
    margin-top: 50rem;
  }
}

#chapter-0 #section-7 #chapter-0__el-14 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (min-width: 1200px) {
  #chapter-0 #section-7 #chapter-0__el-14 {
    margin-top: 0;
    max-width: 35rem;
    position: relative;
    top: -10rem;
  }
}

#chapter-0 #section-9 #chapter-0__el-15 {
  margin-top: 4rem;
  margin-bottom: -6rem;
}

@media (min-width: 768px) {
  #chapter-0 #section-9 #chapter-0__el-15 {
    margin: 8rem auto -5rem auto;
    max-width: 31rem;
  }
}

@media (min-width: 992px) {
  #chapter-0 #section-9 #chapter-0__el-15 {
    max-width: 52rem;
  }
}

@media (min-width: 1200px) {
  #chapter-0 #section-9 #chapter-0__el-15 {
    margin: 12rem auto -5rem auto;
  }
}

#chapter-0 #section-9 #chapter-0__el-15 .text--callout {
  text-align: center;
}

@media (min-width: 768px) {
  #chapter-0 #section-9 #chapter-0__el-15 .text--callout {
    text-align: left;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  #chapter-0 #section-9 #chapter-0__el-15 .text--callout.color--primary {
    text-align: right;
  }
}

@media (min-width: 768px) {
  #chapter-0 #section-9 #chapter-0__el-15 .text--callout.color--black {
    position: relative;
    bottom: -9.25rem;
  }
}

@media (min-width: 768px) {
  #chapter-0 #section-9 #chapter-0__el-15 .text--callout.color--black {
    bottom: -16.25rem;
  }
}

#chapter-1 .chapter-content {
  margin-top: -45%;
}

@media (min-width: 992px) {
  #chapter-1 .chapter-content {
    margin-top: -20%;
  }
}

@media (min-width: 1500px) {
  #chapter-1 .chapter-content {
    margin-top: -10%;
  }
}

#chapter-1 #section-3 {
  position: relative;
  z-index: 2;
}

#chapter-1 #section-3 .section__bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
}

#chapter-1 #section-3 .section__bg > img {
  width: 100%;
}

#chapter-1 #section-4 {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

#chapter-1 #section-5 {
  position: relative;
  z-index: 1;
  padding-top: 20rem;
}

@media (min-width: 1200px) {
  #chapter-1 #section-5 {
    padding-top: 12rem;
  }
}

#chapter-1 #section-5 .container {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 576px) {
  #chapter-1 #section-5 .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#chapter-1 #section-5 .section__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  z-index: 1;
}

@media (min-width: 768px) {
  #chapter-1 #section-5 .section__bg {
    width: 50%;
  }
}

#chapter-1 #section-5 .section__bg > img {
  width: 100%;
}

#chapter-1 #section-5 #chapter-1__el-9 {
  z-index: 2;
}

#chapter-1 #section-5 #chapter-1__el-9 img {
  width: 100%;
}

#chapter-1 #section-5 #chapter-1__el-9 .l-1 {
  z-index: 1;
  max-width: 15rem;
  position: absolute;
  top: -11.5rem;
  left: 15px;
}

@media (min-width: 768px) {
  #chapter-1 #section-5 #chapter-1__el-9 .l-1 {
    max-width: 20rem;
    top: -15.5rem;
  }
}

@media (min-width: 1200px) {
  #chapter-1 #section-5 #chapter-1__el-9 .l-1 {
    max-width: 25rem;
    top: -10.5rem;
    left: -23rem;
  }
}

#chapter-1 #section-5 #chapter-1__el-9 .l-2 {
  z-index: 2;
  margin-right: -15px;
  margin-left: -15px;
}

#chapter-1 #section-5 #chapter-1__el-9 .l-2 .text--caption {
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
}

@media (min-width: 768px) {
  #chapter-1 #section-5 #chapter-1__el-9 .l-2 .text--caption {
    text-align: center;
  }
}

#chapter-1 #section-6 {
  z-index: 3;
}

#chapter-1 #section-6 .container {
  position: relative;
  z-index: 2;
}

#chapter-1 #section-6 .section__bg {
  margin-top: -100%;
}

@media (min-width: 768px) {
  #chapter-1 #section-6 .section__bg {
    margin-top: -30%;
  }
}

#chapter-1 #section-6 #chapter-1__el-11,
#chapter-1 #section-6 #chapter-1__el-13 {
  padding-top: 21rem;
  position: relative;
}

@media (min-width: 1200px) {
  #chapter-1 #section-6 #chapter-1__el-11,
  #chapter-1 #section-6 #chapter-1__el-13 {
    right: -7rem;
    top: -15rem;
  }
}

#chapter-1 #section-6 #chapter-1__el-11 img,
#chapter-1 #section-6 #chapter-1__el-13 img {
  width: 100%;
}

#chapter-1 #section-6 #chapter-1__el-11 .l-1,
#chapter-1 #section-6 #chapter-1__el-13 .l-1 {
  z-index: 1;
  position: absolute;
  top: 4rem;
  right: 2rem;
  max-width: 14rem;
}

@media (min-width: 414px) {
  #chapter-1 #section-6 #chapter-1__el-11 .l-1,
  #chapter-1 #section-6 #chapter-1__el-13 .l-1 {
    top: 5rem;
  }
}

@media (min-width: 1200px) {
  #chapter-1 #section-6 #chapter-1__el-11 .l-1,
  #chapter-1 #section-6 #chapter-1__el-13 .l-1 {
    right: -11rem;
  }
}

#chapter-1 #section-6 #chapter-1__el-11 .l-2,
#chapter-1 #section-6 #chapter-1__el-13 .l-2 {
  z-index: 2;
}

#chapter-1 #section-6 .sticky-block {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  #chapter-1 #section-6 .sticky-block {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#chapter-1 #section-6 #chapter-1__el-12 {
  margin-bottom: 5rem;
}

@media (min-width: 576px) {
  #chapter-1 #section-6 #chapter-1__el-12 {
    margin-bottom: 0;
  }
}

#chapter-1 #section-6 .order-md-1 {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 576px) {
  #chapter-1 #section-6 .order-md-1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#chapter-1 #section-6 #chapter-1__el-14-a,
#chapter-1 #section-6 #chapter-1__el-14-b {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  #chapter-1 #section-6 #chapter-1__el-14-a,
  #chapter-1 #section-6 #chapter-1__el-14-b {
    margin-top: 10rem;
  }
}

#chapter-1 #chapter-1__el-1 {
  text-align: right;
  margin-bottom: 10rem;
}

@media (min-width: 768px) {
  #chapter-1 #chapter-1__el-1 {
    text-align: center;
  }
}

#chapter-1 #chapter-1__el-1 > img {
  max-width: 15.5rem;
}

#chapter-1 #chapter-1__el-3 {
  margin-top: 3rem;
}

@media (min-width: 992px) {
  #chapter-1 #chapter-1__el-3 {
    left: -2rem;
  }
}

@media (min-width: 1500px) {
  #chapter-1 #chapter-1__el-3 {
    left: -10rem;
  }
}

#chapter-1 #chapter-1__el-3 > img {
  max-width: 22.4rem;
}

#chapter-1 #chapter-1__el-4 {
  margin: 8rem auto 0 auto;
  right: -7rem;
  position: relative;
  max-width: 15rem;
}

@media (min-width: 375px) {
  #chapter-1 #chapter-1__el-4 {
    right: -10rem;
  }
}

@media (min-width: 414px) {
  #chapter-1 #chapter-1__el-4 {
    right: -12rem;
  }
}

@media (min-width: 768px) {
  #chapter-1 #chapter-1__el-4 {
    right: 0;
  }
}

@media (min-width: 992px) {
  #chapter-1 #chapter-1__el-4 {
    max-width: 25rem;
  }
}

#chapter-1 #chapter-1__el-4 img {
  width: 100%;
}

#chapter-1 #chapter-1__el-4 .l-1,
#chapter-1 #chapter-1__el-4 .l-3 {
  position: absolute;
}

#chapter-1 #chapter-1__el-4 .l-1 {
  z-index: 1;
  width: 5rem;
  top: 2rem;
  left: -4rem;
}

@media (min-width: 992px) {
  #chapter-1 #chapter-1__el-4 .l-1 {
    top: 4rem;
    width: 7rem;
    left: -5rem;
  }
}

#chapter-1 #chapter-1__el-4 .l-2 {
  z-index: 2;
}

#chapter-1 #chapter-1__el-4 .l-3 {
  z-index: 3;
  width: 12rem;
  bottom: -4rem;
  right: -5rem;
}

@media (min-width: 992px) {
  #chapter-1 #chapter-1__el-4 .l-3 {
    width: 18rem;
    bottom: -6rem;
    right: -6rem;
  }
}

#chapter-2 .chapter-content {
  margin-top: -10%;
}

@media (min-width: 1200px) {
  #chapter-2 .chapter-content {
    margin-top: -20%;
  }
}

#chapter-2 #section-1 {
  z-index: 3;
}

#chapter-2 #section-1 #chapter-2__el-1 {
  max-width: 16rem;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  #chapter-2 #section-1 #chapter-2__el-1 {
    max-width: 20rem;
  }
}

@media (min-width: 1500px) {
  #chapter-2 #section-1 #chapter-2__el-1 {
    max-width: 26rem;
    margin: 0 auto 5rem auto;
  }
}

#chapter-2 #section-1 #chapter-2__el-3 {
  max-width: 16rem;
  margin: 3rem auto;
}

@media (min-width: 768px) {
  #chapter-2 #section-1 #chapter-2__el-3 {
    max-width: 20rem;
  }
}

@media (min-width: 992px) {
  #chapter-2 #section-1 #chapter-2__el-3 {
    max-width: 26rem;
    margin: 3rem 0 0 0;
  }
}

@media (min-width: 1200px) {
  #chapter-2 #section-1 #chapter-2__el-3 {
    max-width: 32rem;
    margin: 3rem 0 0 0;
  }
}

@media (min-width: 1500px) {
  #chapter-2 #section-1 #chapter-2__el-3 {
    margin: 5rem 0 0 -10rem;
  }
}

@media (min-width: 768px) {
  #chapter-2 #section-1 #chapter-2__el-5-6 {
    margin-top: -16rem;
  }
}

@media (min-width: 1200px) {
  #chapter-2 #section-1 #chapter-2__el-5-6 {
    margin-top: -30rem;
  }
}

#chapter-2 #section-2 {
  z-index: 2;
}

@media (min-width: 768px) {
  #chapter-2 #section-2 {
    padding-top: 8rem;
  }
}

@media (min-width: 1200px) {
  #chapter-2 #section-2 .section__content {
    position: absolute;
    top: 10rem;
    left: 0;
    width: 100%;
  }
}

#chapter-2 #section-2 #chapter-2__el-7-8 {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 576px) {
  #chapter-2 #section-2 #chapter-2__el-7-8 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#chapter-2 #section-2 #chapter-2__el-7 {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  #chapter-2 #section-2 #chapter-2__el-7 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-2 #section-2 #chapter-2__el-8 {
    padding-top: 10rem;
  }
}

@media (min-width: 1500px) {
  #chapter-2 #section-2 #chapter-2__el-8 {
    padding-top: 30rem;
  }
}

@media (min-width: 992px) {
  #chapter-2 #section-2 #chapter-2__el-9 {
    max-width: 60rem;
  }
}

@media (min-width: 1200px) {
  #chapter-2 #section-2 #chapter-2__el-9 {
    margin-top: 0;
    position: absolute;
    left: 0;
    max-width: 70rem;
    bottom: 0;
  }
}

@media (min-width: 1500px) {
  #chapter-2 #section-2 #chapter-2__el-9 {
    max-width: 50%;
    bottom: 15%;
  }
}

@media (min-width: 2000px) {
  #chapter-2 #section-2 #chapter-2__el-9 {
    bottom: 20%;
  }
}

@media (min-width: 2300px) {
  #chapter-2 #section-2 #chapter-2__el-9 {
    bottom: 30%;
    max-width: 60%;
  }
}

#chapter-2 #section-2 #chapter-2__el-9 .text--caption {
  text-align: center;
  padding-left: 1.5rem;
}

@media (min-width: 768px) {
  #chapter-2 #section-2 #chapter-2__el-9 .text--caption {
    text-align: left;
  }
}

#chapter-2 #section-3 {
  z-index: 3;
}

@media (min-width: 1500px) {
  #chapter-2 #section-3 {
    margin-top: -13rem;
  }
}

@media (min-width: 768px) {
  #chapter-2 #section-3 .section__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@media (min-width: 1500px) {
  #chapter-2 #section-3 .section__content {
    top: -10rem;
  }
}

@media (min-width: 768px) {
  #chapter-2 #section-3 .section__bg {
    margin-top: 20rem;
  }
}

@media (min-width: 1500px) {
  #chapter-2 #section-3 .section__bg {
    margin-top: 0;
  }
}

#chapter-2 #section-3 .section__bg .text--caption {
  position: absolute;
  bottom: 10%;
  right: 15px;
  color: #fff;
}

@media (min-width: 768px) {
  #chapter-2 #section-3 #chapter-2__el-10 {
    padding-top: 12rem;
  }
}

@media (min-width: 1500px) {
  #chapter-2 #section-3 #chapter-2__el-10 {
    padding-top: 22rem;
  }
}

#chapter-2 #section-3 #chapter-2__el-11 {
  max-width: 18rem;
  margin: 2rem auto 4rem auto;
  text-align: right;
}

@media (min-width: 768px) {
  #chapter-2 #section-3 #chapter-2__el-11 {
    max-width: 25rem;
    text-align: left;
    margin-top: 0;
  }
}

@media (min-width: 1500px) {
  #chapter-2 #section-3 #chapter-2__el-11 {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  #chapter-2 #section-3 #chapter-2__el-12 {
    margin-top: -10rem;
  }
}

@media (min-width: 992px) {
  #chapter-2 #section-3 #chapter-2__el-12 {
    margin-top: -6rem;
  }
}

@media (min-width: 1500px) {
  #chapter-2 #section-3 #chapter-2__el-12 {
    margin-top: -19rem;
  }
}

@media (min-width: 768px) {
  #chapter-2 #section-4 {
    margin-top: -14rem;
    z-index: 4;
  }
}

@media (min-width: 992px) {
  #chapter-2 #section-4 {
    margin-top: -18rem;
  }
}

@media (min-width: 1200px) {
  #chapter-2 #section-4 {
    margin-top: -40rem;
  }
}

@media (min-width: 1360px) {
  #chapter-2 #section-4 {
    margin-top: -57rem;
    max-width: 75%;
  }
}

@media (min-width: 1800px) {
  #chapter-2 #section-4 {
    margin-top: -82rem;
  }
}

#chapter-2 #section-4 .col-12:first-child {
  max-width: 72rem;
}

#chapter-2 #section-4 #chapter-2__el-13 {
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-left: -15px;
}

@media (min-width: 768px) {
  #chapter-2 #section-4 #chapter-2__el-13 {
    margin-bottom: 0;
    margin-top: 0;
  }
}

#chapter-2 #section-4 #chapter-2__el-14 {
  text-align: right;
}

@media (min-width: 768px) {
  #chapter-2 #section-4 #chapter-2__el-14 {
    text-align: left;
  }
}

#chapter-2 #section-5 {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  #chapter-2 #section-5 {
    padding-left: 20%;
  }
}

@media (min-width: 1360px) {
  #chapter-2 #section-5 {
    padding-left: 25%;
  }
}

@media (min-width: 1800px) {
  #chapter-2 #section-5 {
    padding-left: 32%;
  }
}

@media (min-width: 768px) {
  #chapter-2 #section-5 #chapter-2__el-16 {
    position: relative;
    right: -15px;
  }
}

@media (min-width: 768px) {
  #chapter-2 #section-5 #chapter-2__el-17 {
    padding-right: 5rem;
  }
}

@media (min-width: 992px) {
  #chapter-2 #section-5 #chapter-2__el-17 {
    padding-left: 30px;
  }
}

@media (min-width: 768px) {
  #chapter-2 #section-5 #chapter-2__el-18 {
    margin-top: 4rem;
    text-align: center;
  }
}

@media (min-width: 1200px) {
  #chapter-2 #section-5 #chapter-2__el-18 {
    text-align: left;
    padding-left: 30px;
  }
}

#chapter-2 #section-5 #chapter-2__el-18 > img {
  max-width: 20rem;
}

@media (min-width: 1200px) {
  #chapter-2 #section-5 #chapter-2__el-18 > img {
    max-width: 28rem;
  }
}

#chapter-2 #section-5 #chapter-2__el-19 {
  position: relative;
  right: -7rem;
}

#chapter-2 #section-5 #chapter-2__el-19 > img {
  max-width: 38.4rem;
}

@media (min-width: 768px) {
  #chapter-2 #section-5 #chapter-2__el-19 > img {
    max-width: 100%;
  }
}

#chapter-3 .chapter-content {
  margin-top: -100%;
}

@media (min-width: 768px) {
  #chapter-3 .chapter-content {
    margin-top: -70%;
  }
}

@media (min-width: 992px) {
  #chapter-3 .chapter-content {
    margin-top: -40%;
  }
}

@media (min-width: 1200px) {
  #chapter-3 .chapter-content {
    margin-top: -20%;
  }
}

@media (min-width: 1500px) {
  #chapter-3 .chapter-content {
    margin-top: -30%;
  }
}

#chapter-3 #section-1 #chapter-3__el-1 {
  top: 4rem;
  z-index: 2;
}

@media (min-width: 768px) {
  #chapter-3 #section-1 #chapter-3__el-1 {
    margin-left: 2rem;
  }
}

#chapter-3 #section-1 #chapter-3__el-1 > img {
  max-width: 13rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-1 #chapter-3__el-1 > img {
    max-width: 20rem;
  }
}

@media (min-width: 1200px) {
  #chapter-3 #section-1 #chapter-3__el-1 > img {
    max-width: 30rem;
  }
}

#chapter-3 #section-1 #chapter-3__el-2 {
  margin-top: -2rem;
  margin-right: 2rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-1 #chapter-3__el-2 {
    margin-top: -5rem;
    margin-right: 4rem;
  }
}

@media (min-width: 1200px) {
  #chapter-3 #section-1 #chapter-3__el-2 {
    margin-top: -15rem;
  }
}

#chapter-3 #section-1 #chapter-3__el-2 > img {
  max-width: 8rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-1 #chapter-3__el-2 > img {
    max-width: 12rem;
  }
}

@media (min-width: 1200px) {
  #chapter-3 #section-1 #chapter-3__el-2 > img {
    max-width: 16rem;
  }
}

#chapter-3 #section-2 {
  margin-top: 10rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-2 {
    margin-top: 25rem;
  }
}

#chapter-3 #section-2 .section__bg {
  position: static;
  margin-top: 6rem;
}

@media (min-width: 1200px) {
  #chapter-3 #section-2 .section__bg {
    text-align: right;
    margin-top: -12%;
  }
  #chapter-3 #section-2 .section__bg > img {
    max-width: 60%;
  }
}

@media (min-width: 1500px) {
  #chapter-3 #section-2 .section__bg {
    margin-top: -9%;
  }
  #chapter-3 #section-2 .section__bg > img {
    max-width: 58%;
  }
}

@media (min-width: 2000px) {
  #chapter-3 #section-2 .section__bg {
    margin-top: -7%;
  }
  #chapter-3 #section-2 .section__bg > img {
    max-width: 56%;
  }
}

@media (min-width: 1200px) {
  #chapter-3 #section-2 #chapter-3__el-3 {
    max-width: 45rem;
  }
}

@media (min-width: 1200px) {
  #chapter-3 #section-2 #chapter-3__el-4 {
    position: relative;
    left: 8rem;
    margin-top: 10rem;
  }
}

#chapter-3 #section-3 {
  max-height: 1645px;
  overflow: hidden;
}

@media (min-width: 768px) {
  #chapter-3 #section-3 {
    padding-bottom: 18rem;
  }
}

@media (min-width: 1024px) {
  #chapter-3 #section-3 {
    padding-bottom: 31rem;
  }
}

@media (min-width: 1200px) {
  #chapter-3 #section-3 {
    padding-bottom: 21rem;
    margin-top: 8rem;
  }
}

@media (min-width: 1500px) {
  #chapter-3 #section-3 {
    padding-bottom: 0;
  }
}

#chapter-3 #section-3 .section__bg {
  width: 60%;
}

@media (min-width: 768px) {
  #chapter-3 #section-3 .section__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

#chapter-3 #section-3 #chapter-3__el-5-6 {
  padding-top: 8rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-3 #chapter-3__el-5-6 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-3 #section-3 #chapter-3__el-5 {
    padding-right: 2rem;
  }
}

@media (min-width: 1500px) {
  #chapter-3 #section-3 #chapter-3__el-5 {
    padding-right: 5rem;
  }
}

#chapter-3 #section-3 #chapter-3__el-6 {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-3 #chapter-3__el-6 {
    margin: 0;
    position: absolute;
    top: 8rem;
    left: 0;
    max-width: 50%;
  }
}

@media (min-width: 1500px) {
  #chapter-3 #section-3 #chapter-3__el-6 {
    max-width: 80rem;
  }
}

#chapter-3 #section-3 #chapter-3__el-7-8 {
  margin-top: 4rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-3 #chapter-3__el-7-8 {
    margin-top: 15%;
  }
}

@media (min-width: 768px) {
  #chapter-3 #section-3 #chapter-3__el-8 {
    width: 26rem;
    margin: 0;
  }
}

#chapter-3 #section-3 #chapter-3__el-9 {
  margin-top: 4rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-3 #chapter-3__el-9 {
    margin-top: 0;
  }
}

#chapter-3 #section-4 {
  margin-top: 4rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-4 {
    margin-top: 0;
  }
}

#chapter-3 #section-4 #chapter-3__el-11 {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 768px) {
  #chapter-3 #section-4 #chapter-3__el-11 {
    margin: 0;
  }
}

@media (min-width: 768px) {
  #chapter-3 #section-4 #chapter-3__el-12-13 {
    margin-top: -5rem;
    position: relative;
    z-index: 4;
  }
}

@media (min-width: 768px) {
  #chapter-3 #section-4 #chapter-3__el-12 {
    margin: 0;
    position: relative;
    left: -4rem;
  }
}

#chapter-3 #section-4 #chapter-3__el-13 {
  text-align: center;
  margin-top: 4rem;
  position: relative;
}

@media (min-width: 768px) {
  #chapter-3 #section-4 #chapter-3__el-13 {
    margin: 0;
  }
}

@media (min-width: 992px) {
  #chapter-3 #section-4 #chapter-3__el-13 {
    left: -10rem;
  }
}

#chapter-3 #section-4 #chapter-3__el-13 > img {
  max-width: 20rem;
}

@media (min-width: 768px) {
  #chapter-3 #section-4 #chapter-3__el-13 > img {
    max-width: 35rem;
  }
}

@media (min-width: 992px) {
  #chapter-3 #section-4 #chapter-3__el-13 > img {
    max-width: 40rem;
  }
}

@media (min-width: 1200px) {
  #chapter-3 #section-4 #chapter-3__el-13 > img {
    max-width: 50rem;
  }
}

#chapter-4 .chapter-content {
  margin-top: 140%;
}

@media (min-width: 768px) {
  #chapter-4 .chapter-content {
    margin-top: 35rem;
  }
}

@media (min-width: 1024px) {
  #chapter-4 .chapter-content {
    margin-top: 22rem;
  }
}

@media (min-width: 1200px) {
  #chapter-4 .chapter-content {
    margin-top: 18rem;
  }
}

@media (min-width: 1400px) {
  #chapter-4 .chapter-content {
    margin-top: 0;
  }
}

@media (min-width: 1500px) {
  #chapter-4 .chapter-content {
    margin-top: 11%;
  }
}

@media (min-width: 2300px) {
  #chapter-4 .chapter-content {
    margin-top: -5%;
  }
}

#chapter-4 #section-1 {
  margin-top: -5rem;
}

@media (min-width: 992px) {
  #chapter-4 #section-1 {
    margin-top: 35rem;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-1 {
    margin-top: -5rem;
  }
}

#chapter-4 #section-1 #chapter-4__el-1 {
  text-align: right;
  padding-right: 11rem;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  #chapter-4 #section-1 #chapter-4__el-1 {
    text-align: left;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-1 #chapter-4__el-1 {
    padding-left: 5rem;
    margin-bottom: 8.5rem;
  }
}

#chapter-4 #section-1 #chapter-4__el-1 img {
  max-width: 10rem;
}

@media (min-width: 1200px) {
  #chapter-4 #section-1 #chapter-4__el-1 img {
    max-width: 13rem;
  }
}

#chapter-4 #section-1 #chapter-4__el-2 {
  text-align: right;
}

@media (min-width: 768px) {
  #chapter-4 #section-1 #chapter-4__el-2 {
    text-align: left;
  }
}

#chapter-4 #section-1 #chapter-4__el-2 img {
  max-width: 15rem;
}

@media (min-width: 1200px) {
  #chapter-4 #section-1 #chapter-4__el-2 img {
    max-width: 22rem;
  }
}

#chapter-4 #section-1 #chapter-4__el-3 {
  margin-top: 4rem;
  max-width: 45rem;
}

@media (min-width: 768px) {
  #chapter-4 #section-1 #chapter-4__el-3 {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-1 #chapter-4__el-3 {
    margin-bottom: 8.5rem;
  }
}

@media (min-width: 768px) {
  #chapter-4 #section-3 .section__bg {
    margin-top: -20rem;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-3 .section__bg {
    margin-top: 0;
  }
}

#chapter-4 #section-3 .section__content--top {
  z-index: 2;
  position: relative;
}

@media (min-width: 1200px) {
  #chapter-4 #section-3 .section__content--top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

@media (min-width: 768px) {
  #chapter-4 #section-3 .section__content--bottom {
    position: absolute;
    left: 0;
    bottom: 10%;
    width: 100%;
    z-index: 2;
  }
  #chapter-4 #section-3 .section__content--bottom * {
    color: #fff;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-3 .section__content--bottom {
    bottom: 20%;
  }
}

#chapter-4 #section-3 .badge {
  width: 100%;
  margin: 0 auto 3rem auto;
}

@media (min-width: 768px) {
  #chapter-4 #section-3 .badge .badge__content p {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  #chapter-4 #section-3 .quote-minimal {
    text-align: right;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-3 .quote-minimal {
    position: relative;
    left: -10rem;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-3 .quote-minimal .quote__icon {
    margin-top: -23rem;
  }
}

#chapter-4 #section-3 .quote-minimal .quote__inner {
  margin-top: -7rem;
}

@media (min-width: 768px) {
  #chapter-4 #section-3 .quote-minimal .quote__inner {
    margin-top: -16rem;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-3 .quote-minimal .quote__inner {
    position: relative;
    right: -4rem;
  }
}

#chapter-4 #section-3 #chapter-4__el-7 {
  margin-top: 6rem;
}

@media (min-width: 1200px) {
  #chapter-4 #section-4 {
    margin-bottom: 20rem;
  }
}

@media (min-width: 768px) {
  #chapter-4 #section-4 #chapter-4__el-8 {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-4 .number-scroll-container {
    position: relative;
    left: -8rem;
  }
}

#chapter-4 #section-4 #chapter-4__el-14 {
  margin-bottom: 5rem;
}

@media (min-width: 768px) {
  #chapter-4 #section-4 #chapter-4__el-14 {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-5 .owl-carousel--split-primary-container {
    top: -15rem;
  }
}

#chapter-4 #section-5 .owl-carousel--split-off-screen.owl-carousel--split-right {
  bottom: -100%;
}

@media (min-width: 1200px) {
  #chapter-4 #section-5 .owl-carousel--split-off-screen.owl-carousel--split-right {
    bottom: 0;
  }
}

#chapter-4 #section-6 #chapter-4__el-19,
#chapter-4 #section-6 #chapter-4__el-20 {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #chapter-4 #section-6 #chapter-4__el-19,
  #chapter-4 #section-6 #chapter-4__el-20 {
    padding-left: 0;
    padding-right: 0;
  }
}

#chapter-4 #section-6 #chapter-4__el-19 {
  max-width: 43rem;
}

@media (min-width: 1200px) {
  #chapter-4 #section-6 #chapter-4__el-19 {
    margin-bottom: 15rem;
  }
}

@media (min-width: 768px) {
  #chapter-4 #section-6 #chapter-4__el-21 {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 58%;
    margin-bottom: 0;
    z-index: 2;
  }
}

@media (min-width: 1200px) {
  #chapter-4 #section-6 #chapter-4__el-21 {
    bottom: 7%;
    max-width: 59%;
  }
}

@media (min-width: 1500px) {
  #chapter-4 #section-6 #chapter-4__el-21 {
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  #chapter-4 #section-7 {
    margin-top: 4rem;
  }
}

@media (min-width: 768px) {
  #chapter-4 #section-7 .row {
    position: relative;
    right: -15px;
  }
}

@media (min-width: 768px) {
  #chapter-4 #section-7 #chapter-4__el-21 {
    margin-top: 0;
  }
}

#chapter-5 .chapter-content {
  margin-top: -15%;
}

@media (min-width: 375px) {
  #chapter-5 .chapter-content {
    margin-top: -35%;
  }
}

@media (min-width: 414px) {
  #chapter-5 .chapter-content {
    margin-top: -15%;
  }
}

@media (min-width: 768px) {
  #chapter-5 .chapter-content {
    margin-top: -30%;
  }
}

@media (min-width: 1024px) {
  #chapter-5 .chapter-content {
    margin-top: -20%;
  }
}

@media (min-width: 1200px) {
  #chapter-5 .chapter-content {
    margin-top: -30%;
  }
}

@media (min-width: 2000px) {
  #chapter-5 .chapter-content {
    margin-top: -20%;
  }
}

@media (min-width: 375px) {
  #chapter-5 #section-1 {
    margin-top: 42rem;
  }
}

@media (min-width: 768px) {
  #chapter-5 #section-1 {
    margin-top: -10rem;
  }
}

@media (min-width: 992px) {
  #chapter-5 #section-1 {
    margin-top: 5rem;
  }
}

@media (min-width: 1200px) {
  #chapter-5 #section-1 {
    margin-top: -10rem;
  }
}

#chapter-5 #section-1 #chapter-5__el-1 {
  max-width: 45rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  #chapter-5 #section-1 #chapter-5__el-1 {
    margin-top: 10rem;
    margin-bottom: 0;
  }
}

#chapter-5 #section-1 #chapter-5__el-2 {
  text-align: center;
}

#chapter-5 #section-1 #chapter-5__el-2 img {
  max-width: 30rem;
}

@media (min-width: 768px) {
  #chapter-5 #section-1 #chapter-5__el-2 img {
    max-width: 100%;
  }
}

#chapter-5 #section-2 {
  z-index: 2;
  margin-top: -5rem;
}

@media (min-width: 1200px) {
  #chapter-5 #section-2 {
    margin-top: -10%;
  }
}

#chapter-5 #section-3 .section__bg {
  padding-top: 35rem;
}

@media (min-width: 768px) {
  #chapter-5 #section-3 .section__bg {
    padding-top: 22rem;
  }
}

@media (min-width: 1200px) {
  #chapter-5 #section-3 .section__bg {
    padding-top: 24rem;
  }
}

@media (min-width: 1200px) {
  #chapter-5 #section-3 .section__bg .section__bg__tear {
    bottom: 30px;
  }
}

#chapter-5 #section-3 .section__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#chapter-5 #section-3 #chapter-5__el-4 {
  margin-top: 4rem;
}

@media (min-width: 768px) {
  #chapter-5 #section-3 #chapter-5__el-4 {
    margin-top: 0;
  }
}

#chapter-5 #section-4 {
  margin-top: 4.5rem;
}

@media (min-width: 768px) {
  #chapter-5 #section-4 {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-5 #section-4 {
    padding-bottom: 8.5rem;
  }
}

#chapter-5 #section-4 #chapter-5__el-6-wrapper {
  width: 100%;
}

@media (min-width: 768px) {
  #chapter-5 #section-4 #chapter-5__el-6 {
    margin: 0 0 5rem 0;
    float: right;
  }
}

@media (min-width: 1200px) {
  #chapter-5 #section-4 #chapter-5__el-7 .quote__inner {
    padding-left: 26rem;
  }
}

@media (max-width: 575.98px) {
  #chapter-5 #section-4 #chapter-5__el-7 .quote__inner {
    margin-top: -9rem;
    padding-left: 2rem;
    max-width: 25rem;
  }
}

#chapter-5 #section-5 #chapter-5__el-8-wrapper {
  padding-bottom: 4rem;
}

@media (min-width: 768px) {
  #chapter-5 #section-5 #chapter-5__el-8-wrapper {
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-5 #section-5 #chapter-5__el-8-wrapper {
    padding-bottom: 8rem;
  }
}

#chapter-5 #section-5 #chapter-5__el-8 {
  max-width: 39rem;
}

#chapter-5 #section-5 #chapter-5__el-9 {
  position: relative;
  z-index: 2;
}

#chapter-5 #section-6 {
  margin-top: 4rem;
}

@media (min-width: 768px) {
  #chapter-5 #section-6 {
    margin-top: 8.5rem;
  }
}

@media (min-width: 1200px) {
  #chapter-5 #section-6 {
    margin-top: 0;
  }
}

#chapter-5 #section-6 .section__content {
  position: relative;
  z-index: 2;
}

#chapter-5 #section-6 .section__bg {
  margin-top: -10%;
}

@media (min-width: 768px) {
  #chapter-5 #section-6 .section__bg {
    margin-top: -20%;
  }
}

@media (min-width: 2000px) {
  #chapter-5 #section-6 .section__bg {
    margin-top: -30%;
  }
}

#chapter-5 #section-7 #chapter-5__el-11 {
  max-width: 50rem;
}

#chapter-5 #section-7 #chapter-5__el-12 {
  max-width: 45rem;
}

@media (min-width: 768px) {
  #chapter-5 #section-7 #chapter-5__el-12 {
    margin-top: 20rem;
  }
}

#chapter-6 .chapter-content {
  margin-top: 15rem;
}

@media (min-width: 375px) {
  #chapter-6 .chapter-content {
    margin-top: 24rem;
  }
}

@media (min-width: 768px) {
  #chapter-6 .chapter-content {
    margin-top: 28rem;
  }
}

@media (min-width: 992px) {
  #chapter-6 .chapter-content {
    margin-top: 35rem;
  }
}

@media (min-width: 1500px) {
  #chapter-6 .chapter-content {
    margin-top: 28rem;
  }
}

#chapter-6 #section-1 {
  z-index: 2;
}

#chapter-6 #section-1 #chapter-6__el-2 {
  margin-top: -5rem;
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  #chapter-6 #section-1 #chapter-6__el-2 .quote__inner {
    position: relative;
    left: -32rem;
    text-align: right;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  #chapter-6 #section-1 #chapter-6__el-2 p.quote__statement {
    position: relative;
    top: -4rem;
  }
}

#chapter-6 #section-1 #chapter-6__el-3 .text--caption {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #chapter-6 #section-1 #chapter-6__el-3 .text--caption {
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  #chapter-6 #section-1 #chapter-6__el-3 {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
}

@media (min-width: 1500px) {
  #chapter-6 #section-1 #chapter-6__el-3 {
    max-width: 45%;
  }
}

@media (min-width: 768px) {
  #chapter-6 #section-2 {
    margin-bottom: 4rem;
    margin-top: -4rem;
  }
}

@media (min-width: 992px) {
  #chapter-6 #section-2 {
    margin-top: 4rem;
  }
}

@media (min-width: 1200px) {
  #chapter-6 #section-2 {
    margin-top: 0;
    margin-bottom: 8.5rem;
  }
}

@media (min-width: 1200px) {
  #chapter-6 #section-2 .container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  #chapter-6 #section-2 #chapter-6__el-4 {
    position: relative;
    top: -13rem;
  }
}

#chapter-6 #section-2 #chapter-6__el-5 {
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  #chapter-6 #section-2 #chapter-6__el-5 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

#chapter-6 #section-2 #chapter-6__el-5 .text--caption {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #chapter-6 #section-2 #chapter-6__el-5 .text--caption {
    padding-right: 0;
    padding-left: 0;
  }
}

#chapter-6 #section-3 {
  z-index: 1;
}

@media (min-width: 768px) {
  #chapter-6 #section-3 {
    margin-top: -15%;
  }
}

@media (min-width: 2000px) {
  #chapter-6 #section-3 {
    margin-top: -18%;
  }
}

#chapter-6 #section-3 .row {
  position: relative;
  z-index: 1;
}

#chapter-6 #section-4 {
  position: relative;
  z-index: 2;
}

#chapter-6 #section-4 #chapter-6__el-11 {
  margin-top: -5rem;
}

@media (min-width: 1200px) {
  #chapter-6 #section-4 #chapter-6__el-11 {
    margin-top: -7rem;
  }
}

#chapter-6 #section-4 #chapter-6__el-12 {
  padding-right: 8%;
}

#chapter-6 #section-4 #chapter-6__el-12-a {
  position: relative;
}

@media (min-width: 768px) {
  #chapter-6 #section-4 #chapter-6__el-12-a {
    top: 10rem;
  }
}

@media (min-width: 1200px) {
  #chapter-6 #section-4 #chapter-6__el-12-a {
    top: 20rem;
  }
}

@media (min-width: 1500px) {
  #chapter-6 #section-4 #chapter-6__el-12-a {
    top: 30rem;
  }
}

@media (min-width: 768px) {
  #chapter-6 #section-5 {
    margin-top: 2rem;
  }
}

@media (min-width: 768px) {
  #chapter-6 #section-5 #chapter-6__el-14 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 992px) {
  #chapter-6 #section-6 #chapter-6__el-16 {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  #chapter-6 #section-7 {
    margin-top: -20rem;
  }
}

@media (min-width: 768px) {
  #chapter-6 #section-7 .sticky-block {
    margin-top: 0;
  }
}

#chapter-6 #section-8 .section__bg__tear {
  bottom: 40px;
}

#chapter-6 #section-8 .section__bg__tear.section__bg__tear--before {
  top: 10px;
}

@media (min-width: 768px) {
  #chapter-6 #section-9 #chapter-6__el-23-a {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
}

@media (min-width: 1500px) {
  #chapter-6 #section-9 #chapter-6__el-23-a {
    max-width: 42%;
  }
}

@media (min-width: 2300px) {
  #chapter-6 #section-9 #chapter-6__el-23-a {
    max-width: 38%;
  }
}

#chapter-6 #section-9 #chapter-6__el-23-a .text--caption {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #chapter-6 #section-9 #chapter-6__el-23-a .text--caption {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1500px) {
  #chapter-6 #section-11 {
    margin-bottom: 12rem;
  }
}

@media (min-width: 768px) {
  #chapter-6 #section-11 .el {
    margin-top: 0;
  }
}

#chapter-7 .chapter-content {
  margin-top: -30%;
}

@media (min-width: 1200px) {
  #chapter-7 .chapter-content {
    margin-top: -20%;
  }
}

@media (min-width: 1400px) {
  #chapter-7 .chapter-content {
    margin-top: -10%;
  }
}

@media (min-width: 768px) {
  #chapter-7 #section-1 #chapter-7__el-2 {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 45%;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
}

@media (min-width: 1500px) {
  #chapter-7 #section-1 #chapter-7__el-2 {
    max-width: 40%;
  }
}

@media (min-width: 1800px) {
  #chapter-7 #section-1 #chapter-7__el-2 {
    max-width: 43%;
  }
}

@media (min-width: 1500px) {
  #chapter-7 #section-2 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
}

@media (min-width: 1800px) {
  #chapter-7 #section-2 {
    margin-top: 25rem;
    margin-bottom: 25rem;
  }
}

@media (min-width: 768px) {
  #chapter-7 #section-2 #chapter-7__el-4 {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 45%;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
}

@media (min-width: 1500px) {
  #chapter-7 #section-2 #chapter-7__el-4 {
    max-width: 40%;
  }
}

@media (min-width: 1800px) {
  #chapter-7 #section-2 #chapter-7__el-4 {
    max-width: 43%;
  }
}

#chapter-7 #section-2 #chapter-7__el-4 .text--caption {
  padding-left: 15px;
}

#chapter-7 #section-3 #chapter-7__el-5 {
  padding-bottom: 11rem;
  position: relative;
}

@media (min-width: 768px) {
  #chapter-7 #section-3 #chapter-7__el-5 {
    margin-left: -15px;
  }
}

@media (min-width: 992px) {
  #chapter-7 #section-3 #chapter-7__el-5 {
    padding-bottom: 20rem;
  }
}

@media (min-width: 1500px) {
  #chapter-7 #section-3 #chapter-7__el-5 {
    max-width: 105rem;
    padding-bottom: 25rem;
  }
}

#chapter-7 #section-3 #chapter-7__el-5 img {
  width: 100%;
}

#chapter-7 #section-3 #chapter-7__el-5 .l-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 20rem;
}

@media (min-width: 768px) {
  #chapter-7 #section-3 #chapter-7__el-5 .l-2 {
    max-width: 25rem;
  }
}

@media (min-width: 992px) {
  #chapter-7 #section-3 #chapter-7__el-5 .l-2 {
    max-width: 35rem;
  }
}

@media (min-width: 1200px) {
  #chapter-7 #section-3 #chapter-7__el-5 .l-2 {
    max-width: 45rem;
  }
}

@media (min-width: 1500px) {
  #chapter-7 #section-3 #chapter-7__el-5 .l-2 {
    max-width: 55rem;
    right: -13rem;
  }
}

@media (min-width: 768px) {
  #chapter-7 #section-3 #chapter-7__el-5 .text--caption {
    padding-left: 15px;
  }
}

#chapter-7 #section-3 #chapter-7__el-6 {
  margin-top: -9rem;
  max-width: 20rem;
}

@media (min-width: 375px) {
  #chapter-7 #section-3 #chapter-7__el-6 {
    max-width: 26rem;
  }
}

@media (min-width: 414px) {
  #chapter-7 #section-3 #chapter-7__el-6 {
    min-width: 28rem;
  }
}

@media (min-width: 768px) {
  #chapter-7 #section-3 #chapter-7__el-6 {
    margin-top: 0;
    max-width: 39.6rem;
  }
}

@media (min-width: 768px) {
  #chapter-7 #section-4 {
    margin-top: 8.5rem;
  }
}

@media (min-width: 1500px) {
  #chapter-7 #section-4 {
    margin-top: 12rem;
  }
}

#chapter-7 #section-4 #chapter-7__el-7 {
  margin-top: 4rem;
}

@media (min-width: 768px) {
  #chapter-7 #section-4 #chapter-7__el-7 {
    margin-top: 0;
    margin-right: -15px;
  }
}

@media (max-width: 575.98px) {
  #chapter-7 #section-4 #chapter-7__el-8 {
    padding: 0;
    margin-top: 4rem;
  }
}

@media (min-width: 1200px) {
  #chapter-7 #section-4 #chapter-7__el-8 {
    position: absolute;
    top: 0;
    left: -7rem;
    max-width: 40rem;
    width: 100%;
    padding: 2rem;
  }
}

@media (min-width: 1500px) {
  #chapter-7 #section-4 #chapter-7__el-8 {
    left: -12rem;
    max-width: 50rem;
  }
}

@media (min-width: 1800px) {
  #chapter-7 #section-4 #chapter-7__el-8 {
    left: -22rem;
  }
}

#chapter-7 #section-4 #chapter-7__el-7-a {
  position: relative;
  z-index: 2;
  margin-top: -50%;
  max-width: 15rem;
}

#chapter-7 #section-4 #chapter-7__el-9 {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 15px;
  max-width: 15rem;
}

@media (min-width: 768px) {
  #chapter-7 #section-4 #chapter-7__el-9 {
    margin-right: 0;
    max-width: 22rem;
  }
}

@media (min-width: 1200px) {
  #chapter-7 #section-4 #chapter-7__el-9 {
    max-width: 35rem;
    margin-top: 0;
  }
}

#chapter-7 #section-5 #chapter-7__el-10 {
  margin-bottom: 4rem;
  position: relative;
  z-index: 2;
}

@media (max-width: 575.98px) {
  #chapter-7 #section-5 #chapter-7__el-10 {
    padding: 0;
  }
}

@media (min-width: 768px) {
  #chapter-7 #section-5 #chapter-7__el-10 {
    margin-bottom: -7rem;
  }
}

@media (min-width: 1200px) {
  #chapter-7 #section-5 #chapter-7__el-10 {
    margin-bottom: -12rem;
  }
}

#chapter-7 #section-5 #chapter-7__el-10 p {
  margin-bottom: 03;
}

@media (min-width: 768px) {
  #chapter-7 #section-6 {
    margin-top: 4rem;
  }
}

@media (min-width: 1200px) {
  #chapter-7 #section-6 {
    margin-top: 8.5rem;
  }
}

#chapter-7 #section-6 .owl-carousel--split-primary-container {
  top: 0;
}

@media (min-width: 1200px) {
  #chapter-7 #section-6 .owl-carousel--split-off-screen {
    bottom: 50%;
    transform: translateY(50%);
  }
}

#chapter-7 #section-6 #chapter-7__el-13 {
  margin: 4rem 0;
  max-width: 39rem;
}

@media (min-width: 768px) {
  #chapter-7 #section-6 #chapter-7__el-13 {
    margin: 0;
  }
}

#chapter-7 #section-6 #chapter-7__el-14 {
  margin-top: -6rem;
}

@media (min-width: 992px) {
  #chapter-7 #section-6 #chapter-7__el-14 {
    position: relative;
    left: 10rem;
  }
}

@media (min-width: 1200px) {
  #chapter-7 #section-6 #chapter-7__el-14 {
    margin-top: -10rem;
    max-width: 59rem;
    left: 0;
  }
}

#chapter-7 #section-6 #chapter-7__el-14 .quote__inner {
  margin-top: -10rem;
  padding-left: 1rem;
}

@media (min-width: 1200px) {
  #chapter-7 #section-6 #chapter-7__el-14 .quote__inner {
    margin-top: -12rem;
    padding-left: 0;
    position: relative;
    left: -15rem;
  }
}

@media (min-width: 1200px) {
  #chapter-7 #section-6 #chapter-7__el-14 .quote__inner {
    left: -25rem;
  }
}

#chapter-7 #section-6 #chapter-7__el-14 .quote__statement {
  font-size: 1.6rem;
  line-height: 2.5rem;
}

@media (min-width: 1200px) {
  #chapter-7 #section-6 #chapter-7__el-14 .quote__statement {
    font-size: 3rem;
    line-height: 4rem;
  }
}

#chapter-7 #section-7 #chapter-7__el-15 {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  #chapter-7 #section-7 #chapter-7__el-15 {
    margin-bottom: 0;
  }
}

#chapter-7 #section-8 #chapter-7__el-16 {
  margin-top: 2rem;
  margin-bottom: 8rem;
}

@media (min-width: 768px) {
  #chapter-7 #section-8 #chapter-7__el-16 {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  #chapter-7 #section-8 #chapter-7__el-16 {
    margin-top: 4rem;
  }
}

@media (min-width: 768px) {
  #chapter-7 #section-8 #chapter-7__el-17 {
    margin-top: 5rem;
  }
}

@media (min-width: 992px) {
  #chapter-7 #section-8 #chapter-7__el-17 {
    margin-top: 10rem;
  }
}

@media (min-width: 1200px) {
  #chapter-7 #section-8 #chapter-7__el-17 {
    max-width: 85% !important;
  }
}

#chapter-7 #section-8 #chapter-7__el-17 span.type-in {
  font-family: "DharmaGothicCW01";
  font-weight: 800;
  color: #008AAB;
  text-transform: uppercase;
  font-size: 6rem;
  line-height: 5rem;
  letter-spacing: 0.9px;
}

@media (min-width: 992px) {
  #chapter-7 #section-8 #chapter-7__el-17 span.type-in {
    font-size: 10rem;
    line-height: 8rem;
    letter-spacing: 1.5px;
  }
}

#chapter-7 #section-9 .section__content {
  position: absolute;
  left: 0;
  bottom: 20%;
  width: 100%;
}

@media (min-width: 1500px) {
  #chapter-7 #section-9 .section__content .container {
    max-width: 1300px;
  }
}

#chapter-7 #section-9 .section__content .btn--back-to-top {
  text-decoration: none;
  position: relative;
  display: inline-block;
}

#chapter-7 #section-9 .section__content .btn--back-to-top span {
  display: inline-block;
  vertical-align: middle;
}

#chapter-7 #section-9 .section__content .btn--back-to-top span.back-to-top__img {
  margin-right: 0.5rem;
}

@media (min-width: 768px) {
  #chapter-7 #section-9 .section__content .btn--back-to-top span.back-to-top__img {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
  }
}

#chapter-7 #section-9 .section__content .btn--back-to-top span.back-to-top__text {
  font-family: "DharmaGothicCW01";
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #808285;
  font-size: 2.1rem;
  line-height: 3.5rem;
  position: relative;
  top: 2px;
}

@media (min-width: 768px) {
  #chapter-7 #section-9 .section__content .btn--back-to-top span.back-to-top__text {
    font-size: 3rem;
    line-height: 3rem;
    top: 4px;
  }
}

@media (min-width: 992px) {
  #chapter-7 #section-9 .section__content .btn--back-to-top span.back-to-top__text {
    top: 11px;
  }
}

@media (min-width: 1200px) {
  #chapter-7 #section-9 .section__content .btn--back-to-top span.back-to-top__text {
    top: 11px;
  }
}

#chapter-8 #section-1 #chapter-8__el-2 br {
  display: none;
}

@media (min-width: 768px) {
  #chapter-8 #section-1 #chapter-8__el-2 br {
    display: inline-block;
  }
}

#chapter-8 #section-1 #chapter-8__el-2 .text--callout {
  text-align: center;
  font-size: 5rem;
  line-height: 4rem;
}

@media (min-width: 768px) {
  #chapter-8 #section-1 #chapter-8__el-2 .text--callout {
    font-size: 6rem;
    line-height: 5rem;
  }
}

@media (min-width: 992px) {
  #chapter-8 #section-1 #chapter-8__el-2 .text--callout {
    font-size: 10rem;
    line-height: 8rem;
  }
}

#chapter-8 #section-1 #chapter-8__el-2 .text--callout.color--black {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  #chapter-8 #section-1 #chapter-8__el-2 .text--callout.color--black {
    text-align: left;
  }
  #chapter-8 #section-1 #chapter-8__el-2 .text--callout.color--primary {
    text-align: right;
  }
}

#chapter-8 #section-1 #chapter-8__el-3 {
  max-width: 45rem;
}

@media (min-width: 768px) {
  #chapter-8 #section-1 #chapter-8__el-7 {
    margin-top: -30%;
  }
}

#chapter-8 #section-1 #chapter-8__el-7 .text--callout.color--white {
  margin-bottom: 0;
}
