#chapter-8 {
	#section-1 {
		#chapter-8__el-2 {

			br {
				display: none;

				@include media-breakpoint-up(md) {
					display: inline-block;
				}
			}

			.text--callout {
				text-align: center;
				font-size: 5rem;
				line-height: 4rem;

				@include media-breakpoint-up(md) {
					font-size: 6rem;
					line-height: 5rem;
				}

				@include media-breakpoint-up(lg) {
					font-size: 10rem;
   					line-height: 8rem;
				}

				&.color--black {
					margin-bottom: 0;
				}

				@include media-breakpoint-up(md) {
					&.color--black {
						text-align: left;
					}

					&.color--primary {
						text-align: right;
					}
				}
			}
		}

		#chapter-8__el-3 {
			max-width: 45rem;
		}

		#chapter-8__el-7 {
			@include media-breakpoint-up(md) {
				margin-top: -30%;
			}

			.text--callout.color--white {
				margin-bottom: 0;
			}
		}
	}
}