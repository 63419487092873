#chapter-5 {

	.chapter-content {
		margin-top: -15%;

		@media(min-width: 375px) {
			margin-top: -35%;
		}

		@media(min-width: 414px) {
			margin-top: -15%;
		}

		@include media-breakpoint-up(md) {
			margin-top: -30%;
		}

		@media(min-width: 1024px) {
			margin-top: -20%;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -30%;
		}

		@include media-breakpoint-up(hg) {
			margin-top: -20%;
		}
	}

	#section-1 {
		@media(min-width: 375px) {
			margin-top: 42rem;
		}
		
		@include media-breakpoint-up(md) {
			margin-top: -10rem;
		}

		@include media-breakpoint-up(lg) {
			margin-top: 5rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -10rem;
		}

		#chapter-5__el-1 {
			max-width: 45rem;
			margin-top: 4rem;
			margin-bottom: 4rem;

			@include media-breakpoint-up(md) {
				margin-top: 10rem;
				margin-bottom: 0;
			}
		}

		#chapter-5__el-2 {
			text-align: center;

			img {
				max-width: 30rem;

				@include media-breakpoint-up(md) {
					max-width: 100%;
				}
			}
		}
	}

	#section-2 {
		z-index: 2;
		margin-top: -5rem;

		@include media-breakpoint-up(xl) {
			margin-top: -10%;
		}
	}

	#section-3 {

		.section__bg {
			padding-top: 35rem;

			@include media-breakpoint-up(md) {
				padding-top: 22rem;
			}

			@include media-breakpoint-up(xl) {
				padding-top: 24rem;
			}

			.section__bg__tear {
				@include media-breakpoint-up(xl) {
					bottom: 30px;
				}
			}
		}

		.section__content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}

		#chapter-5__el-4 {
			margin-top: 4rem;

			@include media-breakpoint-up(md) {
				margin-top: 0;
			}
		}
	}

	#section-4 {
		margin-top: 4.5rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		@include media-breakpoint-up(xl) {
			padding-bottom: 8.5rem;
		}

		#chapter-5__el-6-wrapper {
			width: 100%;
		}

		#chapter-5__el-6 {
			@include media-breakpoint-up(md) {
				margin: 0 0 5rem 0;
				float: right;
			}
		}

		#chapter-5__el-7 {
			.quote__inner {
				@include media-breakpoint-up(xl) {
					padding-left: 26rem;
				}

				@include media-breakpoint-down(xs) {
					margin-top: -9rem;
				    padding-left: 2rem;
				    max-width: 25rem;
				}
			}
		}
	}

	#section-5 {
		#chapter-5__el-8-wrapper {
			padding-bottom: 4rem;

			@include media-breakpoint-up(md) {
				padding-bottom: 0;
			}

			@include media-breakpoint-up(xl) {
				padding-bottom: 8rem;
			}
		}

		#chapter-5__el-8 {
			max-width: 39rem;
		}

		#chapter-5__el-9 {
			position: relative;
			z-index: 2;
		}

		// .section__bg {
		// 	.section__bg__tear {
		// 		@include media-breakpoint-up(xl) {
		// 			bottom: 100px;
		// 		}
		// 	}
		// }
	}

	#section-6 {
		margin-top: 4rem;

		@include media-breakpoint-up(md) {
			margin-top: 8.5rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 0;
		}

		.section__content {
			position: relative;
			z-index: 2;
		}

		.section__bg {
			margin-top: -10%;

			@include media-breakpoint-up(md) {
				margin-top: -20%;
			}

			@include media-breakpoint-up(hg) {
				margin-top: -30%;
			}
		}
	}

	#section-7 {
		#chapter-5__el-11 {
			max-width: 50rem;
		}
		#chapter-5__el-12 {
			max-width: 45rem;
			@include media-breakpoint-up(md) {
				margin-top: 20rem;
			}
		}
	}
}