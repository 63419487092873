.sticky-block {
	.sticky-block__inner {
		@include media-breakpoint-down(xs) {
			position: static !important;
			width: 100% !important;
		}

		& + div {
			display: none !important;
		}
	}
}