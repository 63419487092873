// Fonts
// @import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(/assets/fonts/BrandonGrotesque/stylesheet.css);
@import url(/assets/fonts/DharmaGothic/stylesheet.css);

// Base
@import 'variables';
@import 'bs4-includes';
@import 'custom-mixins';

// Reboot
@import 'reboot';

// Parts
@import 'parts/header';
@import 'parts/footer';

// Components
@import 'components/helpers';
@import 'components/structure';
@import 'components/type';
@import 'components/button';
@import 'components/owl-carousel/owl-carousel';
@import 'components/animations';
@import 'components/elements';
// @import 'components/scroll-bar';
@import 'components/telstra-header';

// Modules
@import 'modules/hero';
@import 'modules/text-block';
@import 'modules/img-block';
@import 'modules/multi-layer-spotlight';
@import 'modules/quote-block';
@import 'modules/badges';
@import 'modules/sticky-block';
@import 'modules/embed';
@import 'modules/number-scroll';
@import 'modules/dual-scroll';
@import 'modules/paroller';

// Chapters
@import 'chapters/chapters';
