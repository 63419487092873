#chapter-1 {

	.chapter-content {
		margin-top: -45%;

		@include media-breakpoint-up(lg) {
			margin-top: -20%;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: -10%;
		}
	}

	#section-3 {
		position: relative;
		z-index: 2;

		.section__bg {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;

			> img {
				width: 100%;
			}
		}
	}

	#section-4 {
		position: relative;
		z-index: 2;
		overflow: hidden;
	}

	#section-5 {
		position: relative;
		z-index: 1;
		// overflow: hidden;
		padding-top: 20rem;

		@include media-breakpoint-up(xl) {
			padding-top: 12rem;
		}

		.container {
			padding-left: 0;
			padding-right: 0;

			@include media-breakpoint-up(sm) {
				padding-left: $grid-gutter-width / 2;
				padding-right: $grid-gutter-width / 2;
			}
		}

		.section__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 200%;
			z-index: 1;

			@include media-breakpoint-up(md) {
				width: 50%;
			}

			> img {
				width: 100%;
			}
		}

		#chapter-1__el-9 {
			z-index: 2;

			img {
				width: 100%;
			}

			.l-1 {
				z-index: 1;
				max-width: 15rem;
			    position: absolute;
			    top: -11.5rem;
			    left: $grid-gutter-width / 2;

			    @include media-breakpoint-up(md) {
			    	max-width: 20rem;
			    	top: -15.5rem;
			    }

			    @include media-breakpoint-up(xl) {
			    	max-width: 25rem;
			    	top: -10.5rem;
	    			left: -23rem;
			    }
			}

			.l-2 {
				z-index: 2;
				margin-right: -$grid-gutter-width / 2;
				margin-left: -$grid-gutter-width / 2;

				.text--caption {
					padding-left: $grid-gutter-width;
					padding-right: $grid-gutter-width;
					text-align: left;

					@include media-breakpoint-up(md) {
						text-align: center;
					}
				}
			}
		}
	}

	#section-6 {
		z-index: 3;

		.container {
			position: relative;
			z-index: 2;
		}

		.section__bg {
			margin-top: -100%;

			@include media-breakpoint-up(md) {
				margin-top: -30%;
			}
		}

		#chapter-1__el-11,
		#chapter-1__el-13 {
			padding-top: 21rem;
			position: relative;

			@include media-breakpoint-up(xl) {
				right: -7rem;
				top: -15rem;
			}

			img {
				width: 100%;
			}


			.l-1 {
			    z-index: 1;
			    position: absolute;
			    top: 4rem;
			    right: 2rem;
			    max-width: 14rem;

			    @media(min-width: 414px) {
			    	top: 5rem;
			    }

			    @include media-breakpoint-up(xl) {
			    	right: -11rem;
			    }
			}

			.l-2 {
				z-index: 2;
			}
		}

		.sticky-block {
			padding-left: 0;
			padding-right: 0;

			@include media-breakpoint-up(md) {
				padding-left: $grid-gutter-width / 2;
				padding-right: $grid-gutter-width / 2;
			}
		}

		#chapter-1__el-12 {
			margin-bottom: 5rem;

			@include media-breakpoint-up(sm) {
				margin-bottom: 0;			}
		}

		.order-md-1 {
			padding-left: 0;
			padding-right: 0;

			@include media-breakpoint-up(sm) {
				padding-left: $grid-gutter-width / 2;
				padding-right: $grid-gutter-width / 2;
			}
		}

		#chapter-1__el-14-a,
		#chapter-1__el-14-b {
			// position: absolute;
			// top: 8%;
			// left: 0;
			// width: 100%;

			// @include media-breakpoint-up(lg) {
			// 	top: 20%;
			// }

			margin-top: 5rem;

			@include media-breakpoint-up(md) {
				margin-top: 10rem;
			}
		}
	}

	#chapter-1__el-1 {
		text-align: right;
		margin-bottom: 10rem;

		@include media-breakpoint-up(md) {
			text-align: center;
		}

		> img {
			max-width: 15.5rem;
		}
	}

	#chapter-1__el-3 {
		margin-top: 3rem;

		@include media-breakpoint-up(lg) {
			left: -2rem;
		}

		@include media-breakpoint-up(xxl) {
			left: -10rem;
		}

		> img {
			max-width: 22.4rem;
		}
	}

	#chapter-1__el-4 {
		margin: 8rem auto 0 auto;
		right: -7rem;
		position: relative;
		max-width: 15rem;

		@media(min-width: 375px) {
			right: -10rem;
		}

		@media(min-width: 414px) {
			right: -12rem;
		}

		@include media-breakpoint-up(md) {
			right: 0;
		}

		@include media-breakpoint-up(lg) {
		    max-width: 25rem;
	  	}

		img {
			width: 100%;
		}

		.l-1,
		.l-3 {
			position: absolute;
		}

		.l-1 {
			z-index: 1;
			width: 5rem;
			top: 2rem;
			left: -4rem;

			@include media-breakpoint-up(lg) {
				top: 4rem;
				width: 7rem;
				left: -5rem;
			}
		}

		.l-2 {
			z-index: 2;
		}

		.l-3 {
			z-index: 3;
			width: 12rem;
			bottom: -4rem;
			right: -5rem;

			@include media-breakpoint-up(lg) {
			    width: 18rem;
			    bottom: -6rem;
			    right: -6rem;
			}
		}
	}
}