// PAFC Narrative Site Vars
//
// Taken from BS4 and overwritten for PAFC
// only include what we need and add our own too

// Color system

$white:    #fff !default;
$gray-100: #E5E5E5;
$gray-200: #A7A9AC !default;
$gray-300: #808285 !default;
$gray-400: $gray-100 !default;
$gray-500: $gray-100 !default;
$gray-600: $gray-100 !default;
$gray-700: $gray-100 !default;
$gray-800: $gray-100;
$gray-900: $gray-100;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300
  ),
  $grays
);

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       #008AAB !default;
$secondary:     $black !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
$body:          $black;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "black":      $black,
    "white":      $white,
  ),
  $theme-colors
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $body !default;
$body-bg-image:             '../images/global/PortAdelaide_A_2020_RGB.png';

// Links
//
// Style anchor elements.

$link-color:                              $primary !default;
$link-decoration:                         none !default;
$link-hover-color:                        darken($link-color, 15%) !default;
$link-hover-decoration:                   underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
  hg: 2000px,
  xhg: 2300px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1300px,
) !default;

// @include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$rounded-pill:                50rem !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0px 0px 8px 4px rgba(#000, 0.15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;

$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;
$transition-speed:            0.3s;

$embed-responsive-aspect-ratios: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  (
    (21 9),
    (16 9),
    (4 3),
    (1 1),
  ),
  $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      'Brandon Grotesque', sans-serif !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

$font-size-base-sm:           1.6rem !default;
$font-size-base:              2.2rem !default;
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$jumbo-font-size-sm:          20rem;
$jumbo-line-height-sm:        20rem;

$jumbo-font-size-lg:          70rem;
$jumbo-line-height-lg:        70rem;

$jumbo-font-size-xxl:         97rem;
$jumbo-line-height-xxl:       97rem;

$line-height-sm:              3.6rem !default;
$line-height-lg:              5rem !default;
$line-height-base:            0.7 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semi-bold:       500 !default;
$font-weight-bold:            800 !default;
$font-weight-bolder:          900 !default;

$font-weight-base:            $font-weight-normal !default;

$h1-font-size-sm:             4.5rem !default;
$h2-font-size-sm:             4rem !default;

$h1-font-size:                7rem!default;
$h2-font-size:                8rem !default;

$h1-line-height-sm:           $h1-font-size-sm !default;
$h2-line-height-sm:           4rem !default;

$h1-line-height:              6rem!default;
$h2-line-height:              2.6rem !default;

$h1-color:                    $primary;
$h2-color:                    $black;

$headings-margin-bottom:      1.7rem !default;
$headings-font-family:        'DharmaGothicCW01' !default;
$headings-font-weight:        $font-weight-bold !default;
$headings-line-height:        1.2 !default;
$headings-color:              $black !default;
$headings-letter-spacing:     0.5px;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;
$display-line-height:         $headings-line-height !default;

$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            300 !default;

$small-font-size:             1.1rem !default;

$text-muted:                  $gray-600 !default;

$blockquote-small-color:      $gray-600 !default;
$blockquote-small-font-size:  $small-font-size !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;

$hr-border-color:             rgba($black, .1) !default;
$hr-border-width:             $border-width !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;

$hr-margin-y:                 $spacer !default;

// Navs

$nav-link-padding-y:                2rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-color:                    $white;
$nav-link-hover-color:              $primary;
$nav-link-disabled-color:           $gray-500 !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               1.5rem !default;
$btn-padding-x:               2rem !default;
$btn-font-family:             $font-family-base !default;
$btn-font-size:               2.5rem !default;
$btn-line-height:             $btn-font-size !default;
$btn-letter-spacing:          6px !default;
$btn-width-sm:                15rem;
$btn-width-lg:                $btn-width-sm;


$btn-padding-y-sm:            $btn-padding-y !default;
$btn-padding-x-sm:            $btn-padding-x !default;
$btn-font-size-sm:            1.5rem !default;
$btn-line-height-sm:          $btn-font-size-sm !default;

$btn-border-width:            1px !default;

$btn-font-weight:             $font-weight-semi-bold !default;
$btn-box-shadow:              none !default;
$btn-focus-width:             $btn-border-width !default;
$btn-focus-box-shadow:        0 !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       none !default;

$btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           0 !default;
$btn-border-radius-lg:        $btn-border-radius !default;
$btn-border-radius-sm:        $btn-border-radius !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;
$zindex-navbar:                     1090 !default;
