.img-block {
	> img {
		width: 100%;
		display: inline-block;
	}
}

.img-caption {
	margin-bottom: 2rem;

	@include media-breakpoint-up(md) {
		max-width: 28rem;
	}

	@include media-breakpoint-up(xl) {
		margin-bottom: 4rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.img-caption__img {
		margin-bottom: 1rem;

		> img {
			width: 100%;
		}
	}

	.img-caption__text {
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		span {
			display: inline-block;
			font-family: $headings-font-family;
			font-size: 3.5rem;
		    line-height: 3rem;
		    letter-spacing: 0.5px;
		    text-transform: uppercase;
		}

		span.img-caption__name {
			color: $black;
			text-align: left;
		}

		span.img-caption__date {
			color: $primary;
			text-align: right;
		}
	}
}