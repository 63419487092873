.chapters {
	display: none;

	// @include media-breakpoint-down(xs) {
	// 	margin-top: 0 !important;
	// }

	.chapter-bg {
		display: flex;
		align-items: center;
		justify-content: center;
		// min-height: 100vh;
		overflow: hidden;
		// margin-top: -1px;
	    position: relative;

		.chapter-bg__inner {
			transform-origin: top;
			margin-left: -2px;

			> img {
				width: 100%;
			}
		}
	}
}

.chapter-content {
	position: relative;
	z-index: 9;

	.container {
		@include media-breakpoint-up(xxl) {
			max-width: map-get($container-max-widths, xl);
		}
	}

	.section__bg {
		position: relative;

		> img {
			width: 100%;
		}

		.section__bg__tear {
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;

			> img {
				width: 100%;
			}

			&.section__bg__tear--before {
				bottom: auto;
				top: -1px;
			}
		}
	}
}

@import 'chapter-0';
@import 'chapter-1';
@import 'chapter-2';
@import 'chapter-3';
@import 'chapter-4';
@import 'chapter-5';
@import 'chapter-6';
@import 'chapter-7';
@import 'chapter-8';